import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { CreateRemoteUpdateTaskRequest } from '../../dtos/create-remote-update-task.model';
import { GetTaskService } from '../../services/get-task/get-task.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { DrinkDto, DrinkState } from '../../services/task-properties/dto/drink-dto';
import { RemoteUpdateTaskDrinks } from '../../dtos/get-remote-update-task-type.model';
import { RemoteUpdateTaskType } from '../../enums/remote-update-task-type.enum';

@Component({
  selector: 'app-remote-update-task-summary',
  templateUrl: './remote-update-task-summary.component.html',
  styleUrls: ['./remote-update-task-summary.component.css', '../../remote-update-tasks.component.css', '../new-task/components/new-task-step-common.css']
})
export class RemoteUpdateTaskSummaryComponent implements OnInit {
  public taskId : number = 0;
  newTaskRequest : CreateRemoteUpdateTaskRequest;

  constructor(
    private activatedRoute: ActivatedRoute,
    private service: GetTaskService,
    private spinner: NgxSpinnerService,
    private toaster: Toaster
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(param => {
      this.taskId = param.taskId;
      this.getTask();
    });
  }

  private getTask() : void {
    if (this.taskId === 0)
      return;

    this.spinner.show();
    this.service.getRemoteUpdateTaskList(this.taskId).subscribe(
      next => {
        this.newTaskRequest = {
          TaskType: next.value.taskType,
          PropositionType: next.value.propositionType,
          CreatedBy: next.value.createdBy,
          EntityDeployLevel : next.value.entityDeployLevel,
          TotalOfMachines: next.value.totalOfMachines,
          Package: next.value.package,
          ScheduleOn: next.value.scheduleOn,
          TaskStatus: next.value.taskStatus,
          DrinkList: this.mapDrinksFromResponse(next.value.drinks),
          ScreenSelectedLevel1: next.value.level1,
          ScreenSelectedLevel2: next.value.level2,
          ScreenSelectedLevel3: next.value.level3,
          TaskProperty: next.value.taskProperty,
          Metadata: next.value.metadata
        }
      },
      () => this.toaster.open('Error to retrieve task'),
      () => this.spinner.hide()
    );
  }

  private mapDrinksFromResponse(drinks: RemoteUpdateTaskDrinks[]): DrinkDto[] {
    return drinks?.map(drink => {
      let state = drink.enabled ? DrinkState.Enabled : DrinkState.Disabled
      return new DrinkDto(drink.name, '', drink.code, state);
    });
  }
}
