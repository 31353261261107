import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { StepMachineSelectionComponent } from '../step-machine-selection/step-machine-selection.component';
import { CreateRemoteUpdateTaskRequest } from 'src/app/components/remote-update-tasks/dtos/create-remote-update-task.model';
import { RemoteUpdateTaskType } from 'src/app/components/remote-update-tasks/enums/remote-update-task-type.enum';
import { RemoteUpdatePropositionType } from 'src/app/components/remote-update-tasks/enums/remote-update-proposition-type.enum';
import { RemoteUpdateEntityDeployLevel } from 'src/app/components/remote-update-tasks/enums/remote-update-entity-deploy-level.enum';
import { StepSummaryComponent } from '../step-summary/step-summary.component';
import { StepTaskPropertiesComponent } from '../step-task-properties/step-task-properties.component';
import { StepScheduleComponent } from '../step-schedule/step-schedule.component';
import { PackageSelectItem } from 'src/app/components/remote-update-tasks/dtos/get-package-select-list.model';
import { RemoteUpdateTaskTypeEntities } from 'src/app/components/remote-update-tasks/dtos/get-remote-update-task-type.model';

@Component({
  selector: 'app-mat-cc-stepper',
  templateUrl: './mat-cc-stepper.component.html',
  styleUrls: ['./mat-cc-stepper.component.css']
})
export class MatCCStepperComponent implements OnInit {
  @ViewChild(StepSummaryComponent, { static: false }) stepSummaryComponent: StepSummaryComponent;
  @ViewChild('stepper', { static: false }) stepper: MatHorizontalStepper;
  @ViewChild(StepMachineSelectionComponent, { static: false }) stepMachineSelectionComponent: StepMachineSelectionComponent;
  @ViewChild(StepTaskPropertiesComponent, { static: false }) stepTaskPropertiesComponent: StepTaskPropertiesComponent;
  @ViewChild(StepScheduleComponent, { static: false }) stepScheduleComponent: StepScheduleComponent;

  readonly STEP_TASK_PARAMETER: number = 0;
  readonly STEP_TASK_PROPERTIES: number = 1;
  readonly STEP_MACHINE_SELECTION: number = 2;
  readonly STEP_SCHEDULE: number = 3;
  readonly STEP_SUMMARY: number = 4;

  steps: any = {
    [this.STEP_TASK_PARAMETER]: { isCompleted: false },
    [this.STEP_TASK_PROPERTIES]: { isCompleted: false },
    [this.STEP_MACHINE_SELECTION]: { isCompleted: false },
    [this.STEP_SCHEDULE]: { isCompleted: false },
    [this.STEP_SUMMARY]: { isCompleted: false },
  }

  newTaskRequest : CreateRemoteUpdateTaskRequest = new CreateRemoteUpdateTaskRequest();
  package : PackageSelectItem = {} as PackageSelectItem;

  public calendarIsUtc : boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  public isStepCompleted(currentStep): boolean {
    const step = this.steps[currentStep];

    return (step != null)
      && (step.isCompleted);
  }

  public goNextStep(args: { current: number, next: number }): void {
    if (args == null || args == undefined)
      return;

    if (args.next == this.STEP_TASK_PROPERTIES) this.stepTaskPropertiesComponent.loadProperties();

    if (args.next == this.STEP_MACHINE_SELECTION) this.stepMachineSelectionComponent.loadMachine();

    if (args.next == this.STEP_SCHEDULE) {
      let isImmediateDeployment = false
      let allowToggle = true
      if (this.newTaskRequest.TaskType === RemoteUpdateTaskType.CertificateManagement || this.newTaskRequest.TaskType === RemoteUpdateTaskType.Contactless) {
        isImmediateDeployment = true
        allowToggle = false
      }
      this.stepScheduleComponent.configure(isImmediateDeployment, allowToggle);
    }

    if (args.next == this.STEP_SUMMARY) this.stepSummaryComponent.getNewTaskInformation();

    this.steps[args.current].isCompleted = true;
    this.stepper.selected.completed = true;
    this.stepper.next();
  }

  public goPreviousStep(args: { current: number, next: number }): void {
    if (args == null || args == undefined)
      return;

    this.steps[args.current].isCompleted = false;
    this.stepper.selected.completed = false;
    this.stepper.previous();
  }

  stepChanged(event: StepperSelectionEvent): void {
    if (event.previouslySelectedIndex > event.selectedIndex) {
      event.previouslySelectedStep.interacted = false;
    }
  }

  public isNewTaskHeaderVisible(): boolean {
    if (this.newTaskRequest && this.newTaskRequest.TaskType != null && this.newTaskRequest.PropositionType != null)
      return true;

    return false;
  }

  public updateNewTaskParameters(taskParameters: {
    taskType: string,
    propositionType: string,
    propositionTypeId?: string,
    entityDeployLevel: string,
    level1?: RemoteUpdateTaskTypeEntities[],
    level2?: RemoteUpdateTaskTypeEntities[],
    level3?: RemoteUpdateTaskTypeEntities[],
    screenSelectedLevel1?: RemoteUpdateTaskTypeEntities[],
    screenSelectedLevel2?: RemoteUpdateTaskTypeEntities[],
    screenSelectedLevel3?: RemoteUpdateTaskTypeEntities[]
  }) : void {
    if (this.newTaskRequest.TaskType !== taskParameters.taskType && !!this.newTaskRequest.PackageId) {
      this.newTaskRequest.Package = '';
      delete this.newTaskRequest.PackageId;
    }

    this.newTaskRequest.TaskType = taskParameters.taskType as RemoteUpdateTaskType;
    this.newTaskRequest.PropositionType = taskParameters.propositionType as RemoteUpdatePropositionType;
    this.newTaskRequest.PropositionTypeId = taskParameters.propositionTypeId;
    this.newTaskRequest.EntityDeployLevel = taskParameters.entityDeployLevel as RemoteUpdateEntityDeployLevel;

    this.newTaskRequest.Level1 = taskParameters.level1;
    this.newTaskRequest.Level2 = taskParameters.level2;
    this.newTaskRequest.Level3 = taskParameters.level3;

    this.newTaskRequest.ScreenSelectedLevel1 = taskParameters.screenSelectedLevel1;
    this.newTaskRequest.ScreenSelectedLevel2 = taskParameters.screenSelectedLevel2;
    this.newTaskRequest.ScreenSelectedLevel3 = taskParameters.screenSelectedLevel3;
    this.newTaskRequest = {
      ...this.newTaskRequest
    };
  }

  public updateNewTaskSchedule(scheduleOn: string): void {
    this.newTaskRequest.ScheduleOn = scheduleOn;
  }

  public setPackage(selectedPackage : PackageSelectItem) : void {
    this.package = selectedPackage;
  }
}
