import * as FileSaver from 'file-saver';
import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { PackageSelectItem } from 'src/app/components/remote-update-tasks/dtos/get-package-select-list.model';
import { CreateRemoteUpdateTaskRequest } from 'src/app/components/remote-update-tasks/dtos/create-remote-update-task.model';
import { GetRemoteUpdateMachineList } from 'src/app/components/remote-update-tasks/dtos/get-remote-update-machine-list.model';
import { MachineSelectionModel } from 'src/app/components/remote-update-tasks/dtos/machine-selection-model';
import { PaginationSelection } from 'src/app/components/remote-update-tasks/dtos/pagination-select-option.model';
import { RemoteUpdatePropositionType } from 'src/app/components/remote-update-tasks/enums/remote-update-proposition-type.enum';
import { RemoteUpdateMachineGetListService } from 'src/app/components/remote-update-tasks/services/get-list/remote-update-machine-get-list.service';
import { RemoteUpdateTaskLevel } from 'src/app/components/remote-update-tasks/dtos/remote-update-task-level';
import { RemoteUpdateTaskMachine } from 'src/app/components/remote-update-tasks/dtos/remote-update-task-machine';
import { RemoteUpdateTaskMachineType } from 'src/app/components/remote-update-tasks/dtos/remote-update-task-machine';
import { RemoteUpdateTaskType } from 'src/app/components/remote-update-tasks/enums/remote-update-task-type.enum';
import { UserService } from 'src/app/services/user/user.service';
import { StatusVariation } from '../status-column/status-column.component';

type Column = {
  showAt: string;
  id: string;
  label: string;
  class?: string;
  type?: string;
  component?:
    | {
        name: 'app-status-column';
        props: (machine: MachineSelectionModel) => {
          variation: StatusVariation;
          text: string 
        };
      }
    | {
        name: 'mat-checkbox';
        props: (machine: MachineSelectionModel) => {
          disabled: boolean;
        };
      };
  getValue?: (machine: MachineSelectionModel) => string;
};
@Component({
  selector: 'app-table-machine-selection',
  templateUrl: './table-machine-selection.component.html',
  styleUrls: ['./table-machine-selection.component.css', './../../../../../../remote-update-tasks.component.css'],
})
export class TableMachineSelectionComponent implements OnInit {
  @Input() taskType: RemoteUpdateTaskType;
  tableData: MachineSelectionModel[] = null;
  getRemoteUpdateMachineList: GetRemoteUpdateMachineList = null;
  proposition: string = null;
  package: PackageSelectItem = {} as PackageSelectItem;
  level1?: string[] = null;
  level2?: string[] = null;
  level3?: string[] = null;
  disabledCertificateStatus: MachineSelectionModel['certificateStatus'][] = ['Obsolete', 'Revoked'];

  showFilterOnTable: boolean = false;

  remoteUpdateMachines: GetRemoteUpdateMachineList;
  page: number = 0;
  limit: number = 10;
  totalPages: number;
  dtOptions = {};
  selectedOption: string;
  allowedExport = [RemoteUpdateTaskType.CertificateManagement]

  options: PaginationSelection[] = [
    { value: 10, label: '10' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ];
  allColumns: Column[] = [
    {
      showAt: 'all',
      id: 'machineId',
      label: 'Select',
      class: 'table-machine-column-select',
      component: {
        name: 'mat-checkbox',
        props: (machine) => ({
          disabled: this.disabledCertificateStatus.includes(machine?.certificateStatus),
        }),
      },
    },
    { showAt: 'all', id: 'machineAutoGeneratedId', label: 'Machine ID' },
    { showAt: 'all', id: 'machineSerialNumber', label: 'Machine Number' },
    { showAt: 'all', id: 'machineType', label: 'Machine Type' },
    {
      showAt: RemoteUpdateTaskType.Contactless,
      id: 'contactlessEnabled',
      label: 'Contactless Enabled',
      type: 'chip',
    },
    { showAt: 'all', id: 'site', label: 'Site' },
    {
      showAt: 'all',
      id: 'status',
      label: 'Status',
      component: {
        name: 'app-status-column',
        props: (machine) => ({
          variation: machine?.status === 'Active' ? 'blue' : 'secondary',
          text: machine?.status,
        }),
      },
    },
    { showAt: 'all', id: 'workingState', label: 'Working State' },
    { showAt: 'all', id: 'targetWorkingState', label: 'Target Working State' },
    { showAt: 'all', id: 'suspendedReason', label: 'Suspended Reason' },
    { showAt: 'all', id: 'commissioningState', label: 'Commissioning State' },
    { showAt: 'all', id: 'customer', label: 'Customer' },
    { showAt: 'all', id: 'country', label: 'Country' },
    { showAt: 'all', id: 'updateStatus', label: 'Update Status' },
    { showAt: 'all', id: 'failedReason', label: 'Failed Reason' },
    { showAt: 'all', id: 'modelCode', label: 'Model Code' },
    { showAt: 'all', id: 'agentVersion', label: 'Agent Version' },
    { showAt: 'all', id: 'vmcVersion', label: 'VMC Version' },
    { showAt: 'all', id: 'softwareVersion', label: 'Software Version' },
    { showAt: 'all', id: 'systemFirmware', label: 'Firmware Version' },
    { showAt: 'all', id: 'targetVersion', label: 'Target Version' },
    { showAt: 'all', id: 'brandCode', label: 'Brand Code' },
    { showAt: 'all', id: 'brandType', label: 'Brand Type' },
    { showAt: 'all', id: 'lastUpdateMethod', label: 'Last Update Method' },
    { showAt: 'all', id: 'lastUpdateUser', label: 'Last Update User' },
    { showAt: 'all', id: 'lastCommunicationUpdateReceived', label: 'Last Communication Update Received', getValue: (machine) => this.formatDate(machine?.lastCommunicationUpdateReceived) },
    { showAt: 'all', id: 'lastSalesDataReceived', label: 'Last Sales Data Received' },
    {
      showAt: RemoteUpdateTaskType.CertificateManagement,
      id: 'certificateStatus',
      label: 'Cert Status',
      component: {
        name: 'app-status-column',
        props: (machine) => {
          const certificateStatus = machine?.certificateStatus;
          let variation: StatusVariation = 'secondary'
          if (certificateStatus === 'Valid') {
            variation = 'primary'; 
          } else if (certificateStatus === 'Due to Expire') { 
            variation = 'alert'; 
          }
              return {
                variation,
                text: certificateStatus,
              };
        },
      },
    },
    { showAt: RemoteUpdateTaskType.CertificateManagement, id: 'certificateExpiryDate', label: 'Cert Expiry Date', getValue: (machine) => this.formatDate(machine?.certificateExpiryDate) },
    { showAt: RemoteUpdateTaskType.CertificateManagement, id: 'connectivityStatus', label: 'Connectivity Status', getValue: (machine) => machine.connectivityStatus }
  ];
  columnsArray: Column[] = [];

  searchValues = [];
  columns = [];
  orderByColumn: Map<string, boolean> = new Map<string, boolean>(this.columns.map((x) => [x, null]));
  queryParams: Map<string, string> = new Map<string, string>();

  private checkedMachines: Set<string> = new Set<string>();
  allComplete: boolean = false;
  totalMachineIds: number;

  constructor(private remoteUpdateMachineListService: RemoteUpdateMachineGetListService, private spinner: NgxSpinnerService, private toaster: Toaster, private userService: UserService) {}

  ngOnInit(): void {}

  private setupTable() {
    this.dtOptions = {
      autoWidth: true,
      scrollX: true,
      lengthMenu: [10, 25, 50, 100],
      pageLength: this.limit,
      ordering: false,
      paging: false,
      search: false,
      dom: 't',
    };
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 1);
  }

  goToNextPage() {
    if (this.totalPages > this.page + 1) {
      this.page++;
      this.loadRemoteUpdateMachineList();
    }
  }

  goToFirstPage() {
    if (this.page != 0) {
      this.page = 0;
      this.loadRemoteUpdateMachineList();
    }
  }

  goToLastPage() {
    const lastPage = this.totalPages - 1;
    if (this.page < lastPage) {
      this.page = lastPage;
      this.loadRemoteUpdateMachineList();
    }
  }

  changePageLimit(limit: string) {
    if (limit) {
      this.limit = Number(limit);
      this.page = 0;
      this.loadRemoteUpdateMachineList();
    }
  }

  goToPreviousPage() {
    if (this.page > 0) {
      this.page--;
      this.loadRemoteUpdateMachineList();
    }
  }

  toggleHeaderFilterEvent() {
    this.showFilterOnTable = !this.showFilterOnTable;
  }

  searchPerColumn(columnPosition: number, searchInput: string) {    
    const column = this.allColumns[columnPosition];
    let handleInvalidSearch = false;

    if (column.id === 'contactlessEnabled') {
        let translatedValue: string | null = null;

        if (searchInput.trim().toLowerCase() === 'yes') {
            translatedValue = 'true';
        } else if (searchInput.trim().toLowerCase() === 'no') {
            translatedValue = 'false';
        } else if (!searchInput.trim()) {
            translatedValue = null;
        } else {
            handleInvalidSearch = true;
        }

        this.queryParams.set(this.columns[columnPosition], translatedValue);
    } else {        
        if (!searchInput) {
            this.queryParams.delete(this.columns[columnPosition]);
        } else {
            this.queryParams.set(this.columns[columnPosition], searchInput.trim());
        }
    }

    this.page = 0;
    this.loadRemoteUpdateMachineList(handleInvalidSearch);
}

  updateSelectedList(checkbox) {
    if (checkbox.checked) {
      this.checkedMachines.add(checkbox.source.id);
    } else {
      if (this.checkedMachines.has(checkbox.source.id)) {
        this.checkedMachines.delete(checkbox.source.id);
      }
    }

    this.updateAllComplete();
  }

  updateAllComplete() {
    this.allComplete =
      this.tableData != null &&
      this.checkedMachines.size > 0 &&
      (this.totalMachineIds == this.checkedMachines.size || (this.queryParams && this.totalMachineIds == this.tableData.filter((x) => x.checked).length));
  }

  someComplete(): boolean {
    if (this.tableData == null) {
      return false;
    }
    return this.checkedMachines.size > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    if (this.tableData == null) {
      return;
    }
    if (completed) {
      this.getAllMachineIds();
    }
    else {
      this.checkedMachines = new Set<string>();
      this.checkSelectedMachines()
    }
  }

  apiGetAllParams (page: number, limit: number): RemoteUpdateTaskMachine {
    return {
      page,
      limit,
      queryParams: this.queryParams,
      levels: this.getRemoteUpdateTaskLevel(),
      Type: this.taskType === RemoteUpdateTaskType.CertificateManagement ? RemoteUpdateTaskMachineType.GetAllCertificates :RemoteUpdateTaskMachineType.GetAll
    }
  }

  exportToCsv() {
    const exportType = this.checkedMachines.size === 0 ? 'All' : 'Selected';
  
    const remoteUpdateTaskMachineObject = this.apiGetAllParams(0, 99999)
  
    this.spinner.show();
  
    this.remoteUpdateMachineListService.getRemoteMachineUpdate<GetRemoteUpdateMachineList>(remoteUpdateTaskMachineObject).subscribe(
      (response) => {
        const filteredData = this.checkedMachines.size === 0 ? response.data : response.data.filter(row => this.checkedMachines.has(row.machineId));
        const timestamp = this.getTimestamp();
        const fileName = `${exportType}MachineExport_${timestamp}.csv`;
        const csv = [
          this.columnsArray.map(column => column.label).join(','),
          ...filteredData.map(row => 
            this.columnsArray.reduce((line, column) => [...line, this.getColumnValue(row, column)], []).join(',')
          )
        ].join('\n')
        FileSaver.saveAs(new Blob([csv]), fileName);
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        this.toaster.open({
          text: err.message,
          type: 'danger',
          position: 'top-right',
          duration: 10000,
        });
      },
    );
  }

  getColumnValue = (row: MachineSelectionModel, column: Column) => {
    if (column.getValue) {
      return column.getValue(row)
    }
    if (column.component?.name === 'app-status-column') {
      return column.component.props(row).text
    }
    return row[column.id] !== null ? `"${row[column.id]}"` : null
  }
  
  mapRow(row: MachineSelectionModel): Record<string, string> {
    return this.columnsArray.reduce((transformedRow, column) => {
      const value = column.getValue ? column.getValue(row) : row[column.id];
      transformedRow[column.label] = value;
      return transformedRow;
    }, {});
  }
  
  getTimestamp() {
    const nowSplitted = new Date().toISOString().split('T');
    return `${nowSplitted[0]}_${nowSplitted[1].replace(':', '-').slice(0,5)}`;
  }

  getAllMachineIds() {
    this.spinner.show();
    let remoteUpdateTaskMachineObject = this.apiGetAllParams(0, 9999999)
    this.remoteUpdateMachineListService.getRemoteMachineUpdate<GetRemoteUpdateMachineList>(remoteUpdateTaskMachineObject).subscribe(
      (response) => {
        response.data.forEach(({machineId}) => this.checkedMachines.add(machineId));
        this.checkSelectedMachines()
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        console.log('Error when getting machine ids. Details: ', err);
      },
    );
  }

  loadMachineColumns() {
    const columns = this.allColumns.filter((elem) => {
      if (elem.showAt === 'all') return true;
      return this.taskType === elem.showAt;
    });
    this.columnsArray = columns;
    this.searchValues = columns.map((_elem) => '');
    this.columns = columns.map(({ id }) => id);
    this.orderByColumn = new Map<string, boolean>(columns.map(({ id }) => [id, null]));
  }

  formatDate(date: string | undefined) {
    return date ? moment(date).format('DD/MM/YYYY HH:mm') : '';
  }

  fetchMachineDataAPI(taskParameters: CreateRemoteUpdateTaskRequest, selectedPackage: PackageSelectItem) {
    this.loadMachineColumns();
    this.package = selectedPackage;
    this.setCurrentlySelectedProposition(taskParameters.PropositionType);
    this.setLevelEntityIdsFilter(taskParameters);
    this.loadRemoteUpdateMachineList();
  }

  private setCurrentlySelectedProposition(propositionType: RemoteUpdatePropositionType) {
    if (propositionType != this.proposition) this.checkedMachines = new Set<string>();

    this.proposition = propositionType;
  }

  private clearLevels() {
    this.level1 = null;
    this.level2 = null;
    this.level3 = null;
  }

  private setLevelEntityIdsFilter(taskParameters: CreateRemoteUpdateTaskRequest) {
    this.clearLevels();
    this.level1 = taskParameters.Level1.map((m) => m.id);
    this.level2 = taskParameters.Level2.map((m) => m.id);
    this.level3 = taskParameters.Level3.map((m) => m.id);
  }

  private getRemoteUpdateTaskLevel() {
    var remoteUpdateTaskLevel: RemoteUpdateTaskLevel = {} as RemoteUpdateTaskLevel;

    remoteUpdateTaskLevel.Level3Ids = this.level3;
    remoteUpdateTaskLevel.Level2Ids = this.level2;
    remoteUpdateTaskLevel.Level1Ids = this.level1;

    return remoteUpdateTaskLevel;
  }

  private loadRemoteUpdateMachineList(handleInvalidSearch?: boolean) {
    if (handleInvalidSearch) {
      this.remoteUpdateMachines = {
          data: [],
          params: { start: 0, end: 0, count: 0 }
      };
      this.tableData = [];
      this.spinner.hide();
      return;
    }


    if (this.package.minimalVersion) {
      this.queryParams.set('minimalVersion', this.package.minimalVersion);
    }

    if (this.proposition) {
      this.spinner.show();
      this.queryParams.set('propositionType', this.proposition);
    }
    
    const remoteUpdateTaskMachineObject = this.apiGetAllParams(this.page, this.limit)

    this.remoteUpdateMachineListService.getRemoteMachineUpdate<GetRemoteUpdateMachineList>(remoteUpdateTaskMachineObject).subscribe(
      (response) => {
        this.remoteUpdateMachines = response;
        this.tableData = response.data;
        this.totalPages = Math.ceil(response.params.count / this.limit);
        this.checkSelectedMachines();
        this.spinner.hide();
        this.setupTable();
      },
      (err) => {
        this.spinner.hide();
        this.toaster.open({
          text: err.message,
          type: 'danger',
          position: 'top-right',
          duration: 10000,
        });
      },
    );
  }

  checkSelectedMachines() {
    this.tableData.forEach(m => {
      m.checked = this.checkedMachines.has(m.machineId);
    });

    this.updateAllComplete();
  }

  clearMachineCheckeds() {
    this.checkedMachines.clear();
  }

  hasMachineSelected() {
    return this.checkedMachines.size > 0;
  }

  getCurrentPageInfo() {
    let text = '';
    if (this.remoteUpdateMachines.params.start != null) {
      text += this.remoteUpdateMachines.params.start + 1 + '-' + (this.remoteUpdateMachines.params.end + 1);
    } else {
      text += '0-0';
    }

    text += ' of ' + this.remoteUpdateMachines.params.count;

    return text;
  }

  setOrderByColumn(columnPosition: number) {
    this.orderByColumn.forEach((_, key) => {
      if (key.toLowerCase() !== this.columns[columnPosition].toLowerCase()) {
        this.orderByColumn.set(key, null);
      }
    });
    this.orderByColumn.set(this.columns[columnPosition], !this.orderByColumn.get(this.columns[columnPosition]));
    this.queryParams.set('sort', this.columns[columnPosition]);
    this.queryParams.set('order', this.orderByColumn.get(this.columns[columnPosition]) ? 'asc' : 'desc');
    this.page = 0;
    this.loadRemoteUpdateMachineList();
  }

  public getMachinesSelected() {
    return this.checkedMachines;
  }  
}
