import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import * as _moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import * as pbi from 'powerbi-client';
import { MasterLookupEnum } from 'src/app/enums/masterlookup.enum';
import { PriceCardTypeLookupConstant } from 'src/app/enums/pricecardtype.enum';
import { EntityService } from 'src/app/services/entity/entity.service';
import { PriceCardService } from 'src/app/services/price-card/price-card.service';
import { RoleService } from 'src/app/services/role/role.service';
import { DiscrepancyTypes, SalesService } from 'src/app/services/sales/sales.service';
import { UserService } from 'src/app/services/user/user.service';
declare var $: any;
declare var powerbi: any;
const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-sales-list',
  templateUrl: './sales-list.component.html',
  styleUrls: ['./sales-list.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class SalesListComponent implements OnInit ,AfterViewInit{
  @ViewChild('embeddedReport')
  embeddedReport: ElementRef;
  config: any;
  screenHeight: number;
  tabIndex = 0;
  columnsToDisplay = ['propositionType','drink', 'cupSize', 'coffeeType', 'milkType', 'syrup', 'manufacturePrice', 'gridPrice', 'priceCardName', 'uniqueMachineNumber', 'machineType', 'discrepencyType', 'processingStatus', 'actions'];
  
  tlength = 5;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;
  isShowFilter = false;
  drawerfilter: boolean = false;
  isFilterClear: boolean = false;
  isFilterApply: boolean = false;
  salesDataList: any = [];
  blob: any;
  priceCards: any = [];
  markets: any = [];
  partners: any = [];
  sites: any = [];
  machines: any = [];
  uniqueMachines: any = []; //[GD2-3239]
  filter: any = {
    propositionTypeId: [], //GD2-4432
    marketId: '',
    partnerId: '',
    siteId: '',
    uniqueMachineIds: [],//[GD2-3239]
    priceCardId: '',
    processingStatus: '',
    vendStatus: '',
    discrepancyType: '',
    search: '',
    manufactureFromDate: '',
    manufactureToDate: '',
    gridReceivedFromDate: '',
    gridReceivedToDate: '',
    vendFromDate: '',
    vendToDate: '',
  }
  tempfilter: any = {//[GD2-3706]
    propositionTypeId: [], //GD2-4432
    marketId: '',
    partnerId: '',
    siteId: '',
    uniqueMachineIds: [],
    machineId: [],
    priceCardId: '',
    processingStatus: '',
    vendStatus: '',
    discrepancyType: '',
    search: '',
    manufactureFromDate: '',
    manufactureToDate: '',
    gridReceivedFromDate: '',
    gridReceivedToDate: '',
    vendFromDate: '',
    vendToDate: '',
    }
  filterColumns: string[] = ['propositionTypeId','marketId', 'partnerId', 'siteId', 'uniqueMachineIds', 'machineId', 'priceCardId', 'processingStatus', 'vendStatus', 'discrepancyType', 'manufactureFromDate', 'manufactureToDate', 'gridReceivedFromDate', 'gridReceivedToDate', 'vendFromDate', 'vendToDate'];//[GD2-3706]
  recordsTotal = 0;
  pageIndex = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  params = {
    pageNumber: 1,
    pageSize: this.pageSizeOptions[0],
    searchText: '',
    orderByColumn: 'salesReceivedDateTime desc'
  };
  lookUpObject = {
    "search": "",
    "configurationType": MasterLookupEnum.Drink,
    "pageNo": 1,
    "pageSize": -1,
    "orderBy": "text"
  }
  dialogRef: MatDialogRef<any>;
  dialogTexts = {
    successTitle: '',
    cancelTitle: '',
    message: '',
    deviceName: '',
    status: '',
    title: '',
    type: ''
  };
  selectedIndex = -1;
  minDate: any = moment().subtract(this.userService.defaultUIConfigurations.salesListFilterDateMinInMonths, 'months');
  maxDate: any = moment();
  discrepancyTypes = DiscrepancyTypes;
  @ViewChild("confirmDialogTemplate") confirmDialogTemplate: TemplateRef<any>;
  isPageDataLoad = false;
  salesDetails: any = {};
  isOpenSalesDetails: boolean = false;
  moveMachineId = '';
  moveUniqueMachineNumber = ''; //[GD2-3239]
  powerBiWidth:any = '100%';
  advanceFilter = {
    entityID: false,
    entityName: false,
    billTo: false,
    sellTo: false,
    reference: false,
    machineSerialNumber: false
  }
  displayAdvancedSearch = false;
  lookupDropdownSettings = {//[GD2-3239]
    singleSelection: false,
    defaultOpen: false,
    idField: "masterDataId",
    textField: "text",
    enableCheckAll: false,
    allowSearchFilter: true,
    itemsShowLimit: 1
  };
  //GD2-4432
  propositionTypeDropdownSettings = {
    singleSelection: false,
    defaultOpen: false,
    idField: "propositionTypeId",
    textField: "name",
    enableCheckAll: false,
    allowSearchFilter: false,
    itemsShowLimit: 1
  };
  propositionTypes: any = [];

  constructor(private spinner: NgxSpinnerService, private entityService: EntityService, public roleService: RoleService, public priceCardTypeConstant: PriceCardTypeLookupConstant,
    private salesService: SalesService, private activatedRoute: ActivatedRoute, private priceCardService: PriceCardService, private router: Router, public dialog: MatDialog, private toaster: Toaster,private userService:UserService) { }

  ngOnInit() {
    console.log('onInit',new Date());
    this.setPowerBiStyle();
    let that = this; 
    $('#menu').click(function(){
      that.setPowerBiStyle();
    }); 
    this.minDate = moment(this.minDate).startOf('day');
    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams.tabIndex)
        this.tabIndex = queryParams.tabIndex;
      else 
        this.tabIndex = 0;      
      if (this.tabIndex == 1) {
        if (localStorage.getItem("filterDiscrepancyByMachineId"))
        {
          this.moveMachineId = localStorage.getItem("filterDiscrepancyByMachineId");
          localStorage.removeItem("filterDiscrepancyByMachineId");
        }
        if (localStorage.getItem("filterDiscrepancyByMachineUniqueId")) {//[GD2-3239]
            this.moveUniqueMachineNumber = localStorage.getItem("filterDiscrepancyByMachineUniqueId");
            localStorage.removeItem("filterDiscrepancyByMachineUniqueId");
        }
        this.spinner.show();
        if (this.roleService.objRole.isSystemAdmin || this.roleService.objRole.isGlobalMarketAdmin || this.roleService.objRole.isGridR || this.roleService.objRole.isUserAuditorGRIDR) {
          this.getPropositionTypes(); //GD2-4432
        } 
        this.getMarketLookup();
        this.getPartnerLookup(true);
        this.getPriceCardUnrecognizedVends();
        this.getSalesData(true);
      }
      else {
        this.getSalesEmbbedReport();

      }
    });
  }

  ngAfterViewInit(){
    powerbi.bootstrap(this.embeddedReport.nativeElement,{
      type: 'report',
      hostname: "https://app.powerbi.com"
  });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setPowerBiStyle();
  }
  setPowerBiStyle(){
    setTimeout(() => {
      let width = $('.power-bi-w').css('width');
      this.powerBiWidth = width;
    }, 500);
  }
  onTabChanged(event) {
    this.tabIndex = event.index;
    if (this.tabIndex == 1) {
      this.spinner.show();
      this.clearFilter();
    }
    else {
      this.setPowerBiStyle();
      this.getSalesEmbbedReport();

  }
  }

    applyFilter() {//[GD2-3706]
        this.assignFilterToTempFilter();
        this.getSalesData('apply');
    }

    assignFilterToTempFilter() {//[GD2-3706]
        this.filterColumns.forEach(key => {
            this.tempfilter[key] = this.filter[key];
        });
    }
  changefilter() {
    this.roleService.setPopupScroll('section-salesfilter');  
    if (!this.drawerfilter) {
      this.displayAdvancedSearch = false;
      $(".sidebar-mini").addClass("filter-open");
   }
    else {
        this.filterColumns.forEach(key => {//[GD2-3706]
            let valueChanged = false;
            if (this.filter[key] != this.tempfilter[key]) { valueChanged = true; }
            this.filter[key] = this.tempfilter[key];
            if (key == 'propositionTypeId' && this.filter['propositionTypeId'].length >= 0 && valueChanged) {
              this.getMarketLookup(true);
            }
            if (key == 'marketId' && this.filter['marketId'].length >= 0 && valueChanged) {
                this.getPartnerLookup(false);
            }
            if (key == 'partnerId' && this.filter['partnerId'].length >= 0 && valueChanged) {
                this.getSiteLookup(false);
            }
            if (key == 'siteId' && this.filter['siteId'].length >= 0 && valueChanged) {
                this.getMachinesLookup(false);
            }
        });
       $(".sidebar-mini").removeClass("filter-open");
       this.roleService.resetPopupScroll()
   }
    setTimeout(() => {
      this.drawerfilter = !this.drawerfilter;  
    }, this.roleService.scrollSetTimeoutTime); 
  }
  clickFilter(val) {
    this.isShowFilter = val;
  }
  clearFilter() {
    this.spinner.show();
    this.moveMachineId = '';
    this.moveUniqueMachineNumber = '';//[GD2-3239]
    this.sites = [];
    this.partners = [];
    this.machines = [];
    this.isFilterClear = true;
    this.isFilterApply = false;
    let fromClear = true;
    this.advanceFilter = {
          entityID: false,
          entityName: false,
          billTo: false,
          sellTo: false,
          reference: false,
          machineSerialNumber: false
      }
    this.filter = {
      search: '',
      marketId: '',
      partnerId: '',
      siteId: '',
      priceCardId: '',
      uniqueMachineIds: [],//[GD2-3239]
      processingStatus: '',
      vendStatus: '',
      discrepancyType: '',
      manufactureFromDate: '',
      manufactureToDate: '',
      vendFromDate: '',
      vendToDate: '',
      propositionTypeId: []
    }
    this.assignFilterToTempFilter();//[GD2-3706]
    this.params.pageNumber = 1;
    this.params.pageSize = this.pageSizeOptions[0];
    this.params.searchText = '';
    this.params.orderByColumn = 'salesReceivedDateTime desc';

    this.recordsTotal = 0;
    this.salesDataList = [];
    if (this.roleService.objRole.isSystemAdmin || this.roleService.objRole.isGlobalMarketAdmin || this.roleService.objRole.isGridR || this.roleService.objRole.isUserAuditorGRIDR) {
      this.getPropositionTypes(); //GD2-4432
    } 
    this.getMarketLookup();
    this.getPartnerLookup(true);
    this.getPriceCardUnrecognizedVends();
    this.getSalesData(fromClear);
  }
  clearSearch() {
    this.filter.search = '';
    this.filter.advanceSearch = [];
    this.advanceFilter = {
      entityID: false,
      entityName: false,
      billTo: false,
      sellTo: false,
      reference: false,
      machineSerialNumber: false
    }
    this.getSalesData();
  }
  sortData(e) {
    e.direction = (e.active == 'isActive' && e.direction != '' ? (e.direction == 'asc' ? 'desc' : 'asc') : e.direction);
    this.params.orderByColumn = (e.active != '' && e.direction != '' ? e.active + ' ' + e.direction : (e.active != '' && e.direction == '') ? e.active + ' asc' : 'cells_name asc');
    this.params.pageNumber = 1;
    this.getSalesData();
  }
  pageChange(e) {
    this.params.pageNumber = (e.pageIndex > 0 ? (e.pageIndex + 1) : 1);
    this.params.pageSize = (e.pageSize > 0 ? e.pageSize : this.pageSizeOptions[0]);
    this.getSalesData();
  }
  getSalesData(fromClear = undefined) {
    this.displayAdvancedSearch = false;
      if (this.filter.vendFromDate && !this.filter.vendToDate) {
        this.filter.vendToDate = moment().format('YYYY-MM-DD');
      }
      if (this.filter.manufactureFromDate && !this.filter.manufactureToDate) {
        this.filter.manufactureToDate = moment().format('YYYY-MM-DD');
      }

      if (!this.filter.vendFromDate && this.filter.vendToDate) {
        this.toaster.open({
          text: "Vended from date cannot be empty",
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
        this.spinner.hide();
      } else if (!this.filter.manufactureFromDate && this.filter.manufactureToDate) {
        this.toaster.open({
          text: "Received @ Manufacturer from date cannot be empty",
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
        this.spinner.hide();
      } else if (this.filter.manufactureFromDate > this.filter.manufactureToDate) {
        this.toaster.open({
          text: "Received @ Manufacturer to date must be greater than from date",
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
        this.spinner.hide();
      } else if (this.filter.gridReceivedToDate && this.filter.gridReceivedFromDate > this.filter.gridReceivedToDate) {
        this.toaster.open({
          text: "Received @ Grid to date must be greater than from date",
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
        this.spinner.hide();
      } else if (this.filter.vendFromDate > this.filter.vendToDate) {
        this.toaster.open({
          text: "Vended to date must be greater than from date",
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
        this.spinner.hide();
      } else if (this.filter.vendFromDate && this.filter.vendFromDate < this.minDate) {
        this.toaster.open({
          text: "Vended from date must be up to past "+this.userService.defaultUIConfigurations.salesListFilterDateMinInMonths+" Months",
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
        this.spinner.hide();
      } else if (this.filter.gridReceivedFromDate && this.filter.gridReceivedFromDate < this.minDate) {
        this.toaster.open({
          text: "Received @ Grid from date must be up to past "+this.userService.defaultUIConfigurations.salesListFilterDateMinInMonths+" Months",
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
        this.spinner.hide();
      } else if (this.filter.manufactureFromDate && this.filter.manufactureFromDate < this.minDate) {
        this.toaster.open({
          text: "Received @ Manufacturer from date must be up to past "+this.userService.defaultUIConfigurations.salesListFilterDateMinInMonths+" Months",
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
        this.spinner.hide();
      } else {

        this.spinner.show();       
        if (fromClear == "search" || fromClear == "apply" || fromClear == true) {
          this.params.pageNumber = 1;
        }
        if(this.moveMachineId != '') {
          var data = { "masterDataId": this.moveMachineId, "text": this.moveUniqueMachineNumber };//[GD2-3239]
            if ((this.filter['machineId'] == undefined || !this.filter['machineId'].length) && this.filter['uniqueMachineIds'] != undefined && !this.filter['uniqueMachineIds'].length) {
                this.filter.uniqueMachineIds[0] = data;
                this.moveMachineId = '';
                this.moveUniqueMachineNumber = '';
            }//[GD2-3239]
          this.filter.gridReceivedFromDate = '';
          this.filter.gridReceivedToDate = '';
        }
        if (fromClear == undefined)
          this.drawerfilter = false;
        let obj = this.filter;
        this.isFilterApply = false;
        if (this.filter.search != '') { obj['search'] = this.filter.search; };
        if (this.params.pageNumber != undefined) { obj['pageNo'] = this.params.pageNumber };
        if (this.params.pageSize != undefined) { obj['pageSize'] = this.params.pageSize };
        if (this.params.orderByColumn != undefined) { obj['orderByColumn'] = this.params.orderByColumn };
        if (this.isFilterApply) {
          obj['pageNo'] = 1;
          this.params.pageNumber = 1;
          }
          //Start- [GD2-3239]
          let that = this;
          if (that.filter['uniqueMachineIds'] && that.filter['uniqueMachineIds'].length) {
              let ids = [];
              that.filter['uniqueMachineIds'].forEach(e => {
                  if (e.masterDataId)
                      ids.push(e.masterDataId);
              })
              if (ids.length) {
                  obj['machineId'] = ids;
                  this.assignFilterToTempFilter();
              }
          }
          else {
              obj['machineId'] = [];
          }
          //End [GD2-3239]
        var advanceSearchColumns = [];
        if (this.advanceFilter.entityID) {advanceSearchColumns.push("EntityID")};
        if (this.advanceFilter.entityName) {advanceSearchColumns.push("EntityName")};
        if (this.advanceFilter.billTo) {advanceSearchColumns.push("BillTo")};
        if (this.advanceFilter.sellTo) {advanceSearchColumns.push("SellTo")};
        if (this.advanceFilter.reference) {advanceSearchColumns.push("Reference")};
        if (this.advanceFilter.machineSerialNumber) {advanceSearchColumns.push("MachineSerialNumber")};    
        if (advanceSearchColumns.length > 0) { obj['advanceSearch'] = advanceSearchColumns; }
        if (this.filter.propositionTypeId.length > 0) { obj['propositionTypeIds'] = this.filter.propositionTypeId.map(x => x.propositionTypeId).join(","); } //GD2-5435

        this.salesService.getSalesDataDiscrepanciesList(obj).subscribe(response => {
         if (!this.isFilterClear) {
            this.isShowFilter = false;
            this.drawerfilter = false;
          }
          this.isFilterClear = false;
          if (fromClear == "apply") {
            $(".sidebar-mini").removeClass("filter-open");
            this.roleService.resetPopupScroll();
          }
          this.recordsTotal = response?.params?.count;
          this.spinner.hide();
          this.isPageDataLoad = true;
          if (response && response['data'].length) {
            this.salesDataList = response['data'];
          } else {
            this.salesDataList = [];
          }

        }, err => {
          obj['uniqueMachineIds'] = [];//[GD2-3239]
          this.filter.uniqueMachineIds = [];//[GD2-3239]
          this.isPageDataLoad = true;
          let errors = err.error;
          errors.forEach(element => {
            this.toaster.open({
              text: element.msg,
              type: 'danger',
              position: 'top-right',
              duration: 10000
            });
          });
        });
      }
  }
  getSalesEmbbedReport() {
    this.screenHeight = (window.screen.height);
    this.entityService.getSalesEmbbedReport()
      .subscribe(config => {
        this.config = config;
        const model = pbi.models;
        if (config.embedReports.length > 0) 
        {
          const embedConfig = {
            type: 'report',
            tokenType: model.TokenType.Embed,
            accessToken: config.embedToken.token,
            embedUrl: config.embedReports[0].embedUrl,
            permissions: model.Permissions.All,
            settings: {
              filterPaneEnabled: false,
              navContentPaneEnabled: true
            }
          };
          let reportele = powerbi.embed(this.embeddedReport.nativeElement, embedConfig);
          reportele.off("loaded");
          reportele.off("rendered");
          reportele.on('loaded',function(){
            console.log('loaded',new Date());
          });
          reportele.on('rendered',function(){
            console.log('rendered',new Date());
          });
    }
      });
  }
  getMarketLookup(fromPropositionTypeFilter = false) {
    if (!(this.roleService.objRole.isLevel2Admin || this.roleService.objRole.isLevel3Admin)) {
      //GD2-4432 start
    
    let propositionTypeIds = '';
    if (this.filter.propositionTypeId.length > 0)
      propositionTypeIds = this.filter.propositionTypeId.map(x => x.propositionTypeId).join(",");
    //GD2-4432 end
    this.markets = [];
      this.entityService.getMarketLookup('', propositionTypeIds).subscribe(response => {
        if (response != null) {
          if (response['data'].length) {
            this.markets = response['data'];
          }
        }
        if (this.filter.marketId != "" && fromPropositionTypeFilter) {
          this.filter.marketId = '';
          this.filter.partnerId = '';
          this.filter.siteId = '';
          this.getPartnerLookup(false);
        }
        else if (fromPropositionTypeFilter && this.markets.length == 0){
          this.partners = [];
          this.sites = [];
        }
        else if (fromPropositionTypeFilter && this.markets.length > 0 && this.partners.length == 0 && this.sites.length == 0) {
          this.getPartnerLookup(false);
        }
      });
   }
  }
  getPartnerLookup(isPageLoad) {
    this.machines = [];
    this.sites = [];
    this.partners = [];
    this.filter.siteId = '';
    this.filter.partnerId = '';
    this.filter.uniqueMachineIds = [];//[GD2-3239]
    if (!isPageLoad) {
      this.spinner.show();
    }
    this.entityService.getPartnerLookup(this.filter.marketId).subscribe(response => {
      if (!isPageLoad) {
        this.spinner.hide();
      }
      if (response && response['data'].length) {
        this.partners = response['data'];
      }
    });
    if (this.filter.marketId == "") {
      this.getSiteLookup(isPageLoad);
      this.getMachinesLookup(isPageLoad);
    }
  }
  getSiteLookup(isPageLoad) {
    this.sites = [];
    this.filter.siteId = '';
    this.machines = [];
    this.filter.uniqueMachineIds = [];//[GD2-3239]
    if (!isPageLoad) {
      this.spinner.show();
      }
      if (this.filter.marketId != "" && this.filter.partnerId.length == 0) { this.sites = []; this.spinner.hide(); }//[GD2-3796]
      else {
          this.entityService.getSiteLookup(this.filter.partnerId).subscribe(response => {
              if (!isPageLoad) {
                  this.spinner.hide();
              }
              if (response && response['data'].length) {
                  this.sites = response['data'];
              }
          });
      }
    if (this.filter.partnerId == "") {
      this.getMachinesLookup(isPageLoad);
    }
  }
  getMachinesLookup(isPageLoad) {
    this.machines = [];
    this.filter.uniqueMachineIds = [];//[GD2-3239]
    if (!isPageLoad) {
      this.spinner.show();
      }
      if ((this.filter.partnerId != "" || (this.filter.marketId != "" && this.filter.partnerId.length == 0)) && this.filter.siteId.length == 0) { this.machines = []; this.spinner.hide(); }//[GD2-3796]
      else {
          this.entityService.getMachineLookup({ siteIds: (this.filter.siteId) ? [this.filter.siteId] : [] }).subscribe(response => {
              if (!isPageLoad) {
                  this.spinner.hide();
              }
              if (response && response['data'].length) {
                  this.machines = response['data'];
              }
          });
      }
  }
  getPriceCardUnrecognizedVends() {
    this.priceCardService.getPriceCardUnrecognizedVends().subscribe(response => {
      if (response && response['data'].length) {
        this.priceCards = response['data'];
      }
    });
  }  
  updateVends() {
    this.roleService.setPopupScroll('main-updateVends');  
    if (!this.salesService.isShowUpdateVendsPopup) {
        $(".sidebar-mini").addClass("filter-open");
      }
      else {
          $(".sidebar-mini").removeClass("filter-open");
          this.roleService.resetPopupScroll()
      }
      setTimeout(() => {
        this.salesService.isShowUpdateVendsPopup = !this.salesService.isShowUpdateVendsPopup;
      }, this.roleService.scrollSetTimeoutTime); 
  }
  exportSalesReport(type){
    this.spinner.show();
    this.salesService.exportSalesReport(type).subscribe(response => {
      if(response != null){
        this.export(response.fileDownloadName,response.fileContents,response.contentType);
        this.spinner.hide();
      }
    }, err => {
      this.spinner.hide();
      let errors = err.error;
      errors.forEach(element => {
        this.toaster.open({
          text: element.msg,
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
      });
    });
  }
  export(filename: string, base64: string,contentType:string) {
    var filetype = contentType; 
    let byteCharacters = atob(base64);
    let byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    this.blob = new Blob([byteArray], { type: filetype });
    FileSaver.saveAs(this.blob, filename);
    $('.ajax-investmentUpload-request').hide();
  }
  getSalesDiscrepencyDetails(element) {
    this.spinner.show();

    this.roleService.setPopupScroll('section-venddetails');  
    if (!this.isOpenSalesDetails) {
      $(".sidebar-mini").addClass("filter-open");
    }
    else {
        $(".sidebar-mini").removeClass("filter-open");
        this.roleService.resetPopupScroll();
    }
    setTimeout(() => {
      this.isOpenSalesDetails = !this.isOpenSalesDetails;  
    }, this.roleService.scrollSetTimeoutTime);
    
    this.salesService.getSalesDiscrepanciById(element.salesDiscrepencyId).subscribe(response => {             
      this.salesDetails = response.data;
      this.spinner.hide();
  }, err => {
    this.spinner.hide();
    this.salesDetails = {};
    let errors = err.error;
    errors.forEach(element => {
      this.toaster.open({
        text: element.msg,
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });
    });
    });
  }

  closeSalesDetails() {
    this.isOpenSalesDetails = false;
    $(".sidebar-mini").removeClass("filter-open");
    this.roleService.resetPopupScroll();
  }

  advanceToggle () {
    this.displayAdvancedSearch = !this.displayAdvancedSearch;  
  }

  applyAdvanceSearch() {
    this.displayAdvancedSearch = false;
    if (this.filter.search != '') {
      this.getSalesData('search')
    }
  }

  getPropositionTypes() { //GD2-4432
    this.entityService.getEntityPropositions(this.roleService.entityId).subscribe(response => {
      if (response && response['data']) {
        this.propositionTypes = response['data'];
      }
    });
  }
}

