import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import * as FileSaver from 'file-saver';
import htmlToPdfmake from 'html-to-pdfmake';
import * as _moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PriceCardTypeLookupConstant } from 'src/app/enums/pricecardtype.enum';
import { AzureService } from 'src/app/services/azure/azure.service';
import { RoleService } from 'src/app/services/role/role.service';
import { SalesService } from 'src/app/services/sales/sales.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var $: any;
const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-user-verification-review',
  templateUrl: './user-verification-review.component.html',
  styleUrls: ['./user-verification-review.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class UserVerificationReviewComponent implements OnInit {

  public cookieName = environment.cookieName;
  currentUserId : string = '';
  @ViewChild(MatPaginator) paginator: MatPaginator;
    displayedColumns: string[] = ['userAutoGeneratedId','lastName', 'firstName', 'email', 'roleType', 'createdOn', 'createdBy', 'status', 'accountSetToInactiveDateTime', 'role', 'entityLevelName', 'entity', 'market', 'lastSignInDateTime', 'lastUpdatedDateTime', 'previousEntityLevelName', 'entityLevelNumberChangedDateTime', 'previousRole', 'userRoleChangedDateTime', 'updatedBy', 'dateOfAudit' ,'userAuditor','IsApproved','auditorComment'];

  dataSource: any = [];
  @ViewChild(MatSort) sort: MatSort;
  isShowFilter = false;
  drawerfilter: boolean = false;
  isFilterClear: boolean = false;
  isFilterApply: boolean = false;
  userList: any = []
  selectedFiled: any;
  entityLevelList: any = [];
  entityList: Observable<string[]>;
  entities: any = [];
  roleTypeList:any[] = [];
  label = "User"
  userRoleList: any = []
  filter = {
   
    search: ''
  }
  recordsTotal = 0;
  pageIndex = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  params = {
      pageNumber: 1,
      pageSize: this.pageSizeOptions[0],
      searchText: '',
      sortBy: 'lastName asc'
  };
    tempfilter = { //[GD2-3706]
        entityLevel: [],
        entity: [],
        userRole: [],
        roleType: [],
        status: [],
        FromDate: [],
        ToDate: []
    }
    tempEntityEvent: any;//[GD2-3706]
    isFromCancelChangefilter: boolean = false;//[GD2-3706]
  dialogRef: MatDialogRef<any>;
  isPageDataLoad = false;
  isEntityDisabled : boolean = true;
  userForm: FormGroup;

  blob: any;
  minDate: any = moment().subtract(this.userService.defaultUIConfigurations.userVerificationListFilterDateMinInMonths, 'months');
  maxDate: any = moment();
  exportFilter = {};
  @ViewChild("confirmDialogTemplate") confirmDialogTemplate: TemplateRef<any>;
  @ViewChild('pdfTable') pdfTable: ElementRef;
  pdfHtml = '';
  enableGridImprovementsFT: boolean;
  showEntityOrRecordName: string = 'Entity';
  constructor(private spinner: NgxSpinnerService,public priceCardTypeConstant:PriceCardTypeLookupConstant,private cookieService: CookieService,private msalService: MsalService,
      private userService: UserService, private router: Router, public dialog: MatDialog, private toaster: Toaster,public roleService:RoleService,private salesService:SalesService, private azureService: AzureService) {
  }
  async ngOnInit(): Promise<void> {
    await this.SetFeatureFlagsConfigurations();
    this.userForm = new FormGroup({});
    this.userForm.addControl('entityLevel', new FormControl('',[]));
    this.userForm.addControl('entity', new FormControl('',[]));
    this.userForm.addControl('userRole', new FormControl('',[]));
    this.userForm.addControl('roleType', new FormControl('',[]));
    this.userForm.addControl('status', new FormControl('',[]));
    this.userForm.addControl('FromDate', new FormControl('',[]));
    this.userForm.addControl('ToDate', new FormControl('',[]));    
    this.currentUserId = this.cookieService.get(this.cookieName + 'userId')
      this.getEntityLevelLookup();
      this.getRoleTypeLookup();
      this.getRoleLookup();
      this.getUserVerificationList();
  }

  private async SetFeatureFlagsConfigurations() {
    this.enableGridImprovementsFT = await this.azureService.isEnableGridImprovementsFT();
    this.showEntityOrRecordName = this.enableGridImprovementsFT ? 'Record' : 'Entity';
  }

    applyFilter() {//[GD2-3706]
        this.assignFilterToTempFilter();
        this.getUserVerificationList('apply');
    }

    assignFilterToTempFilter() {//[GD2-3706]
        this.tempfilter['entityLevel'] = this.userForm.controls['entityLevel'].value;
        this.tempfilter['entity'] = this.userForm.controls['entity'].value;
        this.tempfilter['userRole'] = this.userForm.controls['userRole'].value;
        this.tempfilter['roleType'] = this.userForm.controls['roleType'].value;
        this.tempfilter['status'] = this.userForm.controls['status'].value;
        this.tempfilter['FromDate'] = this.userForm.controls['FromDate'].value;
        this.tempfilter['ToDate'] = this.userForm.controls['ToDate'].value;
    }

  changefilter() {
    this.roleService.setPopupScroll('section-userfilter');  
    if (!this.drawerfilter) {
      $(".sidebar-mini").addClass("filter-open");
   }
    else {
        if (this.tempEntityEvent != undefined) {//[GD2-3706]
            this.tempEntityEvent.value = this.tempfilter['entityLevel'];
            this.isFromCancelChangefilter = true;
            this.getEntityLookup(this.tempEntityEvent);
        }
        //[GD2-3706]
        this.userForm.controls['entityLevel'].setValue((this.tempfilter['entityLevel'] != undefined) ? this.tempfilter['entityLevel'] : '');
        this.userForm.controls['userRole'].setValue((this.tempfilter['userRole'] != undefined && this.tempfilter['userRole'].length) ? this.tempfilter['userRole'] : '');
        this.userForm.controls['roleType'].setValue((this.tempfilter['roleType'] != undefined && this.tempfilter['roleType'].length) ? this.tempfilter['roleType'] : '');
        this.userForm.controls['status'].setValue((this.tempfilter['status'] != undefined && this.tempfilter['status'].length) ? this.tempfilter['status'] : '');
        this.userForm.controls['FromDate'].setValue((this.tempfilter['FromDate'] != undefined && this.tempfilter['FromDate'].length) ? this.tempfilter['FromDate'] : '');
        this.userForm.controls['ToDate'].setValue((this.tempfilter['ToDate'] != undefined && this.tempfilter['ToDate'].length) ? this.tempfilter['ToDate'] : '');

       $(".sidebar-mini").removeClass("filter-open");
       this.roleService.resetPopupScroll();
   }
    setTimeout(() => {
      this.drawerfilter = !this.drawerfilter;  
    }, this.roleService.scrollSetTimeoutTime); 
  }
  clickFilter(val) {
      this.isShowFilter = val;

  }
  clearFilter() {
      this.userForm.controls['entityLevel'].setValue('');
      this.userForm.controls['entity'].setValue('');
      this.userForm.controls['userRole'].setValue('');
      this.userForm.controls['roleType'].setValue('');
      this.userForm.controls['status'].setValue('');
      this.userForm.controls['FromDate'].setValue('');
      this.userForm.controls['ToDate'].setValue('');
      this.isFilterClear = true;
      this.isFilterApply = false;
      let fromClear = true;
      this.params.pageNumber = 1;
      this.entities = [];
      this.isEntityDisabled = true;
      this.assignFilterToTempFilter();//[GD2-3706]
      this.getUserVerificationList(fromClear);
  }  
  sortData(e) {
      e.direction = (e.active == 'isActive' && e.direction != '' ? (e.direction == 'asc' ? 'desc' : 'asc') : e.direction);
      this.params.sortBy = (e.active != '' && e.direction != '' ? e.active + ' ' + e.direction : (e.active != '' && e.direction == '') ? e.active + ' asc' : 'cells_name asc');
      this.params.pageNumber = 1;
      this.getUserVerificationList();
  }
  pageChange(e) {
      this.params.pageNumber = (e.pageIndex > 0 ? (e.pageIndex + 1) : 1);
      this.params.pageSize = (e.pageSize > 0 ? e.pageSize : this.pageSizeOptions[0]);
      this.getUserVerificationList();
  }
  monthDiff(d1, d2) {
    d1 = new Date(d1);
    d2 = new Date(d2)
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}
  getUserVerificationList(fromClear = undefined) {
      this.spinner.show();
      
      if (this.userForm.controls['FromDate'].value && !this.userForm.controls['ToDate'].value) {
        this.userForm.controls['ToDate'].patchValue(moment().format('YYYY-MM-DD'));
      }
      if (!this.userForm.controls['FromDate'].value && this.userForm.controls['ToDate'].value) {
        this.toaster.open({
          text: "Account last updated From Date cannot be empty",
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
        this.spinner.hide();
      } else if (this.userForm.controls['FromDate'].value > this.userForm.controls['ToDate'].value) {
        this.toaster.open({
          text: "Account last updated To Date must be greater than From Date",
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
        this.spinner.hide();
      }else if (this.userForm.controls['FromDate'].value && moment(this.userForm.controls['FromDate'].value).format('YYYY-MM-DD') < moment(this.minDate).format('YYYY-MM-DD')) {
        this.toaster.open({
          text: "Account last updated From Date must be up to past "+this.userService.defaultUIConfigurations.userVerificationListFilterDateMinInMonths+" Months",
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
        this.spinner.hide();
      }else{ 
      
      if (!this.isFilterClear) {
          this.isShowFilter = false;
      }
      this.isFilterClear = false;     
      if (fromClear == undefined)
          this.drawerfilter = false;
      if (fromClear == "search" || fromClear == "apply")
          this.params.pageNumber = 1

      let obj = {};
      this.isFilterApply = false;
      if (this.userForm.controls['entityLevel'].value != undefined && this.userForm.controls['entityLevel'].value != '') { obj['EntityLevel'] = this.userForm.controls['entityLevel'].value; this.isFilterApply = true; };
      if (this.userForm.controls['entity'].value != undefined && this.userForm.controls['entity'].value != '') { obj['EntityId'] = this.userForm.controls['entity'].value; this.isFilterApply = true; };
      if (this.userForm.controls['roleType'].value != undefined && this.userForm.controls['roleType'].value != '') { obj['roleTypeId'] = this.userForm.controls['roleType'].value; this.isFilterApply = true; };
      if (this.userForm.controls['userRole'].value != undefined && this.userForm.controls['userRole'].value != '') { obj['RoleId'] = this.userForm.controls['userRole'].value; this.isFilterApply = true; };
      if (this.userForm.controls['status'].value) { obj['Status'] = this.userForm.controls['status'].value == 'Active' ? true : false; this.isFilterApply = true; };

      if (this.userForm.controls['FromDate'].value) { obj['FromDate'] = moment(this.userForm.controls['FromDate'].value).format('YYYY-MM-DD'); this.isFilterApply = true;}
      if (this.userForm.controls['ToDate'].value) { obj['ToDate'] = moment(this.userForm.controls['ToDate'].value).format('YYYY-MM-DD'); this.isFilterApply = true;} 
      
      if (this.filter.search != '') { obj['SearchText'] = this.filter.search; };
        if (this.params.pageNumber != undefined) { obj['PageNo'] = this.params.pageNumber };
        if (this.params.pageSize != undefined) { obj['PageSize'] = this.params.pageSize };
        if (this.params.sortBy != undefined) { obj['OrderByColumn'] = this.params.sortBy };
        this.userService.getUserVerificationList(obj).subscribe(response => {
          this.exportFilter = obj;
            this.recordsTotal = response?.params?.count;
            this.spinner.hide();
            this.isPageDataLoad=true;
            if (response && response['data']) {
                this.userList = response['data']
            } else {
                this.userList = [];
            }
            if(fromClear == undefined){
              this.drawerfilter = false;
            }            
            if (fromClear == "apply") {
              this.drawerfilter = false;
              $(".sidebar-mini").removeClass("filter-open");
              this.roleService.resetPopupScroll();
            }
        }, err => {
            let errors = err.errors == undefined ? err.error : err.errors;
            errors.forEach(element => {
                this.toaster.open({
                    text: element.msg,
                    type: 'danger',
                    position: 'top-right',
                    duration: 10000
                });
            });
        });
    }
  }

  getEntityLevelLookup() {
      this.userService.getEntityLevelLookup().subscribe(response => {
          if (response && response['data'].length) {
              this.entityLevelList = response['data'];
          }
      });
  }

  getRoleTypeLookup(){
    this.userService.getRoleTypeLookup().subscribe(response => {
        if (response && response['data'].length) {
            this.roleTypeList = response['data'];
        }
    });
  }

  getEntityLookup(event) {
    this.userForm.controls['entity'].setValue('');
      if(event.value != ''){
        this.isEntityDisabled = false;
        this.userService.getEntityLookup(event.value).subscribe(response => {
            if (response && response['data'].length) {
                this.tempEntityEvent = event;//[GD2-3706]
                this.entities = response['data'];
                this.entityList = this.userForm.controls['entity'].valueChanges
                  .pipe(
                  startWith(''),
                  map(value => this._filter(value))
                );
                if (this.isFromCancelChangefilter) { this.userForm.controls['entity'].setValue((this.tempfilter['entity'] != undefined && this.tempfilter['entity'].length) ? this.tempfilter['entity'] : ''); }//[GD2-3706]
            }
            else {//[GD2-3706]
                this.entities = [];
                this.userForm.controls['entity'].setValue('');
            }
        });
      } else {
        this.isEntityDisabled = true;
        this.userForm.controls['entity'].setValue('');
      }
      
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.entities.filter(option => option.name.toLowerCase().includes(filterValue));
  }
  
  getRoleLookup() {
      this.userService.getRoleLookup().subscribe(response => {
          if (response && response['data'].length) {
              this.userRoleList = response['data'];
          }
      });
  }
  
  clickToExport(){
    this.spinner.show();      
    this.userService.exportUserVerificationData(this.exportFilter).subscribe(response => {
      if(response != null){
        this.export(response.fileDownloadName,response.fileContents,response.contentType);
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      let errors = err.error;
      errors.forEach(element => {
        this.toaster.open({
          text: element.msg,
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
      });
    });  
  }
  exportPdf(html=''){
    let fName = 'GRID Users export ('+ moment().utc().format('DDMMyyyy HHmmss'+')');
    var html = htmlToPdfmake(html, { tableAutoSize: true });

    //var cnt = 0;
    const documentDefinition = {
      content: html,
      // pageBreakBefore: function (currentNode) {
      //   if (currentNode.table && cnt == 0) {
      //     cnt++;
      //     return false;
      //   } else if (currentNode.table && cnt > 0) return true;
      //   return false;
      // },
    };
    pdfMake.createPdf(documentDefinition).download(fName + '.pdf');     
    this.spinner.hide();   
  }
  clickToExportPDF(){
    this.spinner.show();      
    this.userService.userreviewexportpdf(this.exportFilter).subscribe(response => {
      if(response != null){
        this.exportPdf(response.htmlData);
      }
    }, err => {
      this.spinner.hide();
      let errors = err.error;
      errors.forEach(element => {
        this.toaster.open({
          text: element.msg,
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
      });
    });  
  }
  export(filename: string, base64: string,contentType:string) {
    var filetype = contentType; //base64.split(';')[0].split(':')[1];
    // var b64data = base64.split(',')[1]
    let byteCharacters = atob(base64);
    let byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    this.blob = new Blob([byteArray], { type: filetype });
    FileSaver.saveAs(this.blob, filename);
    $('.ajax-investmentUpload-request').hide();
  } 

  displayFn(): (id: number) => string {
    return (id: number) => {
      if (id) {
        if (this.entities.find(obj => obj.entityId == id)) {
          let obj = this.entities.find(obj => obj.entityId == id);
          if (Object.keys(obj).length) {
            return obj.name
          } else {
            return '';
          }
        } else {
          return '';
        }
      }
    }
  }  
}

