import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { GetRemoteUpdateTaskList } from '../../dtos/get-remote-update-tasks-list.model';
import { map } from 'rxjs/operators';
import { param } from 'jquery';

@Injectable({
	providedIn: 'root'
})
export class RemoteUpdateTaskGetListService {

	constructor(
		private cookieService: CookieService,
		private httpClient: HttpClient
	) { }

	cookieName: string = environment.cookieName

	getRemoteUpdateTaskList(page: number, limit: number, queryParams: Map<string, string>) {
		let params = new HttpParams();
		params = page !== null ? params.append("page", page.toString()) : params;
		params = limit !== null ? params.append("limit", limit.toString()) : params;

		if (queryParams != null) {
			for (let query of queryParams) {
				params = query[1] !== null ? params.set(query[0], query[1]) : params;
			}
		}

		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(environment.cookieName + 'access_token'),
				'auth_custom': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			},
			params: params
		};

		return this.httpClient
			.get<GetRemoteUpdateTaskList>(environment.apiUrl.remoteUpdate + "/v1/remoteupdatetask", configHeader)
			.pipe(map(response => {
				return response;
			}));
	}
}
