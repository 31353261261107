import * as moment from 'moment';

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { CreateRemoteUpdateTaskRequest } from 'src/app/components/remote-update-tasks/dtos/create-remote-update-task.model';
import { RemoteUpdateTaskDeploymentType } from 'src/app/components/remote-update-tasks/enums/remote-update-task-deployment-type.enum';
import { RemoteUpdateTaskType } from 'src/app/components/remote-update-tasks/enums/remote-update-task-type.enum';
import { RemoteUpdateTaskStatus } from 'src/app/components/remote-update-tasks/enums/remote-update-task-status.enum';
import { RemoteUpdateImmediateDeploymentTooltipEnum } from 'src/app/components/remote-update-tasks/enums/remote-update-immediate-deployment-tooltip.enum';
import { CcDialogService } from '../../../../../../services/ui/cc-dialog.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-step-schedule',
  templateUrl: './step-schedule.component.html',
  styleUrls: ['./../new-task-step-common.css', './step-schedule.component.css'],
})
export class StepScheduleComponent implements OnInit {
  @Output() nextStepEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() backStepEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() newTaskRequest: CreateRemoteUpdateTaskRequest = new CreateRemoteUpdateTaskRequest();

  public isImmediateDeployment: boolean = false;
  public allowToggle: boolean = true;
  public scheduleToggleTooltip: string = '';
  public datePickerDisabled: boolean = false;
  public datePickerMinDate: Date = new Date();
  public datePickerComponentValue: Date;

  public timePickerDisabled: boolean = false;
  public timePickerMinTime: string;
  public timePickerValue: string = '';

  public showDialog: boolean = false;
  public dialogHeader: string = '';
  public dialogBody: string = '';

  public scheduleFormGroup: FormGroup = new FormGroup({
    datePicker: new FormControl({ value: '', disabled: false }, Validators.required),
    timePicker: new FormControl({ value: '', disabled: true }, Validators.required),
  });

  constructor(private dialogService: CcDialogService) {
  }

  ngOnInit(): void {}

  configure (isImmediateDeployment = false, allowToggle = true): void {
    if (this.newTaskRequest.TaskType === RemoteUpdateTaskType.Contactless) {
      this.scheduleToggleTooltip = RemoteUpdateImmediateDeploymentTooltipEnum.Contactless;
    } else {
      this.scheduleToggleTooltip = RemoteUpdateImmediateDeploymentTooltipEnum.NonContactless;
    }

    if (this.isImmediateDeployment !== isImmediateDeployment) {
      this.changeToggle();
    }

    if (isImmediateDeployment) {
      this.updateTime();
    }

    this.allowToggle = allowToggle
  }

  updateTime(): void {
    let date = new Date();
    let currentTime = moment(date).format('hh:mm A');

    this.scheduleFormGroup.get('datePicker').setValue(date);
    this.scheduleFormGroup.get('timePicker').setValue(currentTime);

    this.scheduleFormGroup.get('datePicker').disable();
    this.scheduleFormGroup.get('timePicker').disable();
    this.scheduleFormGroup.markAsUntouched();
  }

  changeToggle(): void {
    if (!this.allowToggle) return;
    this.isImmediateDeployment = !this.isImmediateDeployment;

    if (this.isImmediateDeployment) {
      this.updateTime();
      this.timePickerDisabled = true;
      return;
    }

    this.scheduleFormGroup.get('datePicker').setValue('');
    this.scheduleFormGroup.get('timePicker').setValue('');
    this.scheduleFormGroup.get('datePicker').enable();
    this.timePickerDisabled = false;
  }

  private validate(): void {
    const title = 'Selection Error';

    const datePicker = this.scheduleFormGroup.get('datePicker');
    const timePicker = this.scheduleFormGroup.get('timePicker');

    if ((datePicker.invalid && datePicker.hasError('required')) || (timePicker.invalid && timePicker.hasError('required'))) {
      this.openDialog(title, 'Please complete all the required fields.');
      this.scheduleFormGroup.markAllAsTouched();
      timePicker.updateValueAndValidity();
      return;
    }

    this.validateDate();

    this.validatePastTime(title);
  }

  public validateDate(): void {
    this.scheduleFormGroup.get('datePicker').markAsTouched();
    const datePicker = this.scheduleFormGroup.get('datePicker');

    this.scheduleFormGroup.get('timePicker').setValue('');

    if (datePicker.invalid && datePicker.hasError('matDatepickerParse')) {
      this.openDialog('Selection Error', 'Invalid date format. Please use DD/MM/YYYY format instead.');
      this.scheduleFormGroup.get('datePicker').setValue('');
      this.scheduleFormGroup.get('timePicker').markAsUntouched();
      this.scheduleFormGroup.get('timePicker').disable();
      return;
    }

    if (datePicker.invalid && datePicker.hasError('matDatepickerMin')) {
      this.openDialog('Selection Error', 'The selected date is in the past. Please choose a future date.');
      this.scheduleFormGroup.get('datePicker').setValue('');
      this.scheduleFormGroup.get('timePicker').markAsUntouched();
      this.scheduleFormGroup.get('timePicker').disable();
      return;
    }

    if (datePicker.valid) this.scheduleFormGroup.get('timePicker').enable();
  }

  private validatePastTime(title: string) {
    const currentDate = moment(new Date());

    const datePicker = this.scheduleFormGroup.get('datePicker');
    const timePicker = this.scheduleFormGroup.get('timePicker');

    const datePickerValue = datePicker.value.format('YYYY-MM-DD');
    const currentDateValue = currentDate.format('YYYY-MM-DD');

    const currentHour = Number(currentDate.format('HHmm'));
    const timePickerValues = timePicker.value.split(' ');
    const timePickerHour = timePickerValues[0].split(':');
    const timePickerPeriod = timePickerValues[1];

    let hourCompare;

    if (timePickerPeriod == 'pm') hourCompare = Number(+timePickerHour[0] + 12 + timePickerHour[1]);
    else hourCompare = Number(timePickerHour[0] + timePickerHour[1]);

    if (moment(currentDateValue).isAfter(datePickerValue) || (moment(currentDateValue).isSame(datePickerValue) && hourCompare < currentHour)) {
      this.openDialog(title, 'The time selected is in the past. Please choose a future time');
      this.scheduleFormGroup.get('timePicker').markAsTouched();
      return false;
    }

    return true;
  }

  dateChangeEvent(event: any): void {
    this.scheduleFormGroup.get('timePicker').setValue('');
    this.scheduleFormGroup.markAsUntouched();

    if (event.value) this.scheduleFormGroup.get('timePicker').enable();
    else this.scheduleFormGroup.get('timePicker').disable();
  }

  toDateTime(datePickerValue: moment.Moment, timePickerValue: string): string {
    const month = (datePickerValue.month() + 1).toString().padStart(2, '0');
    let strDate = `${datePickerValue.year()}-${month}-${datePickerValue.date()} ${timePickerValue}`;
    let date = new Date(strDate);

    const year = date.getFullYear();
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  nextStep(): void {
    this.newTaskRequest.TaskStatus = RemoteUpdateTaskStatus.Pending;

    if (this.isImmediateDeployment) {
      const dateTime = moment().format('YYYY-MM-DDTHH:mm');
      this.newTaskRequest.ScheduleOn = dateTime;
      this.newTaskRequest.DeploymentType = RemoteUpdateTaskDeploymentType.Immediate;
      this.nextStepEvent.emit();

      return;
    }

    switch (this.scheduleFormGroup.status) {
      case 'VALID':
        let valid = this.validatePastTime('Selection Error');
        if (valid) {
          const dateTime = this.toDateTime(this.scheduleFormGroup.get('datePicker').value, this.scheduleFormGroup.get('timePicker').value);
          this.newTaskRequest.ScheduleOn = dateTime;
          this.newTaskRequest.DeploymentType = RemoteUpdateTaskDeploymentType.Scheduled;
          this.nextStepEvent.emit();
        }
        break;
      case 'INVALID':
        this.validate();
        break;
    }
  }

  backStep(): void {
    this.backStepEvent.emit();
  }

  isPastTime(event: boolean) {
    if (event) {
      this.openDialog('Selection Error', 'Error with time selection, please select a future time');
    }
  }

  openDialog(title: string, description: string): void {
    const {key, component} = this.dialogService.openDialog();
    component.instance.title = title;
    component.instance.description = description;
    component.instance.primary = 'Ok';
    const dialogSubs = new Subscription();
    dialogSubs.add(
      component.instance.primaryClick.subscribe(() => {
        this.dialogService.closeDialog(key);
        dialogSubs.unsubscribe();
      })
    );
  }
}
