import { NgxMatDateAdapter, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NgxMatMomentDateAdapterOptions, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular-material-components/moment-adapter';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DATE_LOCALE, ThemePalette } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import htmlToPdfmake from 'html-to-pdfmake';
import * as _moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from "ngx-toast-notifications";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { EntityLevel, SOXAuditLogConstant } from 'src/app/constant/audit-log.constant';
import { RoleService } from 'src/app/services/role/role.service';
import { SOXAuditLogsService } from 'src/app/services/sox-audit-logs/sox-audit-logs.service';
import { UserService } from 'src/app/services/user/user.service';
import { CustomNgxDatetimeAdapter } from './customngx.datetime.adapter';
import { RequestGetUserByEntityModel } from './sox-audit-logs-list.model';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { AlertConfirmDialogModel } from '../../common/alert-confirm-dialog/alert-confirm.dialog.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as FileSaver from 'file-saver';
import { EntityService } from 'src/app/services/entity/entity.service';
import { AzureService } from 'src/app/services/azure/azure.service';

declare var $: any;
const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY HH:mm:ss',
    },
    display: {
        dateInput: 'DD/MM/YYYY HH:mm:ss',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

const CustomNgxDatetimeAdapterFactory = () => {
    let _options: NgxMatMomentDateAdapterOptions = { strict: false, useUtc: true }
    return new CustomNgxDatetimeAdapter("", _options);
};

@Component({
    selector: 'app-sox-audit-logs-list',
    templateUrl: './sox-audit-logs-list.component.html',
    styleUrls: ['./sox-audit-logs-list.component.css'],
    providers: [
        {
            provide: NgxMatDateAdapter,
            //useClass: CustomNgxDatetimeAdapter,
            useFactory: CustomNgxDatetimeAdapterFactory,
            deps: [MAT_DATE_LOCALE, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: NGX_MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
})

export class SOXAuditLogsListComponent implements OnInit {
    module: string = "SOX Audit Logs";
    displayedColumns: string[] = ['createdDate', 'logType', 'description', 'firstName', 'lastName', 'email', 'role', 'action', 'result'];
    dataSource: any = [];
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('paginator') paginator: MatPaginator;
    isShowFilter = false;
    drawerfilter: boolean = false;
    isFilterClear: boolean = false;
    isFilterApply: boolean = false;
    auditLogList: any = []
    entityLevelList: any = [];
    entityNameList: any = [];
    userList: any = [];
    logTypeList: any = [];
    minDate: any = moment().utc().subtract(this.userService.defaultUIConfigurations.soxListFilterDateMinInMonths, 'months');
    minToDate: any = moment().utc();
    maxDate: any = moment().utc();
    maxToDate: any = moment().utc();
    defaultLogType = "All Logs";//GD2-2712 //1; // Entity Logs
    entityLevelEnum: typeof EntityLevel
    filter:any = {
        entityLevel: "", //1,
        entityId: [],
        userId: [],
        logType: this.defaultLogType,
        fromDate: moment().utc().subtract(this.userService.defaultUIConfigurations.soxListFilterDefaultDateRangeInDay, 'days').format('YYYY-MM-DD HH:mm:ss'), // Default get last 24 hours data
        toDate: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
        search: ""
    }
    tempfilter: any = {//[GD2-3706]
        entityLevel: "",
        entityId: [],
        userId: [],
        logType: this.defaultLogType,
        fromDate: moment().utc().subtract(this.userService.defaultUIConfigurations.soxListFilterDefaultDateRangeInDay, 'days').format('YYYY-MM-DD HH:mm:ss'), // Default get last 24 hours data
        toDate: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
        search: ""
    }
    filterColumns = ['entityLevel', 'entityId', 'userId', 'logType', 'fromDate', 'toDate'];//[GD2-3706]
    isFromCancelChangefilter: boolean = false;//[GD2-3706]
    recordsTotal = 0;
    pageIndex = 0;
    pageSizeOptions: number[] = [10, 25, 50, 100];
    params = {
        pageNumber: 1,
        pageSize: this.pageSizeOptions[0],
        searchText: '',
        sortBy: 'createdDate desc',
    };
    isPageDataLoad = false;
    textLabel: string = 'Audit Log List';
    dropdownSettings = {
        singleSelection: true,
        defaultOpen: false,
        idField: "entityId",
        textField: "name",
        allowSearchFilter: true
    };
    userDropdownSettings = {
        singleSelection: true,
        defaultOpen: false,
        idField: "userId",
        textField: "name",
        allowSearchFilter: true
    };
    public disabled = false;
    public showSpinners = true;
    public showSeconds = true;
    public touchUi = false;
    public enableMeridian = false;
    public stepHour = 1;
    public stepMinute = 1;
    public stepSecond = 1;
    public color: ThemePalette = 'primary';
    advanceFilter = {
        entityID: false,
        entityName: false,
        billTo: false,
        sellTo: false,
        reference: false,
        machineSerialNumber: false
    }
    displayAdvancedSearch = false;

    constructor(
        private spinner: NgxSpinnerService,
        private soxAuditLogsService: SOXAuditLogsService,
        public soxAuditLogConstant: SOXAuditLogConstant,
        private toaster: Toaster,
        public roleService: RoleService,
        private userService:UserService,
        private router: Router,
        private azureService: AzureService,
        private entityService: EntityService) { }

    ngOnInit(): void {
        if (!this.roleService.objRole.isSystemAdmin && !this.roleService.objRole.isGlobalMarketAdmin && !this.roleService.objRole.isMarketAdmin && !this.roleService.objRole.isLevel2Admin && !this.roleService.objRole.isLevel3Admin && !this.roleService.objRole.isCustomerAdmin && !this.roleService.objRole.isUserAuditorGRIDR && !this.roleService.objRole.isUserAuditorLevel1R && !this.roleService.objRole.isUserAuditorLevel2R && !this.roleService.objRole.isUserAuditorLevel3R){
            this.router.navigate(['/unauthorized']);
        }else{
            if (this.roleService.objRole.isLevel2Admin || this.roleService.objRole.isLevel3Admin || this.roleService.objRole.isCustomerAdmin){
                this.filter.entityLevel= '';
            }

            this.azureService.isEnableDrinkSetupFT().then(isDrinkSetupEnabled => {
              if (isDrinkSetupEnabled) {
                const idx = this.soxAuditLogConstant.logTypeList.findIndex(x => x.logType === "Price Card Templates Logs");
                if (idx !== -1) {
                  this.logTypeList = [...this.soxAuditLogConstant.logTypeList.slice(0, idx), this.soxAuditLogConstant.drinkSetup, ...this.soxAuditLogConstant.logTypeList.slice(idx + 1)];
                }
              }
            })
            .catch(_ => {
              this.toaster.open({
                text: 'Something wrong happened while trying to check if Drink Setup Feature is enabled.',
                type: 'danger',
                position: 'top-right',
                duration: 10000,
              });
            });

            this.logTypeList = this.soxAuditLogConstant.logTypeList;
            this.entityLevelEnum = EntityLevel;
            this.minDate = moment(this.minDate).startOf('day');
            this.minToDate = moment(this.minDate).startOf('day');
            this.getEntityLevelLookup();
            if (this.roleService.objRole.isMarketAdmin && this.filter.entityLevel == EntityLevel.Market) {
                this.spinner.show()
                this.getEntityLookupMarketAdmin(this.filter.entityLevel, true);
            }
            else {
                this.getEntityLookup(this.filter.entityLevel);
                this.getSOXAuditLogsList();
            }
        }
    }
    getEntityLevelLookup() {
        this.userService.getEntityLevelLookup().subscribe(response => {
            if (response && response['data'].length) {
                this.entityLevelList = response['data'];
            }
        });
    }
    applyFilter() {//[GD2-3706]
        this.assignFilterToTempFilter();
        this.getSOXAuditLogsList('apply');
    }

    assignFilterToTempFilter() {//[GD2-3706]
        this.filterColumns.forEach(key => {
            this.tempfilter[key] = this.filter[key];
        });
    }
    changefilter() {
        this.roleService.setPopupScroll('section-machine-filter');
        if (!this.drawerfilter) {
            this.displayAdvancedSearch = false;
            $(".sidebar-mini").addClass("filter-open");
        }
        else {
            //[GD2-3706]
            this.filterColumns.forEach(key => {
                let valueChanged = false;
                if (this.filter[key] != this.tempfilter[key]) { valueChanged = true; }
                this.filter[key] = this.tempfilter[key];
                if (key == 'entityLevel' && this.filter['entityLevel'] != undefined && valueChanged) {
                    this.isFromCancelChangefilter = true;
                    if (this.roleService.objRole.isMarketAdmin && this.filter.entityLevel == EntityLevel.Market) {
                        this.spinner.show()
                        this.getEntityLookupMarketAdmin(this.filter.entityLevel, true);
                    }
                    else {
                        this.getEntityLookup(this.filter.entityLevel);
                    }
                }
            });
            $(".sidebar-mini").removeClass("filter-open");
            this.roleService.resetPopupScroll()
        }
        setTimeout(() => {
            this.drawerfilter = !this.drawerfilter;

        }, this.roleService.scrollSetTimeoutTime);
    }

    clickFilter(val) {
        this.isShowFilter = val;
    }

    clearFilter() {
        this.minDate = moment().utc().subtract(this.userService.defaultUIConfigurations.soxListFilterDateMinInMonths, 'months');
        this.minDate = moment(this.minDate).startOf('day');
        this.minToDate = moment(this.minDate).startOf('day');
        this.maxDate = moment().utc();
        this.maxToDate = moment().utc();
        //if(this.roleService.objRole.isEntityLevel2Admin || this.roleService.objRole.isEntityLevel3Admin || this.roleService.objRole.isCustomerAdmin){
        //    this.filter.entityLevel= '';
        //}else{
        //    this.filter.entityLevel = 1;
        //}
        this.filter.entityLevel = '';
        this.filter.entityId = [];
        this.filter.userId = [];
        this.filter.logType = this.defaultLogType;
        this.filter.fromDate = moment().utc().subtract(this.userService.defaultUIConfigurations.soxListFilterDefaultDateRangeInDay, 'days').format('YYYY-MM-DD HH:mm:ss'),
        this.filter.toDate = moment().utc().format('YYYY-MM-DD HH:mm:ss'),
        this.isFilterClear = true;
        this.isFilterApply = false;
        let fromClear = true;
        this.params.pageNumber = 1;//[GD2-3706]
        this.assignFilterToTempFilter();//[GD2-3706]
        this.advanceFilter = {
            entityID: false,
            entityName: false,
            billTo: false,
            sellTo: false,
            reference: false,
            machineSerialNumber: false
        }
        //this.entityLevelList = this.soxAuditLogConstant.entityLevelList;
        if (this.roleService.objRole.isMarketAdmin && this.filter.entityLevel == EntityLevel.Market) {
            this.spinner.show()
            this.getEntityLookupMarketAdmin(this.filter.entityLevel, true);
        }
        else {
            this.getEntityLookup(this.filter.entityLevel);
            this.getSOXAuditLogsList();
        }
    }

    getEntityLookup(entityLevel) {
        if(entityLevel == 0){
            entityLevel = '';
        }
        if (!entityLevel) {
            this.entityNameList = [];
            this.filter.entityId = [];
            this.getUserLookupByEntity(false);
        }else{
            this.soxAuditLogsService.getEntityLookup(entityLevel).subscribe(response => {
                if (response && response['data'].length) {
                    this.entityNameList = response['data'];
                    if ((this.roleService.objRole.isMarketAdmin || this.roleService.objRole.isUserAuditorLevel1R) && this.filter.entityLevel == EntityLevel.Market) {
                        this.filter.entityId = response['data']
                        if (this.isFromCancelChangefilter && this.tempfilter['entityId'].length) {//[GD2-3706]
                                this.filter.entityId = this.tempfilter.entityId;
                                this.getSOXAuditLogsList();
                        }
                    }
                    else {
                        this.filter.entityId = []
                        if (this.isFromCancelChangefilter && this.tempfilter['entityId'].length) {//[GD2-3706]
                            this.filter.entityId = this.tempfilter.entityId;
                            this.getSOXAuditLogsList();
                        }
                    }
                } else {
                    this.entityNameList = [];
                    this.filter.entityId = [];
                }
                this.getUserLookupByEntity(false);

            });
        }
    }


    getEntityLookupMarketAdmin(entityLevel, isMarketAdmin) {
        if (entityLevel == "") {
            this.entityNameList = [];
            this.filter.entityId = [];
            this.getUserLookupByEntity(false);
        }
        this.soxAuditLogsService.getEntityLookup(entityLevel).subscribe(response => {
            if (response && response['data'].length) {
                this.entityNameList = response['data'];
                if (this.roleService.objRole.isMarketAdmin && this.filter.entityLevel == EntityLevel.Market) {
                    this.filter.entityId = response['data'];
                    if (this.isFromCancelChangefilter) { if (this.tempfilter['entityId'].length) this.filter.entityId = this.tempfilter.entityId }//[GD2-3706]
                }
                else {
                    this.filter.entityId = []
                    if (this.isFromCancelChangefilter) { if (this.tempfilter['entityId'].length) this.filter.entityId = this.tempfilter.entityId }//[GD2-3706]
                }
            } else {
                this.entityNameList = [];
                this.filter.entityId = [];
            }
            this.getUserLookupByEntity(false, isMarketAdmin);

        }, err => {
            this.spinner.hide();
            let errors = err.errors == undefined ? err.error : err.errors;
            if (errors != null && errors.length > 0) {
                errors.forEach(element => {
                    this.toaster.open({
                        text: element.msg,
                        type: 'danger',
                        position: 'top-right',
                        duration: 10000
                    });
                });
            }
        });
    }

    getUserLookup(entityId, isMarketAdmin) {

        let request: RequestGetUserByEntityModel = new RequestGetUserByEntityModel();
        if (entityId != undefined)
            request.entityId = entityId.map(a => a.entityId);
        else
            request.entityId = [];
        this.filter.userId = []
        this.soxAuditLogsService.getUserLookup(request).subscribe(response => {
            if (response && response['data'].length) {
                this.userList = response['data'];
            } else {
                this.userList = [];
            }
            if (isMarketAdmin) {
                this.getSOXAuditLogsList();
            }
        }, err => {
            this.spinner.hide();
            let errors = err.errors == undefined ? err.error : err.errors;
            if (errors != null && errors.length > 0) {
                errors.forEach(element => {
                    this.toaster.open({
                        text: element.msg,
                        type: 'danger',
                        position: 'top-right',
                        duration: 10000
                    });
                });
            }
        });
    }

    getUserLookupByEntity(deselect, isMarketAdmin?: boolean) {
        if (deselect || this.filter.entityId.length == 0) {
            this.getUserLookup(null, isMarketAdmin);
        }
        if (this.filter.entityId.length > 0) {
            this.getUserLookup(this.filter.entityId, isMarketAdmin);
        }
    }

    clearSearch() {
        this.filter.search = '';
        this.advanceFilter = {
            entityID: false,
            entityName: false,
            billTo: false,
            sellTo: false,
            reference: false,
            machineSerialNumber: false
        }
        this.getSOXAuditLogsList()
    }

    sortData(e) {
        this.params.pageNumber = 1;
        if (this.textLabel == 'Audit Log List') {
            this.params.sortBy = (e.active != '' && e.direction != '' ? e.active + ' ' + e.direction : (e.active != '' && e.direction == '') ? e.active + ' asc' : 'cells_name asc');
            this.getSOXAuditLogsList()
        }
    }

    pageChange(e) {
        this.params.pageNumber = (e.pageIndex > 0 ? (e.pageIndex + 1) : 1);
        this.params.pageSize = (e.pageSize > 0 ? e.pageSize : this.pageSizeOptions[0]);
        if (this.textLabel == 'Audit Log List') {
            this.getSOXAuditLogsList()
        }
    }

    searchText() {
        if (this.textLabel == 'Audit Log List') {
            this.getSOXAuditLogsList('search')
        }
    }


    getSOXAuditLogsList(fromClear = undefined) {
        this.displayAdvancedSearch = false;
        //this.drawerfilter = false;
        this.spinner.show();
        if (!this.isFilterClear) {
            //this.isShowFilter = false;
        }
        this.isFilterClear = false;
        if (fromClear == "apply") {
            //this.changefilter();
        }
        if (fromClear == "search" || fromClear == "apply") {
            this.params.pageNumber = 1;
        }
        let obj = {};
        this.isFilterApply = false;
        if (this.filter.entityLevel) { obj['entityLevel'] = this.filter.entityLevel; this.isFilterApply = true; };
        if (this.filter.entityId) { obj['entityId'] = this.filter.entityId.map(a => a.entityId); this.isFilterApply = true; };
        if (this.filter.fromDate) { obj['fromDate'] = moment(this.filter.fromDate).format('YYYY-MM-DD HH:mm:ss'); this.isFilterApply = true; };
        if (this.filter.toDate != '') { obj['toDate'] = moment(this.filter.toDate).format('YYYY-MM-DD HH:mm:ss'); this.isFilterApply = true; };
        if (this.filter.userId) { obj['userId'] = this.filter.userId.map(a => a.userId); this.isFilterApply = true; };
        if (this.filter.logType != '') { obj['logType'] = this.filter.logType; this.isFilterApply = true; };
        if (this.filter.search != '') { obj['searchText'] = this.filter.search; };
        if (this.params.pageNumber != undefined) { obj['pageNo'] = this.params.pageNumber };
        if (this.params.pageSize != undefined) { obj['pageSize'] = this.params.pageSize };
        if (this.params.sortBy != undefined) { obj['orderByColumn'] = this.params.sortBy };
        var advanceSearchColumns = [];
        if (this.advanceFilter.entityID) {advanceSearchColumns.push("EntityID")};
        if (this.advanceFilter.entityName) {advanceSearchColumns.push("EntityName")};
        if (this.advanceFilter.billTo) {advanceSearchColumns.push("BillTo")};
        if (this.advanceFilter.sellTo) {advanceSearchColumns.push("SellTo")};
        if (this.advanceFilter.reference) {advanceSearchColumns.push("Reference")};
        if (this.advanceFilter.machineSerialNumber) {advanceSearchColumns.push("MachineSerialNumber")};
        if (advanceSearchColumns.length > 0) { obj['advanceSearch'] = advanceSearchColumns; }

        var isvalid = true;
        if (this.filter.fromDate && !this.filter.toDate) {
            this.filter.toDate = this.maxToDate;
        }
        if (!this.filter.fromDate && this.filter.toDate) {
            isvalid = false;
            this.toaster.open({
                text: "From date cannot be empty",
                type: 'danger',
                position: 'top-right',
                duration: 10000
            });
            this.spinner.hide();
        }

        let fDate = moment(this.filter.fromDate).format('YYYY-MM-DD HH:mm:ss');;
        let tDate = moment(this.filter.toDate).subtract(this.userService.defaultUIConfigurations.soxListFilterDateMaxRangeInMonths,'months').format('YYYY-MM-DD HH:mm:ss');
        if (this.filter.fromDate && this.filter.toDate) {
            if (this.filter.fromDate > this.filter.toDate) {
                isvalid = false;
                this.toaster.open({
                    text: "To Date must be grater than from date",
                    type: 'danger',
                    position: 'top-right',
                    duration: 10000
                });
                this.spinner.hide();
            }
            else if (fDate < tDate) {
                isvalid = false;
                this.toaster.open({
                    text: "You can filter logs for maximum " + this.userService.defaultUIConfigurations.soxListFilterDateMaxRangeInMonths + " months.",
                    type: 'danger',
                    position: 'top-right',
                    duration: 10000
                });
                this.spinner.hide();
            }

        }
        if (isvalid) {
            this.soxAuditLogsService.getSOXAuditLogsList(obj).subscribe(response => {
                if (fromClear == "apply") {
                    this.changefilter();
                } else if (fromClear != undefined) {
                    console.log('fromClear', fromClear);

                    this.drawerfilter = false;
                }
                this.recordsTotal = response?.params?.count;
                this.isPageDataLoad = true;
                if (response && response['data'].length) {
                    this.auditLogList = response['data']
                } else {
                    this.auditLogList = [];
                }
                this.spinner.hide();
            }, err => {
                this.spinner.hide();
                let errors = err.errors == undefined ? err.error : err.errors;
                if (errors != null && errors.length > 0) {
                    errors.forEach(element => {
                        this.toaster.open({
                            text: element.msg,
                            type: 'danger',
                            position: 'top-right',
                            duration: 10000
                        });
                    });
                }
            });
        }
    }
    clickToExportPDF(fromClear = undefined){
        if (this.recordsTotal > 10000) {
            this.toaster.open({
                text: "You can only download 10,000 records at a time, please use the date range filter to limit the amount of records that are exported",
                type: 'danger',
                position: 'top-right',
                duration: 10000
            });
        } else {
            this.spinner.show();
            this.isFilterClear = false;

            let obj = {};
            this.isFilterApply = false;
            if (this.filter.entityLevel) { obj['entityLevel'] = this.filter.entityLevel; this.isFilterApply = true; };
            if (this.filter.entityId) { obj['entityId'] = this.filter.entityId.map(a => a.entityId); this.isFilterApply = true; };
            if (this.filter.fromDate) { obj['fromDate'] = moment(this.filter.fromDate).format('YYYY-MM-DD HH:mm:ss'); this.isFilterApply = true; };
            if (this.filter.toDate != '') { obj['toDate'] = moment(this.filter.toDate).format('YYYY-MM-DD HH:mm:ss'); this.isFilterApply = true; };
            if (this.filter.userId) { obj['userId'] = this.filter.userId.map(a => a.userId); this.isFilterApply = true; };
            if (this.filter.logType != '') { obj['logType'] = this.filter.logType; this.isFilterApply = true; };
            if (this.filter.search != '') { obj['searchText'] = this.filter.search; };
            if (this.params.pageNumber != undefined) { obj['pageNo'] = 1; };
            if (this.params.pageSize != undefined) { obj['pageSize'] = 10000; };
            if (this.params.sortBy != undefined) { obj['orderByColumn'] = this.params.sortBy };
            var isvalid = true;
            if (this.filter.fromDate && !this.filter.toDate) {
                this.filter.toDate = this.maxToDate;
            }
            if (!this.filter.fromDate && this.filter.toDate) {
                isvalid = false;
                this.toaster.open({
                    text: "From date cannot be empty",
                    type: 'danger',
                    position: 'top-right',
                    duration: 10000
                });
                this.spinner.hide();
            }

            let fDate = moment(this.filter.fromDate).format('YYYY-MM-DD HH:mm:ss');;
            let tDate = moment(this.filter.toDate).subtract(this.userService.defaultUIConfigurations.soxListFilterDateMaxRangeInMonths,'months').format('YYYY-MM-DD HH:mm:ss');
            if (this.filter.fromDate && this.filter.toDate) {
                if (this.filter.fromDate > this.filter.toDate) {
                    isvalid = false;
                    this.toaster.open({
                        text: "To Date must be grater than from date",
                        type: 'danger',
                        position: 'top-right',
                        duration: 10000
                    });
                    this.spinner.hide();
                }
                else if (fDate < tDate) {
                    isvalid = false;
                    this.toaster.open({
                        text: "You can filter logs for maximum " + this.userService.defaultUIConfigurations.soxListFilterDateMaxRangeInMonths + " months.",
                        type: 'danger',
                        position: 'top-right',
                        duration: 10000
                    });
                    this.spinner.hide();
                }

            }
            if (isvalid) {
                this.soxAuditLogsService.exportSOXAuditLogsPdf(obj).subscribe(response => {
            if(response != null){
                this.exportPdf(response.htmlData);
            }
            this.spinner.hide();
            }, err => {
            this.spinner.hide();
            let errors = err.error;
            errors.forEach(element => {
                this.toaster.open({
                text: element.msg,
                type: 'danger',
                position: 'top-right',
                duration: 10000
                });
            });
            });
        }
        }
    }
    exportPdf(html=''){
        const fName = 'Audit Log export ('+ moment().utc().format('DDMMyyyy HHmmss'+')');
        var html = htmlToPdfmake(html, { tableAutoSize: true });

        //var cnt = 0;
        const documentDefinition = {
          content: html,
          // pageBreakBefore: function (currentNode) {
          //   if (currentNode.table && cnt == 0) {
          //     cnt++;
          //     return false;
          //   } else if (currentNode.table && cnt > 0) return true;
          //   return false;
          // },
        };
        pdfMake.createPdf(documentDefinition).download(fName + '.pdf');
        this.spinner.hide();
    }
    clickToExportExcel() {
        this.spinner.show();
        this.isFilterClear = false;

        let obj = {};
        this.isFilterApply = false;
        if (this.filter.entityLevel) { obj['entityLevel'] = this.filter.entityLevel; this.isFilterApply = true; };
        if (this.filter.entityId) { obj['entityId'] = this.filter.entityId.map(a => a.entityId); this.isFilterApply = true; };
        if (this.filter.fromDate) { obj['fromDate'] = moment(this.filter.fromDate).format('YYYY-MM-DD HH:mm:ss'); this.isFilterApply = true; };
        if (this.filter.toDate != '') { obj['toDate'] = moment(this.filter.toDate).format('YYYY-MM-DD HH:mm:ss'); this.isFilterApply = true; };
        if (this.filter.userId) { obj['userId'] = this.filter.userId.map(a => a.userId); this.isFilterApply = true; };
        if (this.filter.logType != '') { obj['logType'] = this.filter.logType; this.isFilterApply = true; };
        if (this.filter.search != '') { obj['searchText'] = this.filter.search; };
        if (this.params.pageNumber != undefined) { obj['pageNo'] = 1; };
        if (this.params.pageSize != undefined) { obj['pageSize'] = 10000; };
        if (this.params.sortBy != undefined) { obj['orderByColumn'] = this.params.sortBy };
        var isvalid = true;
        if (this.filter.fromDate && !this.filter.toDate) {
            this.filter.toDate = this.maxToDate;
        }
        if (!this.filter.fromDate && this.filter.toDate) {
            isvalid = false;
            this.toaster.open({
                text: "From date cannot be empty",
                type: 'danger',
                position: 'top-right',
                duration: 10000
            });
            this.spinner.hide();
        }

        let fDate = moment(this.filter.fromDate).format('YYYY-MM-DD HH:mm:ss');;
        let tDate = moment(this.filter.toDate).subtract(this.userService.defaultUIConfigurations.soxListFilterDateMaxRangeInMonths,'months').format('YYYY-MM-DD HH:mm:ss');
        if (this.filter.fromDate && this.filter.toDate) {
            if (this.filter.fromDate > this.filter.toDate) {
                isvalid = false;
                this.toaster.open({
                    text: "To Date must be grater than from date",
                    type: 'danger',
                    position: 'top-right',
                    duration: 10000
                });
                this.spinner.hide();
            }
            else if (fDate < tDate) {
                isvalid = false;
                this.toaster.open({
                    text: "You can filter logs for maximum " + this.userService.defaultUIConfigurations.soxListFilterDateMaxRangeInMonths + " months.",
                    type: 'danger',
                    position: 'top-right',
                    duration: 10000
                });
                this.spinner.hide();
            }

        }
        if (isvalid) {
            this.soxAuditLogsService.exportsSOXAuditLogExcel(obj).subscribe(response => {
        if(response != null){
            this.export(response.fileDownloadName, response.fileContents, response.contentType);
        }
        this.spinner.hide();
        }, err => {
        this.spinner.hide();
        let errors = err.error;
        errors.forEach(element => {
            this.toaster.open({
            text: element.msg,
            type: 'danger',
            position: 'top-right',
            duration: 10000
            });
        });
        });
        }
    }
    export(filename: string, base64: string,contentType:string) {
        var filetype = contentType;
        let byteCharacters = atob(base64);
        let byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        let blob = new Blob([byteArray], { type: filetype });
        FileSaver.saveAs(blob, filename);
        $('.ajax-investmentUpload-request').hide();
      }

    advanceToggle () {
        this.displayAdvancedSearch = !this.displayAdvancedSearch;
    }

    applyAdvanceSearch() {
      this.displayAdvancedSearch = false;
      if (this.filter.search != '') {
        this.getSOXAuditLogsList('search');
      }
    }
}
