import { CreateRemoteUpdateTaskRequest } from "../components/remote-update-tasks/dtos/create-remote-update-task.model";
import { RemoteUpdateTaskType } from "../components/remote-update-tasks/enums/remote-update-task-type.enum";

export function getContactlessEnabled(task: CreateRemoteUpdateTaskRequest): boolean | null {
  if (task.TaskType === RemoteUpdateTaskType.Contactless) {
    const contactlessEnabled = task.Metadata?.['enabled'];
    if (typeof contactlessEnabled === 'boolean') {
      return contactlessEnabled;
    }
  }
  return null;
}