import { Component, OnInit, ViewChild } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import * as _moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { EntityService } from 'src/app/services/entity/entity.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { NotificationCountService } from 'src/app/services/notification/notificationCountService.service';
import { RoleService } from 'src/app/services/role/role.service';
import { UserService } from 'src/app/services/user/user.service';

const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class NotificationComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, public roleService: RoleService, private notificationService: NotificationService,private entityService: EntityService,
    private router: Router, public dialog: MatDialog, private toaster: Toaster, private notificationCountService: NotificationCountService, private userService:UserService) { }

  textLabel = 'Outstanding'
  isOutStanding: boolean = true;
  isCompleted: boolean = false;
  notificationList: any[] = [];
  pageNo: number = 1;
  lastNotificationDate: Date;
  totalCount: number = 0;
  resordsTotal : number = 0;
  isPageDataLoad : boolean = false;
  markets: any = [];
  partners: any = [];
  sites: any = [];
  machines : any[] = [];
  issueTypes : any[] = [];
  completedBys : any[] = [];
  notificationLogsList : any[] = [];
  filter:any = {
    marketId: '',
    partnerId: '',
    siteId: '',
    machineId : '',
    issueType: '',
    completedBy: '',
    notificationState : '',
    notificationCompletedFromDate : '',
    notificationCompletedToDate : ''
    }
   tempfilter: any = {//[GD2-3706]
        marketId: '',
        partnerId: '',
        siteId: '',
        machineId: '',
        issueType: '',
        completedBy: '',
        notificationState: '',
        notificationCompletedFromDate: '',
        notificationCompletedToDate: ''
    }
  filterColumns: string[] = ['marketId', 'partnerId', 'siteId', 'machineId', 'issueType', 'completedBy', 'notificationState', 'notificationCompletedFromDate', 'notificationCompletedToDate'];//[GD2-3706]
  isFromCancelChangefilter: boolean = false;//[GD2-3706]
  isShowFilter = false;
  drawerfilter: boolean = false;
  isFilterClear: boolean = false;
  isFilterApply: boolean = false;
  minDate: any = moment().subtract(this.userService.defaultUIConfigurations.notificationFilterLogsDateMinInYears, 'year');
  maxDate: any = moment();
  minToDate : any = moment();
  maxToDate : any = moment();
  pageIndex = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  params = {
      pageNumber: 1,
      pageSize: this.pageSizeOptions[0],
      searchText: '',
      sortBy: 'createdDateTime desc'
  };
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  isApplyDisabled : boolean = false;
  hasAuth = true;

  ngOnInit(): void {
    this.minDate = moment(this.minDate).startOf('day');
    this.filter.notificationCompletedFromDate = moment().subtract(this.userService.defaultUIConfigurations.notificationFilterCompletedFromInDays, 'days');
    this.filter.notificationCompletedToDate = moment();
    this.minToDate = this.filter.notificationCompletedFromDate;
    this.maxToDate = this.maxDate;
      if (!this.roleService.objRole.isMarketAdmin && !this.roleService.objRole.isSystemAdmin && !this.roleService.objRole.isGlobalMarketAdmin && !this.roleService.objRole.isLevel2Admin && !this.roleService.objRole.isLevel3Admin && !this.roleService.objRole.isUserAuditorGRIDR && !this.roleService.objRole.isUserAuditorLevel1R && !this.roleService.objRole.isUserAuditorLevel2R && !this.roleService.objRole.isUserAuditorLevel3R) {
      this.router.navigate(['/unauthorized']);
      this.hasAuth = false;
    }

    if(this.hasAuth){
      this.getIssueTypeLookup();
      if (this.roleService.objRole.isUserAuditorGRIDR )
        this.textLabel = 'Logs';

      if (this.textLabel == 'Logs'){
        this.getMarketLookup();
        this.getPartnerLookup(true);
        this.getCompletedByLookup();
        this.getNotificationLogsList();
      }
      if (!this.roleService.objRole.isUserAuditorGRIDR )
        this.getNotifications();
    }
  }

  onTabChanged(event) {
    this.filter = {
      marketId: '',
      partnerId: '',
      siteId: '',
      machineId : '',
      issueType: '',
      completedBy: '',
      notificationState : '',
      notificationCompletedFromDate :  moment().subtract(this.userService.defaultUIConfigurations.notificationFiltercompletedDateMinRangeInDays, 'days'),
      notificationCompletedToDate : moment()
    }
    this.textLabel = event.tab.textLabel;
    this.notificationList = [];
    this.pageNo = 1;
    this.isPageDataLoad = false;
    window.scrollTo(0,0);
    if (this.textLabel == 'Outstanding') {
      this.isOutStanding = true;
      this.isCompleted = false;
    } else if (this.textLabel == 'Completed') {
      this.isOutStanding = false;
      this.isCompleted = true;
    } else if (this.textLabel == 'Logs') {
      this.getIssueTypeLookup();
      this.getCompletedByLookup();
    }
    this.clearFilter();
  }

  getNotifications(fromClear = undefined) {
    this.spinner.show();

    if (!this.isFilterClear) {
      this.isShowFilter = false;
      this.drawerfilter = false;
    }
    this.isFilterClear = false;
    if (fromClear == "apply") {
        $(".sidebar-mini").removeClass("filter-open");
        this.roleService.resetPopupScroll();
    }
    if (fromClear == undefined)
        this.drawerfilter = false;
    if (fromClear == undefined || fromClear == "apply")
        this.pageNo = 1

    var body = {
      'CheckHasOutStandingNotifications': false,
      'IsOutStanding': this.isOutStanding,
      'IsCompleted': this.isCompleted,
      'PageNo': this.pageNo
    }
    let obj = {};
    if (this.filter.issueType != undefined && this.filter.issueType != '') {
      body['IssueType'] = this.filter.issueType;
      this.isFilterApply = true;
    };
    this.notificationService.getNotifications(body).subscribe(response => {
      if (response != null) {
        this.isPageDataLoad = true;
        this.totalCount = response?.params?.count;
        if(this.isOutStanding){
          this.notificationCountService.updateCount(response?.params?.count);
        }
        if (response['data'].length) {
          if (fromClear == "scroll"){
            response['data'].forEach(element => {
              this.notificationList.push(element);
            });

          }else{
            this.notificationList = response['data'];
        }
        }else{
          this.notificationList = [];
        }
        if(this.isOutStanding){
          this.getLastNotificationDate();
        }
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      let errors = err.error;
      errors.forEach(element => {
        this.toaster.open({
          text: element.msg,
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
      });
    });
  }

  onScrollDown() {
    if (this.notificationList.length > 0) {
      console.log(this.totalCount);
      if (this.totalCount > ((this.pageNo) * 10)) {
        this.pageNo += 1;
        this.getNotifications('scroll');
      }
    }
  }

  getLastNotificationDate() {
    this.notificationService.getLastNotificationDate().subscribe(response => {
      if (response != null) {
          this.lastNotificationDate = response.lastUpdateDate;
      }
    }, err => {
      let errors = err.error;
      errors.forEach(element => {
        this.toaster.open({
          text: element.msg,
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
      });
    });
  }

  moveToCompletedNotification(notificationId) {
    this.notificationService.moveToCompletedNotification(notificationId).subscribe(response => {
      if (response != null) {
        if (response) {
          this.toaster.open({
            text: 'Notification completed successfully',
            type: 'info',
            position: 'top-right',
            duration: 10000
          });
        }
        this.notificationList = [];
        this.pageNo = 1;
        this.getNotifications();
        this.getLastNotificationDate();
        window.scrollTo(0,0);
      }
    }, err => {
      let errors = err.error;
      errors.forEach(element => {
        this.toaster.open({
          text: element.msg,
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
      });
    });
  }

  refreshNotifications(){
    this.notificationList = [];
    this.pageNo = 1;
    this.getNotifications();
    window.scrollTo(0,0);
  }

  getMarketLookup() {
    if (this.roleService.objRole.isSystemAdmin || this.roleService.objRole.isGlobalMarketAdmin || this.roleService.objRole.isUserAuditorGRIDR || this.roleService.objRole.isUserAuditorLevel1R) {
      this.entityService.getMarketLookup().subscribe(response => {
        if(response != null){
          if (response['data'].length) {
            this.markets = response['data'];
          }
        }
      });
    }
  }

  getPartnerLookup(isPageLoad) {
    this.sites = [];
    this.partners = [];
    this.machines = [];
    this.filter.siteId = '';
    this.filter.partnerId = '';
    this.filter.machineId = '';
    if(!isPageLoad){
    this.spinner.show();
    }
    this.entityService.getPartnerLookup(this.filter.marketId).subscribe(response => {
      if(!isPageLoad){
      this.spinner.hide();
      }
      if (response && response['data'].length) {
        this.partners = response['data'];
      }
    });
    if(this.filter.marketId == ""){
      this.getSiteLookup(isPageLoad);
      this.getMachineLookup(isPageLoad);
    }
  }

  getSiteLookup(isPageLoad) {
    this.sites = [];
    this.machines = [];
    this.filter.siteId = '';
    this.filter.machineId = '';
    if(!isPageLoad){
    this.spinner.show();
    }
      if (this.filter.marketId != "" && this.filter.partnerId.length == 0) { this.sites = []; this.spinner.hide(); }//[GD2-3796]
      else {
          this.entityService.getSiteLookup(this.filter.partnerId).subscribe(response => {
              if (!isPageLoad) {
                  this.spinner.hide();
              }
              if (response && response['data'].length) {
                  this.sites = response['data'];
              }
          });
      }
  }

  getMachineLookup(isPageLoad) {
    this.machines = [];
    this.filter.machineId = '';
    if(!isPageLoad){
    this.spinner.show();
    }
      if (this.filter.partnerId != "" && this.filter.siteId.length == 0) { this.machines = []; this.spinner.hide(); }//[GD2-3796]
      else {
          this.notificationService.getMachineLookup((this.filter.siteId != '' && this.filter.siteId != null) ? [this.filter.siteId] : null).subscribe(response => {
              if (!isPageLoad) {
                  this.spinner.hide();
              }
              if (response && response['data'].length) {
                  this.machines = response['data'];
              }
          });
      }
  }

  getIssueTypeLookup() {
    this.notificationService.getIssueTypeLookup().subscribe(response => {
      if(response != null){
      if (response['data'].length) {
        this.issueTypes = response['data'];
      }
    }
    });
  }

  getCompletedByLookup() {
    this.notificationService.getCompletedByLookup().subscribe(response => {
      if(response != null){
      if (response['data'].length) {
        this.completedBys = response['data'];
      }
    }
    });
    }

    applyLogFilter() {//[GD2-3706]
        this.assignLogFilterToTempFilter();
        this.getNotificationLogsList('apply');
    }
    applyOutstandingCompleteLogFilter() {//[GD2-3796]
        this.assignLogFilterToTempFilter();
        this.getNotifications('apply');
    }

    assignLogFilterToTempFilter() {//[GD2-3706]
    this.filterColumns.forEach(key => {
        this.tempfilter[key] = this.filter[key];
    });
}
  changefilter() {
    this.roleService.setPopupScroll('section-notificationfilter');
    if (!this.drawerfilter) {
      $(".sidebar-mini").addClass("filter-open");
   }
    else {
        //[GD2-3706]
        if (this.textLabel == 'Logs') {
            this.filterColumns.forEach(key => {
                let valueChanged = false;
                if (this.filter[key] != this.tempfilter[key]) { valueChanged = true; }
                this.filter[key] = this.tempfilter[key];
                if (key == 'marketId' && this.filter['marketId'].length >= 0 && valueChanged) {
                    this.getPartnerLookup(false);
                }
                if (key == 'partnerId' && this.filter['partnerId'].length >= 0 && valueChanged) {
                    this.getSiteLookup(false);
                }
                if (key == 'siteId' && this.filter['siteId'].length >= 0 && valueChanged) {
                    this.getMachineLookup(false);
                }
            });
        }
        else {//[GD2-3796]
            this.filter['issueType'] = this.tempfilter['issueType'];
        }
       $(".sidebar-mini").removeClass("filter-open");
       this.roleService.resetPopupScroll()
   }
    setTimeout(() => {
      this.drawerfilter = !this.drawerfilter;
    }, this.roleService.scrollSetTimeoutTime);
}
  clickFilter(val) {
      this.isShowFilter = val;

  }
  clearFilter() {
    this.pageNo = 1;
    let fromClear = true;
    if(this.textLabel != 'Logs'){
      this.isFilterClear = true;
      this.isApplyDisabled = false;
      this.isFilterApply = false;
      this.filter.issueType = '';
      this.tempfilter.issueType = '';//[GD2-3796]
      this.getNotifications(fromClear);
    }else{
    this.filter.marketId = '';
    this.filter.partnerId = '';
    this.filter.siteId = '';
    this.filter.machineId = '';
    this.filter.issueType = '';
    this.filter.completedBy = '';
    this.filter.notificationState = '';
    this.filter.notificationCompletedFromDate = moment().subtract(this.userService.defaultUIConfigurations.notificationFilterCompletedFromInDays, 'days');
      this.filter.notificationCompletedToDate = moment();
      this.minToDate = this.filter.notificationCompletedFromDate;
      this.maxToDate = this.maxDate;
      this.isApplyDisabled = false;
      this.isFilterClear = true;
      this.isFilterApply = false;
      this.params.pageNumber = 1;
      this.assignLogFilterToTempFilter(); //[GD2-3706]
      this.getMarketLookup();
      this.getPartnerLookup(true);
      //this.getSiteLookup(true);
      //this.getMachineLookup(true);
      this.getNotificationLogsList(fromClear);
    }
    $(".sidebar-mini").removeClass("filter-open");
    this.roleService.resetPopupScroll();
  }

  pageChange(e) {
    this.params.pageNumber = (e.pageIndex > 0 ? (e.pageIndex + 1) : 1);
    this.params.pageSize = (e.pageSize > 0 ? e.pageSize : this.pageSizeOptions[0]);
    this.textLabel == 'Logs'
    this.getNotificationLogsList();
  }

  getNotificationLogsList(fromClear = undefined) {
    this.spinner.show();
  var isvalid = true;
    if (this.filter.notificationCompletedFromDate && !this.filter.notificationCompletedToDate) {
      this.filter.notificationCompletedToDate = moment();
    }
    if (!this.filter.notificationCompletedFromDate && this.filter.notificationCompletedToDate) {
      this.toaster.open({
        text: "Notification Completed from date cannot be empty",
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });
      this.spinner.hide();
    }
    if (this.filter.notificationCompletedFromDate && this.filter.notificationCompletedToDate) {
      var notificationCompletedFromDate = moment(this.filter.notificationCompletedFromDate, 'DD-MM-YYYY');
      var notificationCompletedToDate = moment(this.filter.notificationCompletedToDate, 'DD-MM-YYYY');
      if(this.filter.notificationCompletedFromDate > this.filter.notificationCompletedToDate){
        isvalid = false;
        this.toaster.open({
          text: "To Date must be grater than from date",
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
        this.spinner.hide();
      }
      else if(notificationCompletedToDate.diff(notificationCompletedFromDate, 'days') > 90){
        isvalid = false;
      this.toaster.open({
        text: "You can filter system notifications for maximum 90 days.",
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });
      this.spinner.hide();
    }

    }
    if(isvalid){
    if (!this.isFilterClear) {
        this.isShowFilter = false;
        this.drawerfilter = false;
    }
    this.isFilterClear = false;
    if (fromClear == "apply") {
        $(".sidebar-mini").removeClass("filter-open");
        this.roleService.resetPopupScroll();
    }
    if (fromClear == undefined)
        this.drawerfilter = false;
    if (fromClear == "search" || fromClear == "apply")
        this.params.pageNumber = 1
    let obj = {};
    this.isFilterApply = false;
    if (this.filter.marketId != undefined && this.filter.marketId != '') { obj['marketId'] = this.filter.marketId; this.isFilterApply = true; };
    if (this.filter.partnerId != undefined && this.filter.partnerId != '') { obj['partnerId'] = this.filter.partnerId; this.isFilterApply = true; };
    if (this.filter.siteId != undefined && this.filter.siteId != '') { obj['siteId'] = this.filter.siteId; this.isFilterApply = true; };
    if (this.filter.machineId != undefined && this.filter.machineId != '') { obj['machineId'] = this.filter.machineId; this.isFilterApply = true; };
    if (this.filter.issueType != undefined && this.filter.issueType != '') { obj['NotificationTypeId'] = this.filter.issueType; this.isFilterApply = true; };
    if (this.filter.completedBy != undefined && this.filter.completedBy != '') { obj['CompletedById'] = this.filter.completedBy; this.isFilterApply = true; };
    if (this.filter.notificationState != undefined && this.filter.notificationState != '') { obj['NotificationState'] = this.filter.notificationState; this.isFilterApply = true; };
    if (this.filter.notificationCompletedFromDate != undefined && this.filter.notificationCompletedFromDate != '') { obj['NotificationCompletedFrom'] = this.filter.notificationCompletedFromDate; this.isFilterApply = true; };
    if (this.filter.notificationCompletedToDate != undefined && this.filter.notificationCompletedToDate != '') { obj['NotificationCompletedTo'] = this.filter.notificationCompletedToDate; this.isFilterApply = true; };
    obj['NotificationCompletedTo'] = moment(obj['NotificationCompletedTo']).format('yyyy-MM-DD')+'T'+moment(obj['NotificationCompletedTo']).format('HH:mm:ss')+'.000Z';
    obj['NotificationCompletedFrom'] = moment(obj['NotificationCompletedFrom']).format('yyyy-MM-DD')+'T'+moment(obj['NotificationCompletedFrom']).format('HH:mm:ss')+'.000Z';
    if (this.params.pageNumber != undefined) { obj['PageNo'] = this.params.pageNumber };
    if (this.params.pageSize != undefined) { obj['PageSize'] = this.params.pageSize };
    if (this.params.sortBy != undefined) { obj['OrderByColumn'] = this.params.sortBy };
    this.notificationService.getDataIssueLogs(obj).subscribe(response => {
        this.spinner.hide();
        this.isPageDataLoad=true;
        this.resordsTotal = response?.extension?.count;
        if (response && response['data']) {
            this.notificationLogsList = response['data']
        } else {
            this.notificationLogsList = [];
        }
    }, err => {
        let errors = err.errors == undefined ? err.error : err.errors;
        errors.forEach(element => {
            this.toaster.open({
                text: element.msg,
                type: 'danger',
                position: 'top-right',
                duration: 10000
            });
        });
    });
  }
  }

  fromDateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    this.minToDate = this.filter.notificationCompletedFromDate;
      this.maxToDate = moment(this.filter.notificationCompletedFromDate).add(this.userService.defaultUIConfigurations.notificationFilterLogsDateMaxRangeInDays, 'days');

      if(this.maxToDate > moment()){
        this.maxToDate = moment();
      }
      if(!(this.minToDate <= this.filter.notificationCompletedToDate && this.maxToDate >= this.filter.notificationCompletedToDate)){
        this.isApplyDisabled = true;
      } else  {
        this.isApplyDisabled = false;
      }
  }

  ToDateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    if(!(this.minToDate <= this.filter.notificationCompletedToDate && this.maxToDate >= this.filter.notificationCompletedToDate)){
      this.isApplyDisabled = true;
    } else  {
      this.isApplyDisabled = false;
    }
  }
}
