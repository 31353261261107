import { LabelType, Options } from '@angular-slider/ngx-slider';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import * as _moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from "ngx-toast-notifications";
import { Observable, Subscription } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { AlarmEventCauseLostTradingHoursEnum } from 'src/app/enums/alarmEventCauseLostTradingHours.enum';
import { AlarmEventTypesEnum } from 'src/app/enums/alarmEventTypes.enum';
import { AlarmListFilterEscalationLevelsEnum } from 'src/app/enums/alarmListFilterEscalationLevels.enum';
import { AlarmListFilterStatusEnum } from 'src/app/enums/alarmListFilterStatus.enum';
import { AlarmListFilterTypesEnum } from 'src/app/enums/alarmListFilterTypes.enum';
import { EventFilterTypesEnum } from 'src/app/enums/eventFilterTypes.enum';
import { MachineEventFilterTypesEnum } from 'src/app/enums/machineEventFilterTypes.enum';
import { MasterLookupEnum } from 'src/app/enums/masterlookup.enum';
import { AlarmTemplateService } from 'src/app/services/alarm-template/alarm-template.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { EntityService } from 'src/app/services/entity/entity.service';
import { EventsService } from 'src/app/services/events/events.service';
import { MachineService } from 'src/app/services/machine/machine.service';
import { PriceCardService } from 'src/app/services/price-card/price-card.service';
import { PriceCardTemplateService } from 'src/app/services/pricecard-template/pricecard-template.service';
import { RoleEnum, RolePermissionsEnum, RoleService } from 'src/app/services/role/role.service';
import { SalesService } from 'src/app/services/sales/sales.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from '../../../../environments/environment';
import { AlertConfirmDialogModel } from '../../common/alert-confirm-dialog/alert-confirm.dialog.model';
import { EntityConfigurationComponent } from '../entity-configuration/entity-configuration.component';
import { fieldValidationJson } from './entity-field-validation';
import { EntityConstant } from '../../../constant/entity.constants'
import { EntityLevel } from '../../../constant/audit-log.constant'
import { PropositionMasterEnum } from 'src/app/enums/propositionMaster.enum';
import { AzureService } from 'src/app/services/azure/azure.service';
import { DropdownDrawerComponent } from '../../dropdown-drawer/dropdown-drawer.component';
import { SuspendOrResumeActionTypes, SuspendOrResumeLabelTypes, SuspendOrResumeMachineTypes } from './suspendOrResumeMachineTypes';
import { LowAdminService } from 'src/app/services/low-admin/low-admin.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CommissioningStatusEnum } from 'src/app/enums/commissioningStatus.enum';
import { MachineModelComponent } from '../machine-model/machine-model.component';
import { PageTypeEnum } from 'src/app/enums/pageType.enum';
import { EntityLevelNumber } from '../enum/entity-level.enum';
import { CcDialogService } from '../../../services/ui/cc-dialog.service';

const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};
declare var $: any
function autocompleteStringValidator(validOptions: Array<string>): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (validOptions.indexOf(control.value) !== -1) {
            return null  /* valid option selected */
        }
        return { 'invalidAutocompleteString': { value: control.value } }
    }
}
@Component({
    selector: 'app-entity-add',
    templateUrl: './entity-add.component.html',
    styleUrls: ['./entity-add.component.css'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },

        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
})
export class EntityAddComponent implements OnInit {

    @ViewChild('dialogTemplate') dialogTemplate: TemplateRef<any>;
    dialogTemplateData: {[key: string]: string} = {};

    alertConfirmDataModel: AlertConfirmDialogModel = new AlertConfirmDialogModel();
    infoConfirmDataModel: AlertConfirmDialogModel = new AlertConfirmDialogModel();
    suspendConfirmDataModel: AlertConfirmDialogModel = new AlertConfirmDialogModel();
    propositionConfirmDataModel: AlertConfirmDialogModel = new AlertConfirmDialogModel(); //GD2-4423
    suspendMachineConfirmDataModel: AlertConfirmDialogModel = new AlertConfirmDialogModel();
    resumeMachineConfirmDataModel: AlertConfirmDialogModel = new AlertConfirmDialogModel();
    siteLowAdminConfirmDataModel: AlertConfirmDialogModel = new AlertConfirmDialogModel();

    @ViewChild(DropdownDrawerComponent, { static: false }) dropdownDrawer: DropdownDrawerComponent;

    myControl = new FormControl();
    options: string[] = ['One', 'Two', 'Three'];
    filteredOptions: Observable<string[]>;
    filteredOptionsObj: any = {};
    tabIndex: any = 0;
    hasTradeHoursSetup: Boolean = false;
    entityForm: FormGroup;
    initialFormValues: Record<string, any> = {};
    checkSubmitStatus = false;
    screenType = 'add';
    filedsJson: any = [];
    bodyJson: any = [];
    suspendReasonOptions: any = [];
    isFormLoad = false;
    navigationFromEntityMove: boolean = false;
    navigationFromMachineMove: boolean = false;
    entityId: any;
    levelNumber = 1;
    parentlevelNumber = 0;
    parentEntityId = '';
    paymentTerminalId = null;
    machineModelId = '';
    marketId = '';
    machineId = '';
    cookieName = environment.cookieName;
    autonomousMachinesFT: boolean;
    globalChangesFT: boolean;
    enableCreateConcessionFT: boolean;
    enableGridImprovementsFT: boolean;
    showEntityOrRecordName: string = 'Entity';
    selectedSuspendReason: string;
    propositionMasterEnum = PropositionMasterEnum;
    machineFields = new Map<string, string>([
        ["machineGeoPositionLat", "isGeoPositionLatEditable"],
        ["machineGeoPositionLong", "isGeoPositionLongEditable"],
        ["machinePaymentSystemType", "isPaymentSystemTypeEditable"],
        ["machinePaymentTerminalID", "isPaymentTerminalIDEditable"],
    ]);
    levelSetup: any = {
        "marketId": "",
        "hasLevel2": true,
        "hasLevel3": true,
        "hasLevel3_5": true,
        "hasLevel5": true,
        "hasLevel6": true,
        "isLevel2ReferenceMandatory": false,
        "isLevel3ReferenceMandatory": false,
        "isLevel3_5ReferenceMandatory": false,
        "isPartnerLevelReferenceMandatory": false,
        "isLevel5ReferenceMandatory": false,
        "isLevel6ReferenceMandatory": false,
        "isSiteLevelReferenceMandatory": false,
        "isMachineLevelReferenceMandatory": false
    };
    level3_5LableName = '';
    @ViewChild(MachineModelComponent, { static: false }) machineModelComponent: MachineModelComponent;
    @ViewChild(EntityConfigurationComponent, { static: false }) entityConfigurationComponent: EntityConfigurationComponent;
    @ViewChild("leaveAnywayDialogTemplate") leaveAnywayDialogTemplate: TemplateRef<any>;
    @ViewChild('infoAlertTemplate') infoAlertTemplate: TemplateRef<any>;
    hasLevelSetup = false;
    countryCodes = [];
    selectedFiled: any;
    selectedFiledIndex = 0;
    marketDetails: any;
    dialogTexts = {
        successTitle: '',
        cancelTitle: '',
        message: '',
        deviceName: '',
        status: '',
        title: '',
        type: '',
        isNext: false
    }
    parentEntityList = [];
    brandCode = '';
    gridId = '';
    lable = 'Record';
    suggestionObj = {};
    autoSuggestionShowObj = {};
    radioObj = {};
    start_time: any = new Date();
    machineSerialNo: any = '';
    uniqueMachineNumber: any = '';
    machineType: any = '';
    fromSuspendRoute = '';
    levelNumbersHasTab = ['1', '4', '7', '8', '2', '3', '3.5', '5', '6'];
    isLowAdminActive: boolean = false;
    isLowAdminCurrentState: boolean = false;

    //@Input() machineId: any = null;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    displayedColumns: string[] = [];//[GD2-2876]
    displayedColumnsCounterTop: string[];
    displayedColumnsCapsules: string[];
    displayedColumnsAutonomous: string[];
    displayedColumnsConcession: string[];
    displayedEventColumns: string[] = ['eventName', 'eventDescription', 'eventState', 'currentEventState', 'lostTradingHours', 'eventType', 'siteName', 'eventDateTime', 'gridReceivedDate'];
    displayedLogsColumns: string[] = ['movementDate'];
    displayedStatusLogsColumns: string[] = ['status'];
    displayedEntityLogsColumns: string[] = ['fromParentEntityName', 'toParentEntityName', 'movementDate'];
    dataSource: any = [];
    @ViewChild(MatSort) sort: MatSort;
    isShowFilter = false;
    drawerfilter: boolean = false;
    alarmDrawerfilter: boolean = false;
    eventdrawerfilter: boolean = false;
    isFilterClear: boolean = false;
    isFilterApply: boolean = false;
    salesDetailList: any = [];
    eventsDetailList: any = [];
    LogHistoryList: any = [];
    drinkList: any = [];
    priceCards: any = [];
    coffeeList: any = [];
    milkList: any = [];
    closeAlarmId = '';

    dropdownSettingsEventFilter: any = {
        "singleSelection": false,
        "defaultOpen": false,
        "idField": "eventValue",
        "textField": "eventText",
        "enableCheckAll": false,
        "itemsShowLimit": 2,
        "allowSearchFilter": true
    };

    selectedEventNames: [] = [];
    selectedEventDescriptions: [] = [];
    selectedEventTypes: [] = [];
    selectedEventStates: [] = [];
    selectedCurrentEventStates: [] = [];

    eventNameList: any = [];
    eventDescriptionList: any = [];
    lastTradingHoursList: any = [];
    eventSiteNameList: any = [];
    eventTypeList: any = [{ eventValue: 'Technical', eventText: 'Technical' }, { eventValue: 'Operator', eventText: 'Operator' }, { eventValue: 'Information', eventText: 'Information' }];
    dropdownSettingsEventTypeList = {
        "singleSelection": false,
        "defaultOpen": false,
        "idField": "eventValue",
        "textField": "eventText",
        "enableCheckAll": true,
        "itemsShowLimit": 2,
        "allowSearchFilter": false,
        "selectAllText": "All",
        "unSelectAllText": "UnSelect All"
    };

    eventStatusList: any = [{ eventState: 'All' }, { eventState: 'Open' }, { eventState: 'Closed' }];
    currentEventStatusList: any = [{ currentEventState: 'Open' }, { currentEventState: 'Information' }, { currentEventState: 'Closed' }];
    currentList: any = [{ currentEventState: 'Open' }, { currentEventState: 'Information' }, { currentEventState: 'Closed' }];

    dropdownSettingsCurrentEventStatesList = {
        "singleSelection": false,
        "defaultOpen": false,
        "idField": "currentEventState",
        "textField": "currentEventState",
        "enableCheckAll": true,
        "itemsShowLimit": 2,
        "allowSearchFilter": false,
        "selectAllText": "All",
        "unSelectAllText": "UnSelect All",
    };

    filter: any = {
        drinkId: '',
        vendStatus: '',
        priceCardNameNo: '',
        manufactureFromDate: '',
        manufactureToDate: '',
        gridReceivedFromDate: '',
        gridReceivedToDate: '',
        vendFromDate: '',
        vendToDate: '',
        search: ''
    }
    tempSalesfilter: any = {//[GD2-3706]
        drinkId: '',
        vendStatus: '',
        priceCardNameNo: '',
        manufactureFromDate: '',
        manufactureToDate: '',
        gridReceivedFromDate: '',
        gridReceivedToDate: '',
        vendFromDate: '',
        vendToDate: '',
        search: ''
    }
    filterSalesColumns = ['drinkId', 'vendStatus', 'priceCardNameNo', 'manufactureFromDate', 'manufactureToDate', 'gridReceivedFromDate', 'gridReceivedToDate', 'vendFromDate', 'vendToDate'];//[GD2-3706]
    eventfilter: any = {
        eventNames: [],
        eventDescriptions: [],
        eventTypes: [],
        eventState: '',
        eventSiteName: '',
        lostTradingHours: '',
        currentEventStates: [],
        occuredFromDate: '',
        occuredToDate: '',
        receivedFromDate: '',
        receivedToDate: '',
        search: ''
    }
    tempeventfilter: any = {//[GD2-3706]
        eventNames: [],
        eventDescriptions: [],
        eventTypes: [],
        eventState: '',
        eventSiteName: '',
        lostTradingHours: '',
        currentEventStates: [],
        occuredFromDate: '',
        occuredToDate: '',
        receivedFromDate: '',
        receivedToDate: '',
        search: '',
        siteName: ''
    }
    filterEventDataColumns = ['eventNames', 'eventDescriptions', 'eventTypes', 'eventState', 'eventSiteName', 'siteName', 'lostTradingHours', 'currentEventStates', 'occuredFromDate', 'occuredToDate', 'receivedFromDate', 'receivedToDate'];//[GD2-3706]
    salesMinDate: any = moment().subtract(this.userService.defaultUIConfigurations.machineSalesFilterDateMinInMonths, 'months');
    salesMinDateVendDateTime: any = moment().subtract(this.userService.defaultUIConfigurations.machineSalesFilterDateMinInMonths, 'months');
    salesMinDateManufacturerReceivedDateTime: any = moment().subtract(this.userService.defaultUIConfigurations.machineSalesFilterDateMinInMonths, 'months');
    eventMinDate: any = moment().subtract(this.userService.defaultUIConfigurations.eventDataFilterDateMinInMonths, 'months');
    eventMinDateOccuredDateTime: any = moment().subtract(this.userService.defaultUIConfigurations.eventDataFilterDateMinInMonths, 'months');
    maxDate: any = moment();
    maxDateOccuredDateTime: any = moment();
    maxDateVendDateTime: any = moment();
    maxDateManufacturerReceivedDateTime: any = moment();
    recordsTotal = 0;
    pageIndex = 0;
    pageSizeOptions: number[] = [10, 25, 50, 100];
    params = {
        pageNumber: 1,
        pageSize: this.pageSizeOptions[0],
        searchText: '',
        sortBy: ''
    };

    isPageDataLoad = false;
    isAlarmPageDataLoad = false;
    salesDetails: any = {};
    isOpenSalesDetails = false;
    isOpenStatusLogs = false;
    nextRoute = '';

    machineModelTypes = [];
    machineTypeEditable = true;
    entityStatusLogs = [];
    hasStatuslogsLoad = false;
    machineSrNo: string = '';
    geoPositionLat: string = '';
    geoPositionLong: string = '';
    machineModel: string = '';
    hasAuth = true;
    @ViewChild(MatTabGroup) tabs: MatTabGroup;
    @ViewChild(MachineModelComponent) MachineModelComponent?: MachineModelComponent;
    isFromBreadcrum = false;
    suspendMachineLabelNameState = '';
    suspendMachineLabelHideState = false;
    //--alarm tab --//
    escalationDurationFrom: number = parseInt(this.userService.defaultUIConfigurations.alarmFilterEscalationRangeMin);
    escalationDurationTo: number = parseInt(this.userService.defaultUIConfigurations.alarmFilterEscalationRangeMax);
    alarmOptions: Options = {
        floor: parseInt(this.userService.defaultUIConfigurations.alarmFilterEscalationRangeMin),
        ceil: parseInt(this.userService.defaultUIConfigurations.alarmFilterEscalationRangeMax),
        translate: (value: number, label: LabelType): string => {
            let totalMinutes = value * 60;
            let days = Math.floor(totalMinutes / (3600 * 24));
            let hours = Math.floor(totalMinutes % (3600 * 24) / 3600);
            let minutes = Math.floor(totalMinutes % 3600 / 60);
            if (value == parseInt(this.userService.defaultUIConfigurations.alarmFilterEscalationRangeMin) || value == parseInt(this.userService.defaultUIConfigurations.alarmFilterEscalationRangeMax)) {
                return days + 'd';
            } else {
                return days + 'd, ' + hours + 'h, ' + minutes + 'm';
            }
        }
    };
    alarmList: any = [];

    escalationLevels = [1, 2, 3, 4]
    alarmParams = {
        pageNumber: 1,
        pageSize: this.pageSizeOptions[0],
        searchText: '',
        sortBy: 'occurredDateTime desc'
    };
    alarmFilter: any = {
        search: '',
        occurrenceFromDate: '',
        occurrenceToDate: '',
        currentEscalationLevel: [],
        eventCategoryTypes: [],
        alarmStatus: [],
        alarmName: [],
        machineSerialNumber: [],
        causesLostTradingHours: [],
        machineModelName: [],
        siteName: [],
        serviceAgent: [],
        countryCode: [],
        customerName: [],
        machineType: []
    };

    tempAlarmFilter: any = {//[GD2-3706]
        search: '',
        occurrenceFromDate: '',
        occurrenceToDate: '',
        currentEscalationLevel: [],
        eventCategoryTypes: [],
        alarmStatus: [],
        alarmName: [],
        machineSerialNumber: [],
        causesLostTradingHours: [],
        machineModelName: [],
        siteName: [],
        serviceAgent: [],
        countryCode: [],
        customerName: [],
        machineType: [],
        escalationDurationFrom: parseInt(this.userService.defaultUIConfigurations.alarmFilterEscalationRangeMin),
        escalationDurationTo: parseInt(this.userService.defaultUIConfigurations.alarmFilterEscalationRangeMax)
    };
    filterAlarmColumns = ['occurrenceFromDate', 'occurrenceToDate', 'escalationDurationFrom', 'escalationDurationTo', 'currentEscalationLevel', 'eventCategoryTypes', 'alarmStatus', 'alarmName', 'machineSerialNumber', 'causesLostTradingHours', 'machineModelName', 'customerName', 'siteName', 'serviceAgent', 'countryCode', 'customerName', 'machineType'];//[GD2-3706]

    alarmEventCauseLostTradingHoursList: any = [];
    filterAlarmEventCauseLostTradingHoursList: any = [];
    alarmEventEscalationsList: any = [];
    escalationLevelNotificationMethodsList: any = [];
    alarmEventTypesList: any = [];
    escalationLevelList: any = [];
    alarmStatusList: any = [];
    alarmDisplayedColumns: string[] = ['alarmName', 'description', 'uniqueMachineNumber', 'machineType', 'machineModelName', 'currentEscalationLevel', 'occurredDateTime', 'eventDuration', 'causesLostTradingHours', 'eventCategoryType', 'siteName', 'serviceAgent', 'customerName', 'countryCode', 'alarmStatus', 'resolvedDateTime', 'closeEventOccurredDateTime', 'resolutionType', 'eventAlarmId'];

    lookupDropdownSettings = {
        singleSelection: false,
        defaultOpen: false,
        idField: "value",
        textField: "text",
        enableCheckAll: false,
        allowSearchFilter: true,
        itemsShowLimit: 1
    };
    singleSelectLookupDropdownSettings = {
        singleSelection: true,
        defaultOpen: false,
        idField: "value",
        textField: "text",
        enableCheckAll: false,
        allowSearchFilter: false,
        itemsShowLimit: 1
    };
    alarmEventCauseLostTradingHoursEnum = AlarmEventCauseLostTradingHoursEnum;
    isFilter = false;

    filterlookups: any = {};
    exportFilter = {};
    blob: any;
    alarmMinDate: any = moment().subtract(this.userService.defaultUIConfigurations.alarmFilterOccurrenceDateMinInDays, 'days');
    alarmMaxDate: any = moment();

    isValidateEntityDetailsTab = false;
    hasCloseAlarmAccess = false;
    defaultAlarmFilter: any = {};
    ismachineMovedToCrossMarket = false;
    parentBrandCode: string = '';
    isOpenMachineTypesPopUp = false;
    machineModelLookupColumns: string[] = ['machineCode', 'machineType'];
    machineModels: any[] = [];
    machineLegacyId: string = 'NA';
    entityHierarchy: any = [];
    moveToNextEntityDetails: any = {};
    salesInfo: any = {
        salesReceivedDate: '',
        machineSerialNo: '',
        uniqueMachineNumber: '',
        machineType: ''
    }
    eventInfo: any = {
        eventRefreshDate: '',
        uniqueMachineNumber: '',
        machineType: ''
    }
    leaveAnyway = null
    canLeaveMoveScreenType: boolean = false;
    propositionTypeList: any = [];
    propositionTypeId = '';
    selectedPropositionType: string = '';
    selectedPropositionTypeName: string = '';
    isLowAdminMarketSetup: boolean = false;
    reloadProposition: boolean = false;
    mobileVerificationStatusFields: any = {};
    //GD2-3677
    siteEscalationContactNumberFields: any = ['sitePrimaryAlarmFirstContactNumber', 'siteSecondaryAlarmFirstContactNumber', 'sitePrimaryAlarmSecondContactNumber', 'siteSecondaryAlarmSecondContactNumber'];
    machineEscalationContactNumberFields: any = ['machinePrimaryAlarmFirstContactMobile#', 'machineSecondaryAlarmFirstContactMobile#', 'machinePrimaryAlarmsecondContactMobile#', 'machineSecondaryAlarmsecondContactMobile#'];
    siteSelectedPropositionTypeId: any = '';
    //--alarm tab --//

    readonly WAREHOUSE_BRANDTYPE: string = 'CE - NON TRADING';
    isWareHouseMultiProposition: boolean = false;
    isGridOneIngested: boolean = false;
    isConcessionMigrated: boolean = false;

    constructor(
        private priceCardService: PriceCardService,
        private priceCardTemplateService: PriceCardTemplateService,
        private SalesService: SalesService,
        private spinner: NgxSpinnerService,
        public entityService: EntityService,
        private router: Router,
        private cookieService: CookieService,
        private activatedRoute: ActivatedRoute,
        private toaster: Toaster,
        public authService: AuthService,
        private machineService: MachineService,
        public roleService: RoleService,
        public salesService: SalesService,
        public eventsService: EventsService,
        private alarmTemplateService: AlarmTemplateService,
        public entityConstant: EntityConstant,
        private userService: UserService,
        private azureService: AzureService,
        private lowAdminService: LowAdminService,
        private dialogService: CcDialogService,
    ) {
        router.events
            .subscribe((event: NavigationStart) => {
                if (event.navigationTrigger === 'popstate') {
                    window.location.reload();
                }
            });
        this.entityService.timeZoneValue = '';
        // activatedRoute.params.subscribe(params => { //in comment to resolved multiple APi calls
        let params: any = this.activatedRoute.snapshot.params;
        if (params.entityId) {
            this.entityId = params.entityId;
            this.machineId = params.entityId;
        }
        if (params.machineId) {
            this.machineId = params.machineId;
            this.entityId = params.machineId;
        }
        // });
        //Reset add screen payload as per story : GD2-2204
        this.entityService.isValidatedTradehoursTab = false;
        this.entityService.resetAddEntityOnHoldData();


        this.createLookupFromEnum();
        this.level3_5LableName = this.userService.defaultUIConfigurations.entityLevel3_5LableName;
    }

    async ngOnInit(sType = false): Promise<void> {
        await this.SetFeatureFlagsConfigurations();
        this.salesMinDate = moment(this.salesMinDate).startOf('day');
        this.eventMinDate = moment(this.eventMinDate).startOf('day');
        this.activatedRoute.queryParams.subscribe(queryParams => {
            // this.activatedRoute.params.subscribe(params => { //in comment to resolved multiple APi calls
            let params: any = this.activatedRoute.snapshot.params;
            if (params.entityId) {
                this.entityId = params.entityId;
                this.machineId = params.entityId;
            }
            if (params.machineId) {
                this.machineId = params.machineId;
                this.entityId = params.machineId;
            }
            // });
            if (queryParams.nextLevelNumber)
                this.levelNumber = queryParams.nextLevelNumber;

            if (queryParams.marketId)
                this.marketId = queryParams.marketId

            if (queryParams.parentEntityId)
                this.parentEntityId = queryParams.parentEntityId;
            if (queryParams.propositionTypeId)//GD2-3874
                this.propositionTypeId = queryParams.propositionTypeId;

            // when current url not hit from breadcrum/path.
            if (!this.isFromBreadcrum) {
                if (queryParams.screenType && !sType)
                    this.screenType = queryParams.screenType;
                else if (sType)
                    this.screenType = 'edit';
            }

            if (this.roleService.objRole.isUserAuditorGRID || this.roleService.objRole.isUserAuditorLevel1 || this.roleService.objRole.isUserAuditorLevel2 || this.roleService.objRole.isUserAuditorLevel3) {
                this.router.navigate(['/unauthorized']);
            }

            if (this.screenType != 'read' && (this.roleService.objRole.isUserAuditorGRIDR || this.roleService.objRole.isUserAuditorLevel1R || this.roleService.objRole.isUserAuditorLevel2R || this.roleService.objRole.isUserAuditorLevel3R)) {
                this.router.navigate(['/unauthorized']);
            }

            if (queryParams.tabIndex) {
                this.tabIndex = queryParams.tabIndex;
                if (localStorage.getItem('fromMachineDetailTab') && this.tabIndex == 6 && this.levelNumber == 8) {
                    this.isPageDataLoad = false;
                    let that = this;
                    $.each(EventFilterTypesEnum, function (key, value) {
                        if (Number.isInteger(value)) {
                            that.filterlookups[key] = [];
                        }
                    });
                    that.isFilterApply = false;
                    this.alarmFilter.search = '';
                    this.getFilterLookup();
                    this.getAlarmList();
                    this.isAlarmPageDataLoad = false;
                }
            }

            if (queryParams.parentlevelNumber)
                this.parentlevelNumber = queryParams.parentlevelNumber

            if (queryParams.isEntityMove != undefined && (queryParams.isEntityMove == "true" || queryParams.isEntityMove == true)) {
                this.navigationFromEntityMove = true;
                this.screenType = 'move';
            } else {
                this.navigationFromEntityMove = false;
            }


            if (queryParams.isMachineMove != undefined && (queryParams.isMachineMove == "true" || queryParams.isMachineMove == true)) {
                this.navigationFromMachineMove = true;
                this.screenType = 'move';
            } else {
                this.navigationFromMachineMove = false;
            }
            if (queryParams.machineSrNo)
                this.machineSrNo = queryParams.machineSrNo;

            if (queryParams.geoPositionLat)
                this.geoPositionLat = queryParams.geoPositionLat;

            if (queryParams.geoPositionLong)
                this.geoPositionLong = queryParams.geoPositionLong;

            if (queryParams.machineModelId)
                this.machineModel = queryParams.machineModelId;

            if (queryParams.ismachineMovedToCrossMarket != undefined && (queryParams.ismachineMovedToCrossMarket == "true" || queryParams.ismachineMovedToCrossMarket == true))
                this.ismachineMovedToCrossMarket = true;

            if (this.levelNumber == 1) {
                this.lable = 'Market';
            } else if (this.levelNumber == 4) {
                this.lable = 'Customer';
            } else if (this.levelNumber == 7) {
                this.lable = 'Site';
                if (this.screenType == 'edit' || this.screenType == 'read') {
                    this.getSiteTradeHours();
                }
            } else if (this.levelNumber == 8) {
                this.lable = 'Machine';
                if (this.roleService.objRole.isSystemAdmin || this.roleService.objRole.isGlobalMarketAdmin || this.roleService.objRole.isMarketAdmin || this.roleService.objRole.isLevel2Admin || this.roleService.objRole.isLevel3Admin || (this.roleService.objRole.isSiteSupportL1 || this.roleService.objRole.isSiteSupportL2 || this.roleService.objRole.isSiteSupportL3)) {
                    this.hasCloseAlarmAccess = true;
                }

                //GD2-2844
                if (this.tabIndex == 4) {
                    this.salesDetailList = [];
                    this.LogHistoryList = [];
                    this.drinkList = [];
                    this.priceCards = [];
                    this.filter = {
                        drinkId: '',
                        vendStatus: '',
                        priceCardNameNo: '',
                        manufactureFromDate: '',
                        manufactureToDate: '',
                        gridReceivedFromDate: '',
                        gridReceivedToDate: '',
                        vendFromDate: '',
                        vendToDate: '',
                        search: ''
                    }
                    this.tempSalesfilter = {//[GD2-3706]
                        drinkId: '',
                        vendStatus: '',
                        priceCardNameNo: '',
                        manufactureFromDate: '',
                        manufactureToDate: '',
                        gridReceivedFromDate: '',
                        gridReceivedToDate: '',
                        vendFromDate: '',
                        vendToDate: '',
                        search: ''
                    }

                    this.recordsTotal = 0;
                    this.pageIndex = 0;
                    this.params = {
                        pageNumber: 1,
                        pageSize: this.pageSizeOptions[0],
                        searchText: '',
                        sortBy: ''
                    };

                    this.getLastSalesDate(true);
                    this.getDrinkList();
                    this.getPriceCardList();
                    this.getMilkList();
                    this.getCoffeeList();
                } else if (this.tabIndex == 5) {
                    this.params.sortBy = 'eventDateTime desc'
                    this.eventsDetailList = [];
                    this.eventNameList = [];
                    this.eventDescriptionList = [];
                    this.lastTradingHoursList = [];
                    this.eventSiteNameList = [];
                    this.selectedEventNames = [];
                    this.selectedEventDescriptions = [];
                    this.selectedEventTypes = [];
                    this.recordsTotal = 0;
                    this.pageIndex = 0;
                    this.params = {
                        pageNumber: 1,
                        pageSize: this.pageSizeOptions[0],
                        searchText: '',
                        sortBy: ''
                    };

                    this.eventfilter = {
                        search: '',
                        eventNames: [],
                        eventDescriptions: [],
                        eventTypes: [],
                        eventState: 'All',
                        currentEventStates: '',
                        lostTradingHours: '',
                        occuredFromDate: '',
                        occuredToDate: '',
                        receivedFromDate: '',
                        receivedToDate: ''
                    }
                    this.tempeventfilter = {//[GD2-3706]
                        search: '',
                        eventNames: [],
                        eventDescriptions: [],
                        eventTypes: [],
                        eventState: 'All',
                        currentEventStates: '',
                        lostTradingHours: '',
                        occuredFromDate: '',
                        occuredToDate: '',
                        receivedFromDate: '',
                        receivedToDate: ''
                    }
                    this.selectedCurrentEventStates = this.currentEventStatusList;
                    this.getLastRefreshEventsDate(true);
                    this.getEventDataFilterLookup();
                    //this.getLastTradingHoursList();
                } else if (this.tabIndex == 7) {
                    this.params.sortBy = 'movementDate desc'
                    this.recordsTotal = 0;
                    this.pageIndex = 0;
                    this.getSiteLogHistory('EntityChangeLog');
                    this.params = {
                        pageNumber: 1,
                        pageSize: this.pageSizeOptions[0],
                        searchText: '',
                        sortBy: ''
                    };
                } else if (this.tabIndex == 6) {
                    let that = this;
                    $.each(EventFilterTypesEnum, function (key, value) {
                        if (Number.isInteger(value)) {
                            that.filterlookups[key] = [];
                        }
                    });
                    that.isFilterApply = false;
                    this.alarmFilter.search = '';
                    // this.alarmClearFilter();
                    this.getAlarmList("search");
                    this.getFilterLookup();
                    this.isAlarmPageDataLoad = false;
                }
            }


        });
        if (!this.roleService.objRole.isSystemAdmin) {
            if (this.levelNumber != undefined && this.levelNumber != null && this.levelNumber != 0) {
                if (this.roleService.levelNumber != 1) {
                    if (this.levelNumber < this.roleService.levelNumber) {
                        this.router.navigate(['/unauthorized']);
                        this.hasAuth = false;
                    }
                } else {
                    if (this.levelNumber == 1 && this.roleService.levelNumber == 1 && this.screenType == 'add' && !this.roleService.objRole.isSystemAdmin && !this.roleService.objRole.isGlobalMarketAdmin) {
                        this.router.navigate(['/unauthorized']);
                        this.hasAuth = false;
                    }
                }
            }
            if ((this.screenType != 'read' && !this.roleService.objRole.isMarketAdmin && !this.roleService.objRole.isSystemAdmin && !this.roleService.objRole.isGlobalMarketAdmin && !this.roleService.objRole.isLevel2Admin && !this.roleService.objRole.isLevel3Admin)) {
                if (!((this.levelNumber == 7 || this.levelNumber == 8) && (this.roleService.objRole.isSiteSupportL1 || this.roleService.objRole.isSiteSupportL2 || this.roleService.objRole.isSiteSupportL3))) {
                    this.router.navigate(['/unauthorized']);
                    this.hasAuth = false;
                } else if (this.screenType != 'edit') {
                    this.router.navigate(['/unauthorized']);
                    this.hasAuth = false;
                }
            } else if (this.screenType != 'read' && ((this.roleService.objRole.isMarketAdmin && this.levelNumber == 1) || (this.roleService.objRole.isLevel2Admin && this.levelNumber == 2) || (this.roleService.objRole.isLevel3Admin && this.levelNumber == 3))) {
                this.router.navigate(['/unauthorized']);
                this.hasAuth = false;
            } else if (this.screenType != 'add' && this.levelNumber == 1 && this.roleService.currentRole == RoleEnum.MarketAdmin && this.entityId != this.roleService.marketId) {
                this.router.navigate(['/unauthorized']);
                this.hasAuth = false;
            }
        }
        if (this.hasAuth) {
            if (this.entityId) {
                this.entityId = this.entityId;
                if (this.levelNumber == 1) {
                    this.getMarketSetup(this.entityId);

                }

                if (this.levelNumber.toString() == "8" && (this.screenType == "read" || this.screenType == "edit" || this.screenType == "move")) {
                    if (!this.navigationFromMachineMove)
                        this.getMachineFormsFileds(this.entityId);
                    else {

                        this.getMachineFormsFileds(this.entityId, this.parentEntityId);
                    }
                }
                else {
                    if (!this.navigationFromEntityMove)
                        this.getFormsFileds(this.entityId);
                    else {
                        this.getFormsFileds(this.entityId, this.parentEntityId);
                    }
                }
            } else {
                if (this.levelNumber == 1) {
                    this.parentEntityId = this.cookieService.get(this.cookieName + 'root_entity');
                }

                if (this.levelNumber == 8) {
                    this.marketDetails = {
                        'isWarehouse': 'false',
                        'machineModelType': 'Legacy', //set default Legacy #GD2-694
                        'isCodeGenerated': false//set default false #GD2-694
                    }
                }

                this.loadSiteInfoForMachineAsPromise(this.parentEntityId)
                    .then(() => this.getFormsFileds());
            }
        }
    }

    private setSiteLowAdminStatus() {
      if (this.entityId && this.levelNumber == 7 && this.selectedPropositionTypeName == this.propositionMasterEnum.Capsules) {
        this.lowAdminService
          .getSiteLowAdminSetup(this.entityId)
          .subscribe((response) => {
            this.isLowAdminActive  = response.isActive;
            this.isLowAdminCurrentState = response.isActive;
          }, (err: HttpErrorResponse) => {
            if (err.status !== 404) {
              this.toaster.open({
                text: 'Something wrong happened while trying to retrieve Low Admin information.',
                type: 'danger',
                position: 'top-right',
                duration: 10000,
              });
            }
            this.isLowAdminActive = false;
          });
      }
    }

    ngAfterViewInit(): void {
        if (this.levelNumbersHasTab.includes(this.levelNumber.toString())) {

            // Just to be sure
            if (!this.tabs) {
                //throw Error('ViewChild(MatTabGroup) tabs; is not defined.');
            } else {

                const handleTabClick = this.tabs._handleClick;

                this.tabs._handleClick = (tab, header, index) => {
                    if (index != this.tabIndex) {
                        this.entityService.nextTabIndex = index;
                        if (!tab.disabled) {

                            let existingData = this.entityService.entityExistingData['level-' + this.levelNumber]['tab-' + this.tabIndex];
                            let latestData = this.entityService.entityLatestData['level-' + this.levelNumber]['tab-' + this.tabIndex];

                            if (this.tabIndex == 1 && this.levelNumber == 1) { //Market level setup tab
                                latestData = this.levelSetup;
                            } else if (this.tabIndex == 1 && this.levelNumber == 7) { //Trade hours setup tab
                                latestData = this.entityService.tradehours
                            }

                            if (existingData && latestData && Object.keys(existingData).length != 0 && Object.keys(latestData).length != 0) {
                                existingData = Object.keys(existingData).reduce((a, c) => (a[c] = existingData[c], a), {});
                                latestData = Object.keys(latestData).reduce((a, c) => (a[c] = latestData[c], a), {});
                            }

                            if (this.tabIndex == 1 && this.levelNumber == 7) { //Trade hours setup tab
                                existingData = JSON.stringify(existingData)
                                latestData = JSON.stringify(latestData)
                                existingData = existingData.replace(/,"isDefault":true/g, '');
                                latestData = latestData.replace(/,"isDefault":true/g, '');
                                if (existingData != latestData) {
                                    this.openDialog('tabChanged');
                                } else {
                                    this.tabIndex = index;
                                }
                            } else if ((this.tabIndex == 2 && (this.levelNumber == 1 || this.levelNumber == 7 || this.levelNumber == 8)) ||
                                this.tabIndex == 1 && this.levelNumber == 4) { //configuration
                                if (JSON.stringify(existingData[0]['cupsize']) != JSON.stringify(latestData[0]['cupsize']) ||
                                    JSON.stringify(existingData[0]['manageIngrdient']) != JSON.stringify(latestData[0]['manageIngrdient']) ||
                                    JSON.stringify(existingData[0]['ingredientSetup']) != JSON.stringify(latestData[0]['ingredientSetup']) ||
                                    JSON.stringify(existingData[1]['cupsize']) != JSON.stringify(latestData[1]['cupsize']) ||
                                    JSON.stringify(existingData[1]['manageIngrdient']) != JSON.stringify(latestData[1]['manageIngrdient']) ||
                                    JSON.stringify(existingData[1]['ingredientSetup']) != JSON.stringify(latestData[1]['ingredientSetup']) ||
                                    JSON.stringify(existingData[2]['cupsize']) != JSON.stringify(latestData[2]['cupsize']) ||
                                    JSON.stringify(existingData[2]['manageIngrdient']) != JSON.stringify(latestData[2]['manageIngrdient']) ||
                                    JSON.stringify(existingData[2]['ingredientSetup']) != JSON.stringify(latestData[2]['ingredientSetup']) ||
                                    JSON.stringify(existingData[3]['cupsize']) != JSON.stringify(latestData[3]['cupsize']) ||
                                    JSON.stringify(existingData[3]['manageIngrdient']) != JSON.stringify(latestData[3]['manageIngrdient']) ||
                                    JSON.stringify(existingData[3]['ingredientSetup']) != JSON.stringify(latestData[3]['ingredientSetup'])
                                ) {
                                    this.openDialog('tabChanged');
                                } else {
                                    this.tabIndex = index;
                                }
                            } else if (!this.isEqualObj(existingData, latestData)) {
                                this.openDialog('tabChanged');
                            } else {
                                this.tabIndex = index;
                            }
                        }
                    }
                };
            }


        }
    }
    getSiteTradeHours() {
        this.entityService.getSiteTradeHours(this.entityId).subscribe(response => {
            if (response) {
                this.getTradeHoursSetup(true);
            }
        });
    }
    changefilter() {
        this.roleService.setPopupScroll('section-tradehours');
        if (!this.entityService.cloneDrawerfilter) {
            $(".sidebar-mini").addClass("filter-open");
        }
        else {
            $(".sidebar-mini").removeClass("filter-open");
            this.roleService.resetPopupScroll()
        }

        setTimeout(() => {
            this.entityService.cloneDrawerfilter = !this.entityService.cloneDrawerfilter;
        }, this.roleService.scrollSetTimeoutTime);
    }
    clickTradeClone(type, i = 0, slot_i = 0) {
        if (type == 'clone_submit') {
            let index = 1;
            let dayOff = this.entityService.tradehours[this.entityService.selected_slot_index]['isDayOff'];
            this.entityService.selectedCloneDays.forEach(element => {
                let i = 1;
                let copySlot = [];
                this.entityService.tradehours[this.entityService.selected_slot_index]['slots'].forEach(slot => {
                    copySlot.push({ start_time: slot.start_time, end_time: slot.end_time })
                    if (i === this.entityService.tradehours[this.entityService.selected_slot_index]['slots'].length) {

                        this.entityService.tradehours[element]['slots'] = copySlot;
                        this.entityService.tradehours[element]['isDayOff'] = dayOff;
                        if (index === this.entityService.selectedCloneDays.length) {
                            this.entityService.selected_slot_index = -1;
                            this.changefilter();
                            this.entityService.selectedCloneDays = [];
                        }
                    }
                    i++;
                });
                index++
            });


        } else if (type == 'clone_cancel') {
            this.entityService.selected_slot_index = -1;
            this.entityService.selectedCloneDays = [];
            this.changefilter();
        } else if (type == 'clone_day') {
            if (i != this.entityService.selected_slot_index) {
                if (this.entityService.selectedCloneDays.includes(i)) {
                    const index = this.entityService.selectedCloneDays.indexOf(i);
                    if (index > -1) {
                        this.entityService.selectedCloneDays.splice(index, 1);
                    }
                } else {
                    this.entityService.selectedCloneDays.push(i);
                }
            }
        }
    }
    private _filter(value: string, i, e): string[] {
        if (i == 'parentEntityId') { //for parent entity autocomplete
            //if (value.length > 2 || this.levelNumber != 8) {
            const filterValue = value.toLowerCase();
            return e.filter(option => option.text.toLowerCase().includes(filterValue));
            //} else {
            //  return [];
            //}
        } else {

            if (!this.filedsJson[i].isSuggestive) {
                const filterValue = value.toLowerCase();
                return e.filter(option => option.text.toLowerCase().includes(filterValue));
            } else {
                if (value.length > 2) {
                    this.entityService.getSuggestion(this.filedsJson[i]['fieldAPIUrl'], {
                        'search': value,
                        'fieldUniqueName': this.filedsJson[i]['fieldUniqueName']
                    }).subscribe(response => {
                        if (!response) {
                            return [];
                        } else {
                            this.filteredOptionsObj['fieldUniqueName'] = response['data'];
                            this.filedsJson[i]['fieldOptions'] = response['data']
                            //e = response['data'];
                            const filterValue = value.toLowerCase();
                            return response['data'].filter(option => option.text.toLowerCase().includes(filterValue));
                        }
                    }, err => {
                        return [];
                    });

                } else {
                    return [];
                }
            }
        }
    }
    sortByKey(array, key) {
        return array.sort(function (a, b) {
            var x = a[key]; var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }
    getFormsFileds(entityId = '', parentEntityId = '') {
        this.spinner.show();
        if (entityId) {
            let moduleAction: any = (this.screenType == 'edit') ? RolePermissionsEnum.ReadWrite : RolePermissionsEnum.Read;
            moduleAction = moduleAction.toString();
            this.entityService.getEntityDetailsWithForm(entityId, parentEntityId, moduleAction).subscribe(response => {
                if (!response) {
                    this.toaster.open({
                        text: 'Something went wrong. Please try again.',
                        type: 'danger',
                        position: 'top-right',
                        duration: 10000
                    });
                    this.router.navigate(['/entity']);
                    this.spinner.hide();
                } else {
                    const isNewEntity = this.marketDetails?.entityId !== this.entityId;
                    this.isWareHouseMultiProposition = response.data.isWareHouse;
                    this.isGridOneIngested = response.data?.isGridOneIngested;
                    this.isConcessionMigrated = response.data?.isConcessionMigrated;
                    this.marketDetails = response["data"];

                    if (this.globalChangesFT) {
                        this.marketDetails["storeFrontName"] = this.marketDetails["formResponse"]
                            .filter((x) => x.fieldUniqueName === "siteStoreFrontName")
                            .map((x) => x.value);
                    }
                    this.spinner.hide();
                    this.getCurrentEntityHierarchy();
                    this.filedsJson = response['data']['formResponse'].filter(x => x.fieldType != null);
                    this.selectedPropositionType = response['data'].propositionType;
                    this.suspendMachineLabelNameState = this.getSuspendMachineLabelNameState();
                    this.suspendMachineLabelHideState = this.getSuspendMachineLabelHideState();
                    this.mobileVerificationStatusFields = this.filedsJson.filter(x => x.fieldType == 'Image');
                    if ((this.levelNumber == 4 || this.levelNumber == 5 || this.levelNumber == 6) && (this.screenType == 'edit' || this.screenType == 'move')) {
                        var brandCodeFieldName = this.levelNumber == 4 ? "partnerBrandCode" : (this.levelNumber == 5 ? "level5BrandCode" : "level6BrandCode");
                        var brandCodeValue = this.filedsJson.filter(x => x.fieldUniqueName == brandCodeFieldName);
                        this.parentBrandCode = brandCodeValue?.length > 0 ? (brandCodeValue[0]?.value) : '';
                    }
                    if (isNewEntity && this.levelNumber == 7 && this.screenType != 'add') { //GD2-4423
                        this.siteSelectedPropositionTypeId = this.filedsJson.filter(x => x.fieldUniqueName == 'sitePropositionType').length > 0 ? (this.filedsJson.filter(x => x.fieldUniqueName == 'sitePropositionType')[0]?.value) : '';
                        this.checkSiteLatLong(response['data']['formResponse']);
                    } else if (this.levelNumber == 8 && this.screenType != 'add') { //GD2-4427
                        this.siteSelectedPropositionTypeId = this.marketDetails["propositionTypeId"];
                    }
                    this.onSitePropositionTypeChange(this.siteSelectedPropositionTypeId);
                }
                if (this.filedsJson.length) {
                    let i = 0;
                    let formFieldIndex = 1;
                    if (this.levelNumber != 1) {
                        formFieldIndex++;
                    }
                    this.filedsJson.forEach(element => {
                        this.filedsJson[i]['formFieldIndex'] = formFieldIndex;
                        let type = (element.fieldType != 'SearchableDropdown') ? element.fieldType : 'S-Dropdown';
                        this.filedsJson[i]['id'] = type + '-' + element.fieldUniqueName;
                        if (i === (this.filedsJson.length - 1)) {
                            this.setFormGroup();
                        }
                        i++;
                        formFieldIndex++;
                    });

                } else {
                    this.setFormGroup();
                }
            }, err => {
                let errors = err.error;
                errors.forEach(element => {
                    this.toaster.open({
                        text: element.msg,
                        type: 'danger',
                        position: 'top-right',
                        duration: 10000
                    });
                });
                this.router.navigate(['/entity']);

            });
        } else {
            this.entityService.getFormsFileds(this.levelNumber, this.parentEntityId, this.marketId).subscribe(response => {
                if (!response) {
                    this.toaster.open({
                        text: 'Something went wrong. Please try again.',
                        type: 'danger',
                        position: 'top-right',
                        duration: 10000
                    });
                    this.router.navigate(['/entity']);
                    this.spinner.hide();
                } else {
                    this.spinner.hide();
                    this.getCurrentEntityHierarchy();
                    this.filedsJson = response['data'];
                    this.mobileVerificationStatusFields = this.filedsJson.filter(x => x.fieldType == 'Image');
                    if ((this.levelNumber == 2 || this.levelNumber == 3) && (this.screenType == 'add') && (this.filedsJson.filter(x => x.fieldUniqueName == "level2FranchiseCountryID" || x.fieldUniqueName == "level3FranchiseCountryID").length)) {
                        var countryCodeFieldName = this.levelNumber == 2 ? "level2FranchiseCountryID" : (this.levelNumber == 3 ? "level3FranchiseCountryID" : "");
                        this.gridId = this.filedsJson.filter(x => x.fieldUniqueName == countryCodeFieldName)[0].value;
                    }
                    if (this.filedsJson.length) {
                        let i = 0;
                        let formFieldIndex = 1;
                        if (this.levelNumber != 1) {
                            formFieldIndex++;
                        }
                        this.filedsJson.forEach(element => {
                            this.filedsJson[i]['formFieldIndex'] = formFieldIndex;
                            let type = (element.fieldType != 'SearchableDropdown') ? element.fieldType : 'S-Dropdown';
                            this.filedsJson[i]['id'] = type + '-' + element.fieldUniqueName;
                            if (i === (this.filedsJson.length - 1)) {
                                this.setFormGroup();
                            }
                            i++;
                            formFieldIndex++;
                        });
                        if (this.marketDetails != undefined)
                            this.marketDetails['propositionTypeId'] = this.propositionTypeId;//GD2-3874
                    } else {
                        this.setFormGroup();
                    }
                }
            }, err => {
                this.spinner.hide();
                if (err) {
                    let errors = err.error;
                    errors.forEach(element => {
                        this.toaster.open({
                            text: element.msg,
                            type: 'danger',
                            position: 'top-right',
                            duration: 10000
                        });
                    });
                    this.router.navigate(['/entity']);
                }
            });
        }
    }
    getMachineFormsFileds(machineId = '', parentEntityId = '') {
        this.parentEntityId = parentEntityId;
        this.spinner.show();
        let moduleAction: any = (this.screenType == 'edit') ? RolePermissionsEnum.ReadWrite : RolePermissionsEnum.Read;
        moduleAction = moduleAction.toString();
        this.machineService.getMachineDetailsWithForm(machineId, parentEntityId, moduleAction).subscribe(response => {
            if (!response) {
                this.toaster.open({
                    text: 'Something went wrong. Please try again.',
                    type: 'danger',
                    position: 'top-right',
                    duration: 10000
                });
                this.router.navigate(['/machine']);
                this.spinner.hide();
            } else {

                const loadMachineData = () => {
                    this.spinner.hide();
                    this.getCurrentEntityHierarchy();
                    this.isGridOneIngested = response.data?.isGridOneIngested;
                    this.isConcessionMigrated = response.data?.isConcessionMigrated || false;
                    this.filedsJson = response['data']['formResponse'].filter(x => x.fieldType != null);
                    this.selectedPropositionType = response['data'].propositionType;
                    this.suspendMachineLabelNameState = this.getSuspendMachineLabelNameState();
                    this.suspendMachineLabelHideState = this.getSuspendMachineLabelHideState();
                    this.mobileVerificationStatusFields = this.filedsJson.filter(x => x.fieldType == 'Image');
                    let commissioningStatusField = this.filedsJson.filter(x => { return x.fieldUniqueName
                        == this.entityConstant.fieldUniqueNames.machineCommissioningStatus })[0];
                    if(commissioningStatusField) {
                        this.CommissioningStatusFieldToLabel(commissioningStatusField);
                    }
                    this.paymentTerminalId = this.filedsJson.filter(x => { return x.fieldUniqueName == "machinePaymentTerminalID" })[0].value;
                    if (this.filedsJson.length) {
                        let i = 0;
                        let formFieldIndex = 1;
                        if (this.levelNumber != 1) {
                            formFieldIndex++;
                        }
                        this.filedsJson.forEach(element => {
                            this.filedsJson[i]['formFieldIndex'] = formFieldIndex;
                            let type = (element.fieldType != 'SearchableDropdown') ? element.fieldType : 'S-Dropdown';
                            this.filedsJson[i]['id'] = type + '-' + element.fieldUniqueName;
                            if (i === (this.filedsJson.length - 1)) {
                                this.setFormGroup();
                            }
                            i++;
                            formFieldIndex++;
                        });

                    } else {
                        this.setFormGroup();
                    }
                };

                this.marketDetails = response['data'];
                if (this.navigationFromMachineMove) {
                    this.loadSiteInfoForMachineAsPromise(this.parentEntityId)
                        .then(() => loadMachineData());
                }
                else {
                    this.parentEntityId = this.marketDetails.parentEntityId
                    this.isWareHouseMultiProposition = response['data'].isWarehouse;
                    loadMachineData();
                }

            }
        }, err => {
            let errors = err.error;
            errors.forEach(element => {
                this.toaster.open({
                    text: element.msg,
                    type: 'danger',
                    position: 'top-right',
                    duration: 10000
                });
            });
            this.router.navigate(['/machine']);
        });
    }

    getMarketSetup(entityId = '') {
        this.spinner.show();
        if (entityId) {
            this.levelSetup.marketId = this.entityId;
            this.entityService.getMarketSetup(entityId).subscribe(response => {
                if (response) {
                    this.hasLevelSetup = true;
                    this.levelSetup = response.data;
                }
                this.entityService.entityExistingData['level-' + this.levelNumber]['tab-1'] = {};
                this.entityService.entityLatestData['level-' + this.levelNumber]['tab-1'] = {};
                Object.entries(this.levelSetup).forEach(([key, value]) => {
                    this.entityService.entityExistingData['level-' + this.levelNumber]['tab-1'][key] = value;
                    this.entityService.entityLatestData['level-' + this.levelNumber]['tab-1'][key] = value;
                });
            }, err => {
                this.spinner.hide();
                let errors = err.error;
                errors.forEach(element => {
                    this.toaster.open({
                        text: element.msg,
                        type: 'danger',
                        position: 'top-right',
                        duration: 10000
                    });
                });

            });
        }
    }
    updateLevelSetup(isNext) {
        this.spinner.show();
        this.entityService.addMarketSetup(this.levelSetup).subscribe(response => {
            this.hasLevelSetup = true;
            this.toaster.open({
                text: "Market level setup is updated successfully",
                type: 'success',
                position: 'top-right',
                duration: 10000
            });
            if (isNext) {
                this.entityService.entityExistingData['level-' + this.levelNumber]['tab-1'] = {};
                this.entityService.entityLatestData['level-' + this.levelNumber]['tab-1'] = {};
                Object.entries(this.levelSetup).forEach(([key, value]) => {
                    this.entityService.entityExistingData['level-' + this.levelNumber]['tab-1'][key] = value;
                    this.entityService.entityLatestData['level-' + this.levelNumber]['tab-1'][key] = value;
                });
                this.tabIndex = 2;
            } else {
                this.router.navigate(['/entity']);
            }
            this.spinner.hide();
        }, err => {
            this.spinner.hide();
            let errors = err.error;
            errors.forEach(element => {
                this.toaster.open({
                    text: element.msg,
                    type: 'danger',
                    position: 'top-right',
                    duration: 10000
                });
            });
        });
    }

    setFormGroup() {
        let i = 0;
        this.countryCodes = []
        this.filedsJson.forEach(element => {
            if (element.validationRegex == 'phone') { //To remove country code field from formbuilder
                this.countryCodes[i - 1] = this.filedsJson[i - 1];
                this.filedsJson.splice(i - 1, 1);
            }
            else if (element.fieldType == 'Image') { //GD2-3677
                this.filedsJson.splice(i, 1);
            }
            i++;
            if (this.filedsJson.length === i) {
                //GD2-5491
                if (!this.disableLockdownFieldsForConcessionsFT && this.isGridOneIngested && !this.isConcessionMigrated) {
                    this.getEntityPropositionAsPromise()
                        .then(() => {
                            this.disableAdditionalApiFields();
                            //
                            this.createFormGroup();
                        });
                }
                else {
                    this.createFormGroup();
                }

            }
        })
    }
    openMovePopup() {
        if (this.screenType == "move") {
            if (this.levelNumber == 8 && !this.ismachineMovedToCrossMarket) {
                // GD2-1715 AC 6
                this.toaster.open({
                    text: 'If you use the reference field you may need to update this to a new reference',
                    type: 'info',
                    position: 'top-right',
                    duration: 20000
                });
                return;
            }
            let machineFields = [];
            let title = "";
            let message = "";
            if (this.levelNumber == 2 || this.levelNumber == 3 || this.levelNumber == 5 || this.levelNumber == 6 || this.levelNumber == 3.5) {
                title = "Move Entity"
            }
            else if (this.levelNumber == 4) {
                title = "Move Customer"
            }
            else if (this.levelNumber == 7) {
                title = "Move Site"
            }
            else if (this.levelNumber == 8) {
                title = "Move Machine"
            }
            this.infoConfirmDataModel = new AlertConfirmDialogModel();
            this.infoConfirmDataModel.action = "Information";
            this.infoConfirmDataModel.dialogId = "machine-move-validation";
            this.infoConfirmDataModel.okButtonName = "Ok";
            this.infoConfirmDataModel.isVisibleCancel = false;
            this.infoConfirmDataModel.title = title;
            this.infoConfirmDataModel.message = "<p class='pt-0'>Please complete all mandatory fields</p>";
            if (this.levelNumber == 8) {
                this.infoConfirmDataModel.message = "<p class='pt-0'>Please ensure that you enter all machine entity details and ingredients configuration to complete machine movement</p>";
            } else if (this.levelNumber == 4) {
                this.infoConfirmDataModel.message += "<p class='pt-0'>Please ensure you have the correct price card applied</p>"; //l
            }
            if (!localStorage.getItem('isOpenedConfirmationPopup')) { //GD2-2849
                localStorage.setItem('isOpenedConfirmationPopup', '1');
                this.dialogTemplateData = {
                  htmlContent: this.infoConfirmDataModel.message
                };
                const { key, component } = this.dialogService.openDialog();
                component.instance.title = this.infoConfirmDataModel.title;
                component.instance.secondary = this.infoConfirmDataModel.okButtonName;
                component.instance.contentTemplate = this.dialogTemplate;
                const dialogSubs = new Subscription();
                dialogSubs.add(
                  component.instance.secondaryClick.subscribe(() => {
                    this.onConfirmOk();
                    this.dialogService.closeDialog(key);
                    dialogSubs.unsubscribe();
                  }),
                );
            }
        }
    }
    createFormGroup() {
        if (!this.entityForm) {
            this.entityForm = new FormGroup({});
        }

        if (this.levelNumber == 7) {
          let val = { value: this.isLowAdminActive, disabled: this.screenType === 'read' || !this.roleService.objRole.isSystemAdmin };

          this.entityForm.setControl('siteLowAdminSetup', new FormControl(val, []));
        }

        if (this.levelNumber != 1) {
            let val = { value: this.parentEntityId, disabled: false };

            if (this.screenType != 'add') {
                val.disabled = true;
            }
            this.entityForm.setControl('parentEntityId', new FormControl(val, [Validators.required, this.requireMatch('parentEntityId', null, true)]));
            this.getParentEntityLookupAsPromise()
                .then(() => {
                    if (this.levelNumber == 8) {
                        let val = { value: this.machineModelId, disabled: false };
                        if (this.screenType == 'read' || (this.screenType == 'edit') || this.screenType == 'move') {
                            val.disabled = true;
                            this.machineTypeEditable = false;
                        }

                        if ((this.screenType == 'edit' || this.screenType == 'move') && this.marketDetails['machineTwinValidated'] == true) {
                            this.machineTypeEditable = true;
                        }

                        this.entityForm.setControl('machineModelId', new FormControl(val, [Validators.required]));

                        let propositionTypeValue = { value: this.propositionTypeId, disabled: false };
                        if (this.roleService.objRole.isSiteSupportL1 || this.roleService.objRole.isSiteSupportL2 || this.roleService.objRole.isSiteSupportL3)
                            propositionTypeValue.disabled = true;

                        this.entityForm.setControl('propositionTypeId', new FormControl(propositionTypeValue)); // required field validator added after lookup api call

                        if (this.screenType == 'add' || this.screenType == 'move' || (this.screenType == 'edit' && this.reloadProposition)) {
                            let updateMachineModelJson = (this.screenType == 'edit' || this.screenType == 'move');
                            this.propositionTypeLookup(updateMachineModelJson);
                        }
                        this.reloadProposition = false;
                    }
                });
        }

        let i = 0;

        this.filedsJson.forEach(element => {
            if (this.screenType == "edit" &&
                !this.entityConstant.autonomousMachineTypeList.includes(this.marketDetails.machineModelName) &&
                this.entityConstant.fieldUniqueNames.machineCommissioningStatus == element.fieldUniqueName &&
                this.entityForm.controls["machineCommissioningStatus"]) {
                this.entityForm.controls["machineCommissioningStatus"].enable();
            }

            if (this.levelNumber == 7
                && this.screenType == "edit"
                && this.selectedPropositionTypeName == 'Capsules'
                && this.entityForm.controls["siteLowAdminSetup"]
                && this.roleService.objRole.isSystemAdmin) {
              this.entityForm.controls["siteLowAdminSetup"].enable();
            }

            if (this.levelNumber == 8) {
                if (this.machineSrNo != '' && this.machineSrNo != undefined && this.machineSrNo != null) {
                    if (element.fieldUniqueName == 'machineSerialNumber') {
                        element.value = this.machineSrNo;
                    }
                }
                if (this.geoPositionLat != '' && this.geoPositionLat != undefined && this.geoPositionLat != null) {
                    if (element.fieldUniqueName == 'machineGeoPositionLat') {
                        element.value = this.geoPositionLat;
                    }
                }
                if (this.geoPositionLong != '' && this.geoPositionLong != undefined && this.geoPositionLong != null) {
                    if (element.fieldUniqueName == 'machineGeoPositionLong') {
                        element.value = this.geoPositionLong;
                    }
                }
                if (this.screenType == "edit" &&
                    element.value == CommissioningStatusEnum.InProgress &&
                    this.entityConstant.fieldUniqueNames.machineCommissioningStatus == element.fieldUniqueName &&
                    this.entityForm.controls["machineCommissioningStatus"]) {
                    this.entityForm.controls["machineCommissioningStatus"].disable();
                }
            }
            let validations = [];
            if (element.isMandatory) {
                validations.push(Validators.required);
            }
            if (element.validationRegex) {
                let pattern = "";
                if (element.validationRegex == 'email') {
                    pattern = "[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,3}";//"[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}";
                } else if (element.validationRegex == 'phone') {
                    pattern = "^[0-9]*$";
                } else if (element.validationRegex == 'phonecountry') {
                    pattern = "^[0-9]*$";
                } else if (element.validationRegex == 'uniqueid') {
                    pattern = "[_A-z0-9]*((-|\s)*[_A-z0-9])*$";
                } else if (element.validationRegex == 'tradecode') {
                    pattern = "^[0-9a-zA-Z]*$";
                } else if (element.validationRegex == 'nonnumeric') {
                    pattern = "^[a-zA-Z ._^%$#!*()+~@&=?,-]*$";
                } else if (element.validationRegex == 'numeric') {
                    pattern = "^[0-9]*$";
                } else if (element.validationRegex == 'alphanumeric') {
                    pattern = "^[a-zA-Z0-9]*$";
                }
                //pattern ="\\s?\\S+(?: \\S+)*\\s?"; to validation double space or start with space for a string
                if (pattern) {
                    validations.push(Validators.pattern(pattern));
                }
            }
            if (fieldValidationJson[element.fieldUniqueName]) { // Validation for minimum and maximum length
                validations.push(Validators.minLength(fieldValidationJson[element.fieldUniqueName]['length']['min']));
                validations.push(Validators.maxLength(fieldValidationJson[element.fieldUniqueName]['length']['max']));
            }

            if (element.fieldType == "SearchableDropdown") {
                validations.push(this.requireMatch(element.fieldUniqueName, this.filteredOptionsObj, false));
            }
            let val = { value: element.value, disabled: false };
            if ((this.screenType == "edit" || this.screenType == "read") &&
                this.entityConstant.autonomousMachineTypeList.includes(this.marketDetails.machineModelName) &&
                this.levelNumber == EntityLevel.Machine &&
                element.fieldUniqueName == this.entityConstant.fieldUniqueNames.machineCommissioningStatus) {
                val.disabled = true;
            }

            if (this.screenType == 'read' || element.fieldType == 'Label' || (this.screenType == 'edit' && (element.isConfirmationPopup || !element.isEditable)) || (this.screenType == 'move' && (element.isConfirmationPopup || !element.isEditable)) || (this.screenType == 'add' && !element.isEditableForAddScreen) || ((this.screenType == 'edit' || this.screenType == 'move') && this.levelNumber == 8 && !this.marketDetails['isServeOptionEditable'] && element.fieldUniqueName == 'machineServeOption')) {
                val.disabled = true;
            }


            if (this.levelNumber == 8 && element.isEditable == false) {
                val.disabled = true;
            }
            if ((this.screenType == 'edit' || this.screenType == 'move') && !this.shouldBlockFieldsForConcessions) {
                if (!(this.roleService.objRole.isSiteSupportL1 || this.roleService.objRole.isSiteSupportL2 || this.roleService.objRole.isSiteSupportL3)) {
                    let isCustomDisabled = this.machineFields.get(element.fieldUniqueName);
                    if (isCustomDisabled != undefined)
                        val.disabled = !this.marketDetails[isCustomDisabled];
                }
            }
            if (this.screenType == 'add' && this.levelNumber == 8 && (element.fieldUniqueName == "machineWorkingStatus" || element.fieldUniqueName == "machineTargetWorkingStatus" || element.fieldUniqueName == "machineSuspendReason")) {
                val.disabled = true;
                if (element.fieldUniqueName == "machineSuspendReason") {
                    val.value = "N/A";
                } else {
                    val.value = "Disabled";
                }
            }
            if ((this.screenType == 'edit' || this.screenType == 'read') && this.levelNumber == 8 && (element.fieldUniqueName == "machineWorkingStatus" || element.fieldUniqueName == "machineTargetWorkingStatus")) {
                val.disabled = true;
            }
            if (this.levelNumber == 7 && this.screenType != 'add' && element.fieldUniqueName == "sitePropositionType") {
                val.value = val.value?.toLowerCase();
            }
            this.entityForm.setControl(element.fieldUniqueName, new FormControl(val, validations));
            // Auto complete dropdown //
            if (element.fieldAPIUrl && !element.isSuggestive) {
                this.filedsJson[i]['fieldOptions'] = [];
                this.getLookup(element, i);
            }
            if (element.isSuggestive) {
                this.suggestionObj[element.fieldUniqueName] = [];
                this.autoSuggestionShowObj[element.fieldUniqueName] = false;
            }
            // Auto complete dropdown //
            if (element.fieldType == 'Radio') { // Radio button value set
                this.entityForm.controls[element.fieldUniqueName].setValue((element.value == 'Inactive') ? false : true);
                this.radioObj[element.fieldUniqueName] = (element.value == 'Inactive') ? false : true;
            }

            // To add country code manually into formgroup
            if (element.validationRegex == 'phone') { //To add country code field in formbuilder
                validations = [];
                if (this.countryCodes[i].isMandatory) {
                    validations.push(Validators.required);
                }
                if (this.countryCodes[i].validationRegex) {
                    let pattern = "";
                    if (this.countryCodes[i].validationRegex == 'phonecountry') {
                        pattern = "^[0-9]*$";
                    }
                    if (pattern) {
                        validations.push(Validators.pattern(pattern));
                    }
                }
                if (fieldValidationJson[this.countryCodes[i].fieldUniqueName]) { // Validation for minimum and maximum length
                    validations.push(Validators.minLength(fieldValidationJson[this.countryCodes[i].fieldUniqueName]['length']['min']));
                    validations.push(Validators.maxLength(fieldValidationJson[this.countryCodes[i].fieldUniqueName]['length']['max']));
                }
                val = { value: this.countryCodes[i].value, disabled: false };
                if (this.screenType == 'read' || this.countryCodes[i].fieldType == 'Label') {
                    val.disabled = true;
                } else if (this.screenType == 'edit' && !this.countryCodes[i].isEditable) {
                    val.disabled = true;
                }
                this.entityForm.setControl(this.countryCodes[i].fieldUniqueName, new FormControl(val, validations));
            }

            i++;
            if (this.filedsJson.length === i) {
                this.isFormLoad = true;
                if (this.screenType.toLowerCase() == 'edit') {
                    this.entityForm.markAllAsTouched();
                }
                if (this.roleService.objRole.isSystemAdmin || this.roleService.objRole.isGlobalMarketAdmin || this.roleService.objRole.isMarketAdmin || this.roleService.objRole.isLevel2Admin || this.roleService.objRole.isLevel3Admin) {
                    this.openMovePopup();
                }

                //GD2-1348
                if (this.levelNumbersHasTab.includes(this.levelNumber.toString())) {
                    this.entityService.entityExistingData['level-' + this.levelNumber]['tab-0'] = this.entityForm.getRawValue();
                    this.entityService.entityLatestData['level-' + this.levelNumber]['tab-0'] = this.entityForm.getRawValue();

                    this.entityForm.valueChanges.subscribe(x => {
                        if (this.levelNumbersHasTab.includes(this.levelNumber.toString())) {
                            this.entityService.entityLatestData['level-' + this.levelNumber]['tab-0'] = this.entityForm.getRawValue();
                        }
                    })
                }
            }

            if (this.entityForm.controls[element.fieldUniqueName]) {
                if (this.entityForm.controls[element.fieldUniqueName].disabled) {
                    this.entityForm.controls[element.fieldUniqueName].disable();
                }
                else {
                    this.entityForm.controls[element.fieldUniqueName].enable();
                }
            }


        });

        if(this.levelNumber == 5 && this.screenType == 'edit' && this.entityForm.controls["level5EntityName"].value.includes("Default L5")){
            this.entityForm.controls["level5EntityName"].disable();
        }

        if(this.levelNumber == 6 && this.screenType == 'edit' && this.entityForm.controls["level6EntityName"].value.includes("Default L6")){
            this.entityForm.controls["level6EntityName"].disable();
        }

        // GD2-4423
        if (this.levelNumber == 7 && (this.screenType == 'edit' || this.screenType == 'move') && this.marketDetails.siteHaveMachines) {
            this.entityForm.controls['sitePropositionType'].disable();
        }

        this.initialFormValues = this.entityForm.getRawValue();
    }

    displayFn(options, e): (id: number) => string {
        return (id: number) => {
            if (id) {
                if (e.fieldUniqueName == 'parentEntityId') {
                    if (this.parentEntityList.find(obj => obj.masterDataId == id)) {
                        let obj = this.parentEntityList.find(obj => obj.masterDataId == id);
                        if (Object.keys(obj).length) {
                            if (this.levelNumber == 8) {
                                let oldMarketId = this.marketId;
                                this.marketId = obj.marketId;
                                if ((this.marketId != null && this.marketId != '' && oldMarketId != this.marketId) || !this.propositionTypeList.length) { // GD2-3635
                                    let updateExistingJsonData = (this.screenType == 'read' || this.screenType == 'edit'); //GD2-3930
                                    setTimeout(() => {
                                        this.propositionTypeLookup(updateExistingJsonData);
                                    }, 500);
                                }
                                if (this.filedsJson.findIndex(p => p.fieldUniqueName == 'machinePriceCard') != -1) {
                                    var index = this.filedsJson.findIndex(p => p.fieldUniqueName == 'machinePriceCard');
                                    var priceobjlst = this.filedsJson.filter(p => p.fieldUniqueName == 'machinePriceCard');
                                    var priceobj = priceobjlst[0];
                                    if (priceobj.fieldAPIUrl.indexOf("{{marketId}}") != -1) {
                                        priceobj.fieldAPIUrl = priceobj.fieldAPIUrl.replace("{{marketId}}", this.marketId);
                                    } else {
                                        if ((priceobj.fieldAPIUrl.split('&marketId=')).length > 0)
                                            priceobj.fieldAPIUrl = priceobj.fieldAPIUrl.split('&marketId=')[0] + '&marketId=' + this.marketId;
                                    }
                                    this.getLookup(priceobj, index);
                                }
                            }
                            return obj.text
                        } else {
                            return '';
                        }
                    } else {
                        return '';
                    }
                } else {
                    var index = this.filedsJson.findIndex(p => p.fieldUniqueName == e.fieldUniqueName)
                    if (this.filedsJson[index]['fieldOptions'].find(obj => obj.masterDataId == id)) {
                        let obj = this.filedsJson[index]['fieldOptions'].find(obj => obj.masterDataId == id);
                        if (this.filedsJson.filter(x => x.fieldUniqueName == "level2FranchiseCountryID" || x.fieldUniqueName == "level3FranchiseCountryID").length) {
                            if (this.levelNumber == 2 && e.fieldUniqueName == 'level2CountryCode' && this.screenType == 'add') {
                                this.entityForm.controls['level2FranchiseCountryID'].patchValue(this.setFranchiseCountryId(this.gridId, obj.value));
                            }
                            if (this.levelNumber == 3 && e.fieldUniqueName == 'level3CountryCode' && this.screenType == 'add') {
                                this.entityForm.controls['level3FranchiseCountryID'].patchValue(this.setFranchiseCountryId(this.gridId, obj.value));
                            }
                        }
                        if (Object.keys(obj).length) {
                            return obj.text
                        } else {
                            return '';
                        }
                    } else {
                        return '';
                    }
                }
            } else {
                return '';
            }
        }
    }
    setFranchiseCountryId(gridName, countyCode) {
        if (this.endsWithcountryCode("-" + countyCode, gridName)) {
            return gridName;
        }
        else {
            return gridName + '-' + countyCode;
        }
    }

    endsWithcountryCode(suffix, str) {
        return str.match(suffix + "$") == suffix;
    }
    getLookup(e, index) {
        if (!e.isSuggestive) {
            e.fieldAPIUrl = e.fieldAPIUrl.replace("{{marketId}}", this.marketId);
            if (this.levelNumber == 7) { //GD2-4423
                e.fieldAPIUrl = e.fieldAPIUrl.replace("{parentEntityId}", this.parentEntityId);
            }
            this.entityService.getLookup(e.fieldAPIUrl).subscribe(response => {
                if (e.fieldUniqueName == "machineSuspendReason") {
                    this.filedsJson[index]['fieldOptions'] = [];
                    response['data'].forEach(element => {
                        this.filedsJson[index]['fieldOptions'].push({ "text": element.text, "value": element.value })
                    });
                }
                else if (e.fieldUniqueName == "sitePropositionType") { //GD2-4423
                    this.filedsJson[index]['fieldOptions'] = [];
                    this.filedsJson[index]['fieldOptions'] = (response['data'].filter(x => x.isEnabled).map(element => ({ masterDataId: element.propositionTypeId, text: element.name })));
                    if (this.isWareHouseMultiProposition) {
                        this.handleSiteWarehouseMultipleProposition();
                    }
                    else if (this.filedsJson[index]['fieldOptions'].filter(x => x.masterDataId.toLowerCase() == e.value.toLowerCase()).length == 0) {
                        e.value = "";
                        this.entityForm.patchValue({ [e.fieldUniqueName]: e.value });
                    }
                }
                else {
                    if (response) {
                        this.filedsJson[index]['fieldOptions'] = response['data'];
                    }
                }
                if (e.fieldType == 'SearchableDropdown') {
                    this.filteredOptionsObj[e.fieldUniqueName] = '';
                    this.filteredOptionsObj[e.fieldUniqueName] = this.entityForm.controls[e.fieldUniqueName]?.valueChanges
                        .pipe(
                            startWith(''),
                            map(value => this._filter(value, index, this.filedsJson[index]['fieldOptions']))
                        );
                    this.entityForm.patchValue({ [e.fieldUniqueName]: e.value })
                }

                if (e.cascadingKey != '' && this.entityForm.value[e.fieldUniqueName]) { // to cascade brandcode based on brandtype
                    let obj = e.fieldOptions.find(obj => obj.masterDataId == this.entityForm.value[e.fieldUniqueName]);
                    this.brandCode = this.entityForm.controls[e.outputVariable]?.value;
                    if (obj?.text != 'COMMERCIAL TRADING') {
                        this.isWareHouseMultiProposition = this.WAREHOUSE_BRANDTYPE == obj?.text;
                        if (this.isWareHouseMultiProposition) {
                            this.handleSiteWarehouseMultipleProposition();
                        }
                        this.entityForm.controls[e.outputVariable]?.disable();
                    }
                }

                if (!this.isLockdownProperty(e) && this.screenType == "edit" &&
                    (["partnerTradeCode", "level5TradeCode", "level6TradeCode", "siteTradeCode"].includes(e.fieldUniqueName) ||
                        ["partnerBrandType", "level5BrandType", "level6BrandType", "siteBrandType"].includes(e.fieldUniqueName))) {
                    this.entityForm.controls[e.fieldUniqueName].enable();
                }

                if (this.levelNumbersHasTab.includes(this.levelNumber.toString())) {
                    this.entityService.entityExistingData['level-' + this.levelNumber]['tab-0'] = this.entityForm.getRawValue();
                    this.entityService.entityLatestData['level-' + this.levelNumber]['tab-0'] = this.entityForm.getRawValue();
                }
            });
        } else {
            this.filteredOptionsObj[e.fieldUniqueName] = '';
            this.entityForm.controls[e.fieldUniqueName].valueChanges
                .pipe(
                    startWith(''),
                    map(value => this._filter(value, index, []))
                );
        }
    }
    autoSuggestion(element, i) {
        if (this.entityForm.value[element.fieldUniqueName].length > 2) {
            this.autoSuggestionShowObj[element.fieldUniqueName] = true;
            let value = this.entityForm.value[element.fieldUniqueName];
            this.entityService.getSuggestion(element['fieldAPIUrl'], {
                'search': value,
                'fieldUniqueName': element.fieldUniqueName
            }).subscribe(response => {
                if (!response) {
                    this.suggestionObj[element.fieldUniqueName] = [];
                } else {
                    this.suggestionObj[element.fieldUniqueName] = response.data;
                }
            }, err => {
                this.suggestionObj[element.fieldUniqueName] = [];
            });

        } else {
            this.autoSuggestionShowObj[element.fieldUniqueName] = false;
            this.suggestionObj[element.fieldUniqueName] = [];
        }
    }
    autoSuggestionFoucs(flag, element, i) {
        if (flag && this.entityForm.value[element.fieldUniqueName].length > 2) {
            this.autoSuggestion(element, i);
            this.autoSuggestionShowObj[element.fieldUniqueName] = flag;
            this.suggestionObj[element.fieldUniqueName] = [];
        } else if (!flag) {
            this.autoSuggestionShowObj[element.fieldUniqueName] = flag;
            this.suggestionObj[element.fieldUniqueName] = [];
        }
    }
    getParentEntityLookupAsPromise(): Promise<void> {
        return new Promise((resolve) => {
            if (this.authService.currentRoute == 'machine') {
                this.entityService.getSiteLookup('').subscribe(response => {
                    if (response) {
                        this.parentEntityList = response['data'];

                        // For autocomplete
                        this.filteredOptionsObj['parentEntityId'] = '';
                        this.filteredOptionsObj['parentEntityId'] = this.entityForm.controls['parentEntityId'].valueChanges
                            .pipe(
                                startWith(''),
                                map(value => this._filter(value, 'parentEntityId', this.parentEntityList))
                            );
                        this.entityForm.patchValue({ ['parentEntityId']: this.parentEntityId })
                    }
                    resolve(null);
                }, err => {
                    this.parentEntityList = [];
                    resolve(null);
                });
            } else {
                if (this.marketId && this.parentEntityId) {
                    this.entityService.getParentEntityLookup({
                        'marketId': this.marketId,
                        'entityId': this.parentEntityId,
                        'levelNumber': this.parentlevelNumber
                    }).subscribe(response => {
                        if (response) {
                            this.parentEntityList = response['data'];

                            // For autocomplete
                            this.filteredOptionsObj['parentEntityId'] = '';
                            this.filteredOptionsObj['parentEntityId'] = this.entityForm.controls['parentEntityId']?.valueChanges
                                .pipe(
                                    startWith(''),
                                    map(value => this._filter(value, 'parentEntityId', this.parentEntityList))
                                );
                            this.entityForm.patchValue({ ['parentEntityId']: this.parentEntityId })
                        }
                        resolve(null);
                    }, err => {
                        resolve(null);
                        this.parentEntityList = [];
                    });
                }
            }
        });
    }
    getMachineModelTypes(parentEntityId = null, propositionTypeId = null, updateExistingJsonData = false, loader = false, type = '') {  //GD2-694
        this.machineModels = [];
        if (loader) {
            this.spinner.show();
        }
        this.machineService.getMachineModelTypeLookup(parentEntityId, propositionTypeId).subscribe(response => {
            this.spinner.hide();
            if (response && this.entityForm.controls['machineModelId']) {
                this.machineModelTypes = response['data'];

                if (this.machineModel != '') {
                    if (!this.machineModelTypes.filter(x => x.machineModelId == this.machineModel && x.isActive).length) {
                        this.entityForm.patchValue({ 'machineModelId': '' }); // GD2-4090
                    } else {
                        this.entityForm.patchValue({ 'machineModelId': this.machineModel });
                    }
                }
                else if (this.marketDetails['machineModelId']) { // ref GD2-3539
                    var chekExistsModel = this.machineModelTypes.find(x => x.machineModelId == this.marketDetails['machineModelId'] && x.isActive);
                    if (chekExistsModel != null && chekExistsModel != '' && chekExistsModel != undefined)
                        this.entityForm.patchValue({ 'machineModelId': this.marketDetails['machineModelId'] });
                    else
                        this.entityForm.patchValue({ 'machineModelId': '' });
                }
                else if (type == 'tabChanged') { //GD2-3930
                    let existingData = this.entityService.entityExistingData['level-' + this.levelNumber]['tab-0'];
                    if (Object.keys(existingData).length > 0 && existingData?.machineModelId) {
                        this.entityForm.patchValue({ 'machineModelId': existingData.machineModelId });
                    }
                }
                else {
                    this.entityForm.patchValue({ 'machineModelId': '' });
                }
                this.onMachineTypeChange(this.entityForm.controls['machineModelId'].value);

                if (updateExistingJsonData)
                    this.entityService.entityExistingData['level-' + this.levelNumber]['tab-0'] = this.entityForm.getRawValue();

                this.entityService.entityLatestData['level-' + this.levelNumber]['tab-0'] = this.entityForm.getRawValue();
                // region GD2-2188 AC1
                var uniqueCode = this.machineModelTypes.map(item => item.schaererMachineType).filter((value, index, self) => self.indexOf(value) === index);
                if (Object.keys(uniqueCode).length > 0) {
                    uniqueCode = uniqueCode.sort((a, b) => a - b); // GD2-2415 AC4
                    uniqueCode.forEach(item => {
                        var models = this.machineModelTypes.filter(x => x.schaererMachineType === item).map(x => x.machineModelName);
                        this.machineModels.push({ 'code': item, 'machineModel': models });
                    });
                }
                // endregion
            } else {
                this.entityForm.patchValue({ 'machineModelId': '' });
            }
        }, err => {
            this.spinner.hide();
            this.machineModelTypes = [];
            this.machineModels = [];
        });
    }
    changeEvent(e) {
        if (!this.entityForm.controls[e.fieldUniqueName].value) {
            this.entityForm.patchValue({ [e.outputVariable]: '' });
        } else {
            if (e.outputVariable == '') {
                this.spinner.show();
                this.brandCode = '';
                let apiUrl = e.changeApiUrl;
                let cascadingKey = e.cascadingKey;
                cascadingKey = cascadingKey.split(',');
                cascadingKey.forEach(element => {
                    apiUrl = apiUrl.replace("{" + element + "}", this.entityForm.controls[element].value);
                });
                if (this.screenType == 'edit' && e.fieldUniqueName == "machineCommissioningStatus") {
                    apiUrl = apiUrl + '?machineId=' + this.entityId;
                }
                if (e.fieldUniqueName == "machineCommissioningStatus" && e.value == "Commissioned" &&
                    this.entityForm.controls[e.fieldUniqueName].value == "Not Commissioned") {//GD2-831
                    this.entityForm.controls['machineWorkingStatus'].setValue("Disabled");
                    this.entityForm.controls['machineTargetWorkingStatus'].setValue("Disabled");
                } else if (e.fieldUniqueName == "machineCommissioningStatus" && e.value == "Commissioned" && this.entityForm.controls[e.fieldUniqueName].value == "Commissioned") {
                    this.entityForm.controls['machineWorkingStatus'].setValue("Trading");
                    this.entityForm.controls['machineTargetWorkingStatus'].setValue("Trading");
                }
                this.machineService.getLookup(apiUrl).subscribe(response => {
                    this.spinner.hide();
                    if (response != '' && response != null) {
                        if (e.fieldUniqueName == "machineCommissioningStatus" && e.fieldOptions.length > 0 && e.fieldOptions.filter(x => x.value == "Not Commissioned") != null) {
                            this.entityForm.controls[e.fieldUniqueName].setValue("Not Commissioned");
                        }

                        this.toaster.open({
                            text: response,
                            type: 'danger',
                            position: 'top-right',
                            duration: 10000
                        });
                    }
                });
            } else {
                let obj = e.fieldOptions.find(obj => obj.masterDataId == this.entityForm.controls[e.fieldUniqueName].value);
                if (obj.text == 'COMMERCIAL TRADING') {
                    this.brandCode = '';
                    this.entityForm.patchValue({ [e.outputVariable]: '' });
                    this.entityForm.controls[e.outputVariable].enable();
                } else {
                    this.brandCode = obj.text;
                    this.entityForm.patchValue({ [e.outputVariable]: obj.text });
                    this.entityForm.controls[e.outputVariable].disable();
                }

                this.handleSiteWarehouseMultipleProposition();
            }
        }
    }

    handleSiteWarehouseMultipleProposition() {
        const propositionTypeAll = { 'masterDataId': 'All', text: 'All' };
        this.entityForm.controls['sitePropositionType'].enable();

        let propositionDropdownSettings = this.filedsJson.filter(it => it.fieldUniqueName == 'sitePropositionType')[0];
        propositionDropdownSettings.fieldOptions = propositionDropdownSettings.fieldOptions.filter(it => it.text != propositionTypeAll.masterDataId);

        this.siteSelectedPropositionTypeId = "";
        this.selectedPropositionTypeName = "";
        this.entityForm.patchValue({ ['sitePropositionType']: this.siteSelectedPropositionTypeId });

        if (this.screenType == 'add')
            this.isWareHouseMultiProposition = false;

        if (this.entityForm.controls['siteBrandCode'].value == this.WAREHOUSE_BRANDTYPE) {
            this.entityForm.patchValue({ ['sitePropositionType']: propositionTypeAll.masterDataId });
            this.entityForm.controls['sitePropositionType'].disable();

            this.siteSelectedPropositionTypeId = propositionTypeAll.masterDataId;
            this.isWareHouseMultiProposition = true;

            propositionDropdownSettings.fieldOptions.push(propositionTypeAll);
        }
    }

    tabChangeFromMacineModel(tabIndex) {
        if (tabIndex == -1) { // When save machine model
            this.getmachineDetails();
        } else if (tabIndex == -2) { //View to edit screen
            this.router.navigate(['/machine/', this.entityId], { queryParams: { nextLevelNumber: this.levelNumber, screenType: 'edit', parentlevelNumber: this.parentlevelNumber, tabIndex: this.tabIndex } });
            this.ngOnInit();
        } else {
            this.tabIndex = tabIndex;
        }
    }

    edit(entityType = "entity") {
        if (entityType == "entity") {
            this.router.navigate(['/entity/', this.entityId], { queryParams: { screenType: 'edit', parentEntityId: this.parentEntityId, tabIndex: this.tabIndex, nextLevelNumber: this.levelNumber, marketId: this.marketId, parentlevelNumber: this.parentlevelNumber } });
            this.ngOnInit(true);
            this.ngAfterViewInit();
            if (this.levelNumber != 1) {
                this.entityForm.controls['parentEntityId'].enable();
            }
        } else if (entityType == "machine") {
            this.router.navigate(['/machine/', this.entityId], { queryParams: { screenType: 'edit', parentEntityId: this.parentEntityId, tabIndex: 0, nextLevelNumber: this.levelNumber, marketId: this.marketId, parentlevelNumber: this.parentlevelNumber } });
            this.reloadProposition = true;
            this.ngOnInit(true);
        }
    }
    changeParentEntity() {
        this.machineModelTypes = [];
        this.machineModels = [];
        this.propositionTypeList = [];
        this.machineLegacyId = 'NA';
        if (this.parentEntityId != this.entityForm.controls['parentEntityId'].value)
            this.entityHierarchy = [];

        this.parentEntityId = this.entityForm.controls['parentEntityId'].value;
        if (this.authService.currentRoute == 'machine') {
            if (this.machineSrNo != '' && this.geoPositionLat != '' && this.geoPositionLong != '') {
                this.router.navigate(['/machine/add/'], { queryParams: { parentEntityId: this.entityForm.controls['parentEntityId'].value, nextLevelNumber: this.levelNumber, machineSrNo: this.machineSrNo, geoPositionLat: this.geoPositionLat, geoPositionLong: this.geoPositionLong } });
            } else {
                this.router.navigate(['/machine/add/'], { queryParams: { parentEntityId: this.entityForm.controls['parentEntityId'].value, nextLevelNumber: this.levelNumber } });
            }
        } else {
            this.router.navigate(['/entity/add/'], { queryParams: { parentEntityId: this.entityForm.controls['parentEntityId'].value, tabIndex: 0, nextLevelNumber: this.levelNumber, marketId: this.marketId, parentlevelNumber: this.parentlevelNumber } });
        }

        this.loadSiteInfoForMachineAsPromise(this.parentEntityId)
            .then(() => {
                this.getFormsFileds();
            })
    }

    loadSiteInfoForMachineAsPromise(siteId): Promise<void> {
        return new Promise((resolve) => {
            if (this.levelNumber == 8 && siteId) {
                const moduleAction: any = RolePermissionsEnum.Read;
                this.entityService.getEntityDetailsWithForm(siteId, siteId, moduleAction).subscribe(response => {
                    if (response) {
                        this.isWareHouseMultiProposition = response['data'].isWareHouse;
                    }
                    resolve(null);
                }, err => resolve(null));
            }
            else {
                resolve(null);
            }
        });
    }

    add(route = 'list', fromSuspend = false, propositionTypeChange = false) {
        let lable = this.lable;
        this.checkSubmitStatus = true;
        this.entityForm.markAllAsTouched();
        if (this.entityForm.status === 'INVALID') {
            this.toaster.open({
                text: 'Please provide valid input for all the highlighted fields',
                type: 'danger',
                position: 'top-right',
                duration: 10000
            });
        }
        else if (this.levelNumber == 8 && !this.validateMachineSerialNumber()) {
            this.toaster.open({
                text: 'The machine serial number pre-fix is not valid for that machine type',
                type: 'danger',
                position: 'top-right',
                duration: 10000
            });
        }
        else if (this.levelNumber == 8 &&
            this.selectedPropositionType == PropositionMasterEnum.Concession &&
            this.screenType != 'edit' && !this.enableCreateConcessionFT
        ) { // not allowing to create concession machine for as of now
            this.toaster.open({
                text: 'Can not create/update machine for Concession proposition type',
                type: 'danger',
                position: 'top-right',
                duration: 10000
            });
        }

        else if (this.levelNumber == 8 && (this.entityForm.controls['propositionTypeId'].value == null || this.entityForm.controls['propositionTypeId'].value == "" || this.entityForm.controls['propositionTypeId'].value == undefined)) {
            this.toaster.open({
                text: 'Proposition Type is required!',
                type: 'danger',
                position: 'top-right',
                duration: 10000
            });
        }
        else if (this.levelNumber == 8 &&
            this.selectedPropositionType == PropositionMasterEnum.Concession &&
            this.screenType == 'edit' && this.entityForm.controls['machineCommissioningStatus']?.value == CommissioningStatusEnum.InProgress
        ) {
            this.toaster.open({
                text: 'It is not possible to edit a machine when commissioning state is "In Progress"',
                type: 'danger',
                position: 'top-right',
                duration: 10000
            });
        }
        else {
            if (this.entityForm.status === "VALID") {
                let machineSuspendReasonIndex = this.filedsJson.findIndex(el => el.fieldUniqueName == 'machineSuspendReason');
                let sitePropositionTypeIndex = this.filedsJson.findIndex(el => el.fieldUniqueName == "sitePropositionType");
                if (!fromSuspend && this.levelNumber == 8 && this.entityForm.controls["machineSuspendReason"].value && this.entityForm.controls["machineSuspendReason"].value != "N/A" && this.filedsJson[machineSuspendReasonIndex].value != this.entityForm.controls["machineSuspendReason"].value && (this.screenType == 'edit' || this.screenType == 'move')) { //GD2-270 : AC:5 //&& this.entityForm.controls["machineTargetWorkingStatus"].value != "Suspended"
                    this.fromSuspendRoute = route;
                    this.suspendConfirmation();
                }
                else if (!propositionTypeChange && this.levelNumber == 7 && (this.screenType == 'edit' || this.screenType == 'move') && this.filedsJson[sitePropositionTypeIndex].value?.toLowerCase() != this.entityForm.controls["sitePropositionType"].value?.toLowerCase()) { //GD2-4423
                    this.propsitionTypeChangeConfirmation();
                }
                else {
                    if (this.levelNumber == 8 && this.entityForm.controls["machineSuspendReason"].value == "N/A" && (this.screenType == 'edit' || this.screenType == 'move')) { //GD2-270 : AC:6

                        if (this.filedsJson[machineSuspendReasonIndex].value != "N/A") {
                            this.filedsJson[machineSuspendReasonIndex].value = this.entityForm.controls["machineSuspendReason"].value;
                            if (this.selectedPropositionType == PropositionMasterEnum.Capsules || this.selectedPropositionType == PropositionMasterEnum.CoffeeCounter) { //[GD2-3716] AC-8
                                let targetWorkingStatus = this.entityForm.controls['machineCommissioningStatus'].value == "Commissioned" ? "Trading" : "Disabled";
                                this.entityForm.patchValue({ machineTargetWorkingStatus: targetWorkingStatus }); //[GD2-3716] AC-8
                            }
                            else {
                                this.entityForm.patchValue({ machineTargetWorkingStatus: this.entityForm.controls['machineWorkingStatus'].value });
                            }
                        }
                    } else if (this.levelNumber == 8) {
                        let machineSuspendReasonIndex = this.filedsJson.findIndex(el => el.fieldUniqueName == 'machineSuspendReason')
                        this.filedsJson[machineSuspendReasonIndex].value = this.entityForm.controls["machineSuspendReason"].value;
                    }

                    // region GD2-1715 AC 8
                    if ((this.levelNumber == 4 || this.levelNumber == 5 || this.levelNumber == 6) && (this.screenType == 'edit' || this.screenType == 'move')) {
                        var brandCodeFieldName = this.levelNumber == 4 ? "partnerBrandCode" : (this.levelNumber == 5 ? "level5BrandCode" : "level6BrandCode");
                        var currentBrandCode: any = this.entityForm.controls[brandCodeFieldName]?.value;
                        if (currentBrandCode != null && currentBrandCode != undefined && this.parentBrandCode != 'CE - NON TRADING' && currentBrandCode == 'CE - NON TRADING') {
                            this.toaster.open({
                                text: 'Brand Type “CE NON TRADING” will not auto populate down to the site level entity',
                                type: 'info',
                                position: 'top-right',
                                duration: 20000
                            });
                        }
                    }
                    // endregion

                    this.spinner.show();
                    let body = [];
                    let i = 0;
                    this.filedsJson.forEach(element => {
                        let obj = {
                            "fieldUniqueName": element.fieldUniqueName,
                            "validationRegex": element.validationRegex,
                            "isMandatory": element.isMandatory,
                            "isMasterField": element.isMasterField,
                            "fieldDisplayName": element.fieldDisplayName,
                            "value": (this.entityForm.controls[element.fieldUniqueName].value != undefined) ? this.entityForm.controls[element.fieldUniqueName].value : (element.fieldDisplayName == 'Brand Code') ? this.brandCode : element.value
                        };
                        if (element.fieldType === 'Radio') { // Radio button value set
                            obj.value = (this.radioObj[element.fieldUniqueName] == true) ? 'Active' : 'Inactive'
                        }
                        if (this.countryCodes.hasOwnProperty(i)) { //to add phone code before phone number
                            if ((this.entityForm.value[this.countryCodes[i].fieldUniqueName] != undefined)) {
                                this.countryCodes[i].value = this.entityForm.value[this.countryCodes[i].fieldUniqueName];
                            }
                            body.splice(i, 0, this.countryCodes[i])
                        }
                        body.push(obj);
                        i++;
                        if (i === this.filedsJson.length) {
                            let entityBody = {}
                            if ((this.screenType == "add") || (this.screenType == "edit" && this.levelNumber != 8)) {
                                entityBody = {
                                    "parentEntityId": (this.levelNumber == 1 || this.screenType != "add") ? this.parentEntityId : this.entityForm.value['parentEntityId'],
                                    "levelNumber": this.levelNumber,
                                    "isWareHouse": false,
                                    "formResponse": body
                                };
                            }
                            else {
                                entityBody = {
                                    "parentEntityId": this.parentEntityId,
                                    "machineId": this.entityId,
                                    "formResponse": body,
                                    "levelNumber": this.levelNumber,
                                };
                            }
                            if (this.levelNumber == 8 && this.machineTypeEditable) {
                                entityBody['machineModelId'] = this.entityForm.value['machineModelId'];
                            }
                            if (this.levelNumber == 7
                                && this.roleService.objRole.isSystemAdmin
                                && this.selectedPropositionTypeName == this.propositionMasterEnum.Capsules) {
                              entityBody['siteLowAdminSetup'] = {
                                'isActive': this.isLowAdminActive,
                                'propositionTypeId': this.siteSelectedPropositionTypeId,
                                'entityId': this.entityId
                              }
                            }
                            if (this.screenType == 'edit' || this.screenType == 'move') {
                                if (this.levelNumber == 7
                                    && this.roleService.objRole.isSystemAdmin
                                    && this.selectedPropositionTypeName == this.propositionMasterEnum.Capsules) {
                                  entityBody['isSiteLowAdmin'] = this.isLowAdminActive ;
                                }
                                if (this.levelNumber == 8 && (!this.machineTypeEditable || this.marketDetails['machineTwinValidated'] == true)) {
                                    entityBody['machineModelId'] = this.marketDetails['machineModelId'];
                                }
                                if (this.levelNumber == 8 && this.screenType == 'move') {//[GD2-4037]
                                    entityBody['isMoveRequest'] = true;
                                }

                                this.entityService.updateEntity(entityBody, this.entityId).then((res) => res.subscribe(response => {
                                    if (this.levelNumber != 8)
                                        this.removeStorage();
                                    else
                                        this.removeMachineStorage();

                                    this.entityService.removeMarketFieldVisibilityStorage();
                                    this.spinner.hide();
                                    // region GD2-1310
                                    if (this.screenType == 'move' && (this.levelNumber == 8)) {
                                        this.toaster.open({
                                            text: "Please Note:  The price card has automatically be updated with the price card set against the Customer. You are able to manually allocate a different price card if required.",
                                            type: 'info',
                                            position: 'top-right',
                                            duration: 10000
                                        });
                                    }
                                    // endregion
                                    // region GD2-2865
                                    if (this.levelNumber == 7 && this.screenType == "move") {
                                        this.toaster.open({
                                            text: 'Please ensure the price card allocated is correct for this site',
                                            type: 'info',
                                            position: 'top-right',
                                            duration: 10000
                                        });
                                    }
                                    // endregion
                                    if (route == 'list') {
                                        if (this.levelNumber == 8) {
                                            if (this.screenType == 'move') this.canLeaveMoveScreenType = true;
                                            this.toaster.open({
                                                text: "Machine has been updated Successfully",
                                                type: 'success',
                                                position: 'top-right',
                                                duration: 10000
                                            });
                                            this.resetMachineDetailsChanges(true)
                                            if (this.ismachineMovedToCrossMarket) { //GD2-267
                                                this.tabIndex = 2;
                                                this.router.navigate(['/machine/', this.entityId], { queryParams: { screenType: 'edit', parentEntityId: this.parentEntityId, tabIndex: this.tabIndex, marketId: this.marketId, nextLevelNumber: this.levelNumber, parentlevelNumber: this.parentlevelNumber } });
                                            }
                                            else
                                                this.router.navigate(['/machine']);
                                        } else {
                                            this.toaster.open({
                                                text: lable + " has been updated successfully",
                                                type: 'success',
                                                position: 'top-right',
                                                duration: 10000
                                            });
                                            this.router.navigate(['/entity']);
                                        }
                                    } else {
                                        this.entityService.entityExistingData['level-' + this.levelNumber]['tab-0'] = this.entityForm.getRawValue();

                                        this.toaster.open({
                                            text: lable + " has been updated successfully",
                                            type: 'success',
                                            position: 'top-right',
                                            duration: 10000
                                        });

                                        if (this.levelNumber == 8) {
                                            //Getting machine details with machine model type details #GD2-694
                                            this.spinner.show();
                                            this.machineService.getMachineDetailsWithForm(this.machineId, this.parentEntityId).subscribe(response => {
                                                this.spinner.hide();
                                                if (!response) {
                                                    this.toaster.open({
                                                        text: 'Something went wrong. Please try again.',
                                                        type: 'danger',
                                                        position: 'top-right',
                                                        duration: 10000
                                                    });
                                                } else {
                                                    let nextTabIndex = 1;
                                                    this.marketDetails = response['data'];

                                                    if ((this.marketDetails['machineModelType']?.trim() != 'Proprietary' && this.selectedPropositionType.toLowerCase() == PropositionMasterEnum.CoffeeCounter.toLowerCase())
                                                        || (this.selectedPropositionType.toLowerCase() == PropositionMasterEnum.Capsules.toLowerCase() && this.screenType == 'edit') || (this.selectedPropositionType.toLowerCase() == PropositionMasterEnum.Autonomous.toLowerCase() && this.screenType == 'edit')
                                                    ) { //GD2-3459
                                                        nextTabIndex = 2;
                                                    }
                                                    this.tabIndex = nextTabIndex;
                                                    this.router.navigate(['/machine/', this.entityId], { queryParams: { screenType: 'edit', parentEntityId: this.parentEntityId, tabIndex: nextTabIndex, marketId: this.marketId, nextLevelNumber: this.levelNumber, parentlevelNumber: this.parentlevelNumber } });
                                                }
                                            }, err => {
                                                let errors = err.error;
                                                errors.forEach(element => {
                                                    this.toaster.open({
                                                        text: element.msg,
                                                        type: 'danger',
                                                        position: 'top-right',
                                                        duration: 10000
                                                    });
                                                });

                                            });
                                        } else {
                                            this.tabIndex = 1;
                                            this.getFormsFileds(this.entityId);
                                            this.router.navigate(['/entity/', this.entityId], { queryParams: { screenType: 'edit', parentEntityId: this.parentEntityId, tabIndex: 1, marketId: this.marketId, nextLevelNumber: this.levelNumber, parentlevelNumber: this.parentlevelNumber } });
                                        }
                                    }
                                }, err => {
                                    let errors = err.error;
                                    errors.forEach(element => {
                                        this.toaster.open({
                                            text: element.msg,
                                            type: 'danger',
                                            position: 'top-right',
                                            duration: 10000
                                        });
                                        if (element.code && element.code == 'SITEHASNOTTRADEHOURS') {
                                            this.entityForm.patchValue({ 'siteAccountStatus': false });
                                        }
                                    });
                                }));

                            } else {
                                if (this.levelNumber == 7) {
                                    let siteTimeZoneValueObjectArray = entityBody["formResponse"].filter(x => x.fieldUniqueName === 'siteTimeZone');
                                    if (siteTimeZoneValueObjectArray != null && siteTimeZoneValueObjectArray.length > 0) {
                                        let timeZoneId = siteTimeZoneValueObjectArray[0].value;
                                        let siteTimeZoneFormFieldOptionsObjectArray = this.filedsJson.filter(x => x.fieldUniqueName === 'siteTimeZone');
                                        if (siteTimeZoneFormFieldOptionsObjectArray != null && siteTimeZoneFormFieldOptionsObjectArray.length > 0) {
                                            let siteTimeZoneFormObjectArray = siteTimeZoneFormFieldOptionsObjectArray[0].fieldOptions.filter(x => x.masterDataId === timeZoneId);
                                            if (siteTimeZoneFormObjectArray != null && siteTimeZoneFormObjectArray.length > 0) {
                                                this.entityService.timeZoneValue = siteTimeZoneFormObjectArray[0].text;
                                            }
                                        }
                                    }
                                }

                                entityBody["marketId"] = this.marketId;
                                let msg = lable + " has been added successfully";
                                if (this.levelNumber == 8) {
                                    if (this.machineSrNo != '' && this.entityForm.controls['machineSerialNumber'].value != '' && this.entityForm.controls['machineSerialNumber'].value != null) {
                                        if (this.machineSrNo == this.entityForm.controls['machineSerialNumber'].value) {
                                            msg = 'Machine added successfully. Removed from the list of Unrecognised Machines.';
                                        } else {
                                            msg = lable + " added successfully";
                                        }
                                    } else {
                                        msg = lable + " added successfully";
                                    }
                                }
                                if (this.levelNumber == 7 || this.levelNumber == 8) {
                                    this.entityService.addEntityValidate(entityBody).subscribe(response => {
                                        this.entityService.addEntityOnHoldData['level-' + this.levelNumber]['tab-0'] = entityBody;
                                        this.isValidateEntityDetailsTab = true;
                                        if (this.levelNumber == 7)
                                            this.tabIndex = + 1;
                                        else {
                                            if (this.selectedPropositionType == PropositionMasterEnum.Autonomous.toString() && this.tabIndex == 0 && this.screenType == 'edit') {
                                                this.tabIndex = 2;
                                            } else {
                                                this.tabIndex = + 1;
                                            }
                                            this.entityService.addEntityOnHoldData['level-' + this.levelNumber]['tab-0']['selectedPropositionType'] = this.selectedPropositionType; //GD2-3459
                                            let machineModelType = this.machineModelTypes.find(x => x.machineModelId == entityBody['machineModelId']).machineModelType;
                                            if ((machineModelType?.trim() != 'Proprietary' && this.selectedPropositionType.toLowerCase() == PropositionMasterEnum.CoffeeCounter.toLowerCase())
                                                || (this.selectedPropositionType.toLowerCase() == PropositionMasterEnum.Capsules.toLowerCase() && this.screenType == 'edit')
                                            ) { //GD2-3459
                                                this.tabIndex = 2;
                                                this.entityService.isValidatedMachineModelTab = true;
                                                this.entityService.saveMachineModelDetails = false;
                                                this.entityService.addEntityOnHoldData['level-8']['tab-1'] = {};
                                            }
                                            else {
                                                if (this.selectedPropositionType == PropositionMasterEnum.Autonomous.toString() && this.tabIndex == 2) {
                                                    this.entityService.isValidatedMachineModelTab = true;
                                                } else {
                                                    this.entityService.isValidatedMachineModelTab = false;
                                                }
                                                this.entityService.saveMachineModelDetails = true;
                                            }
                                        }
                                        this.spinner.hide();
                                        this.entityService.entityExistingData['level-' + this.levelNumber]['tab-0'] = this.entityForm.getRawValue();
                                    }, err => {
                                        let errors = err.error;
                                        errors.forEach(element => {
                                            this.toaster.open({
                                                text: element.msg,
                                                type: 'danger',
                                                position: 'top-right',
                                                duration: 10000
                                            });
                                        });
                                    });
                                } else {
                                    this.entityService.addEntity(entityBody).then(res => res.subscribe(response => {
                                        if (this.parentlevelNumber == 0) {
                                            this.marketId = response;
                                        }
                                        if (this.levelNumber == 8) {
                                            this.machineId = response;
                                        }
                                        if (this.levelNumber != 8)
                                            this.removeStorage();
                                        else
                                            this.removeMachineStorage();
                                        this.spinner.hide();
                                        this.toaster.open({
                                            text: msg,
                                            type: 'success',
                                            position: 'top-right',
                                            duration: 10000
                                        });
                                        if (route == 'list') {
                                            if (this.authService.currentRoute == 'machine') {
                                                this.router.navigate(['/machine']);
                                            } else {
                                                this.router.navigate(['/entity']);
                                            }
                                            //this.router.navigate(['/entity']);
                                        } else if (route == 'nextPartnerDetail' || route == 'nextmachineDetail') {

                                            if (this.levelNumber != 8) {
                                                this.router.navigate(['/entity/', response], { queryParams: { screenType: 'edit', parentEntityId: this.parentEntityId, tabIndex: 1, marketId: this.marketId, nextLevelNumber: this.levelNumber, parentlevelNumber: this.parentlevelNumber } });
                                            } else {
                                                //Getting machine details with machine model type details #GD2-694
                                                this.spinner.show();
                                                this.machineService.getMachineDetailsWithForm(response, this.parentEntityId).subscribe(machineDetailsResponse => {
                                                    this.spinner.hide();
                                                    if (!machineDetailsResponse) {
                                                        this.toaster.open({
                                                            text: 'Something went wrong. Please try again.',
                                                            type: 'danger',
                                                            position: 'top-right',
                                                            duration: 10000
                                                        });
                                                    } else {
                                                        let nextTabIndex = 1;
                                                        this.marketDetails = machineDetailsResponse['data'];

                                                        if ((this.marketDetails['machineModelType'] != 'Proprietary' && this.selectedPropositionType?.trim().toLowerCase() == PropositionMasterEnum.CoffeeCounter.toLowerCase())
                                                            || (this.selectedPropositionType.toLowerCase() == PropositionMasterEnum.Capsules.toLowerCase() && this.screenType == 'edit')
                                                        ) { //GD2-3459
                                                            nextTabIndex = 2;
                                                        }
                                                        this.tabIndex = nextTabIndex;
                                                        this.router.navigate(['/machine/', response], { queryParams: { screenType: 'edit', parentEntityId: this.parentEntityId, tabIndex: nextTabIndex, marketId: this.marketId, nextLevelNumber: this.levelNumber, parentlevelNumber: this.parentlevelNumber } });
                                                    }
                                                }, err => {
                                                    let errors = err.error;
                                                    errors.forEach(element => {
                                                        this.toaster.open({
                                                            text: element.msg,
                                                            type: 'danger',
                                                            position: 'top-right',
                                                            duration: 10000
                                                        });
                                                    });

                                                });
                                            }
                                        } else {
                                            this.router.navigate(['/entity/', response], { queryParams: { screenType: 'edit', parentEntityId: this.parentEntityId, tabIndex: 1, marketId: this.marketId, nextLevelNumber: this.levelNumber, parentlevelNumber: this.parentlevelNumber } });
                                        }
                                    }, err => {
                                        let errors = err.error;
                                        errors.forEach(element => {
                                            this.toaster.open({
                                                text: element.msg,
                                                type: 'danger',
                                                position: 'top-right',
                                                duration: 10000
                                            });
                                        });
                                    }))
                                }
                            }
                        }
                    });
                }
            }
        }
    }
    private suspendConfirmation() {
        const { key, component } = this.dialogService.openDialog();
        component.instance.title = 'Confirmation';
        component.instance.description = 'Are you sure you want to suspend this machine?';
        component.instance.primary = 'No';
        component.instance.secondary = 'Yes';
        const dialogSubs = new Subscription();
        dialogSubs.add(
          component.instance.primaryClick.subscribe(() => {
            this.dialogService.closeDialog(key);
            dialogSubs.unsubscribe();
          }),
        );
        dialogSubs.add(
          component.instance.secondaryClick.subscribe(() => {
            this.onConfirmSuspend();
            this.dialogService.closeDialog(key);
            dialogSubs.unsubscribe();
          }),
        );
    }

    private suspendReasonCancel() {
        const { key, component } = this.dialogService.openDialog();
        component.instance.title = 'Unsaved Changes';
        component.instance.description = 'All changes will be lost, do you wish to continue?';
        component.instance.primary = 'No';
        component.instance.secondary = 'Yes';
        const dialogSubs = new Subscription();
        dialogSubs.add(
          component.instance.primaryClick.subscribe(() => {
            this.dialogService.closeDialog(key);
            dialogSubs.unsubscribe();
          }),
        );
        dialogSubs.add(
          component.instance.secondaryClick.subscribe(() => {
            this.onConfirmSuspend();
            this.dialogService.closeDialog(key);
            dialogSubs.unsubscribe();
          }),
        );
    }

    statusChange(e) {
        let parentEntityId = (this.levelNumber == 1) ? '' : this.entityForm.value['parentEntityId'];
        if (parentEntityId == null || parentEntityId == undefined) {
            parentEntityId = this.entityForm.controls.parentEntityId.value;
        }
        let entityId = (this.entityId) ? this.entityId : '';
        this.entityService.checkEntityStatusValidation(this.radioObj[e.fieldUniqueName], parentEntityId, entityId, this.levelNumber).subscribe(response => {
            if (response.message) {
                this.toaster.open({
                    text: response.message,
                    type: 'info',
                    position: 'top-right',
                    duration: 10000
                });
            }
        }, err => {
            this.radioObj[e.fieldUniqueName] = !this.radioObj[e.fieldUniqueName];
            if (err.error) {
                let errors = err.error;
                errors.forEach(element => {
                    this.toaster.open({
                        text: element.msg,
                        type: 'danger',
                        position: 'top-right',
                        duration: 10000
                    });
                });
            }
        });

        // GD2-4536
        if (this.levelNumber == 7 && this.radioObj[e.fieldUniqueName] && (this.screenType == 'edit' || this.screenType == 'move')) {
            this.checkParentHasPropositionOn(e);
        }
    }

    cancel() {
        if (this.levelNumber == 8 && this.screenType == "move") {
            this.canMachineDeactivate('/machine');
        }
        else if (this.levelNumber == 8 && this.screenType != "read") {

            if (this.unsavedCheck()) {
                this.openDialog('machine_cancel')
            } else {
                this.router.navigate(['/machine']);
            }
        }
        else if (this.levelNumber == 8 && this.screenType == "read") {
            this.router.navigate(['/machine']);
        } else {
            if (this.screenType == "add" || this.screenType == "edit") {

                if (this.unsavedCheck()) {
                    this.openDialog('entity_cancel')
                    } else {
                    this.router.navigate(['/entity']);
                }
            } else {
                let selectedEntityModel = localStorage.getItem("selectedEntityModel");
                let moveEntityModel = localStorage.getItem("moveEntityModel");
                if ((!moveEntityModel || selectedEntityModel == undefined || moveEntityModel == undefined) && this.screenType == 'move') {
                    this.canDeactivate('/entity');
                } else {
                    this.router.navigate(['/entity']);
                }
            }
        }
    }
    cancelSetup() {
        if (this.hasLevelSetup) {
            this.router.navigate(['/entity']);
        } else {
            if (this.screenType == 'read') {
                this.router.navigate(['/entity']);
            } else {
                this.openDialog('market_levlesetup_cancel')
            }
        }
    }

    openDialog(type: string = 'editable', element = {}, i = 0): void {
        if (type == 'entity_cancel' || type == 'machine_cancel') {
            this.dialogTexts = {
                successTitle: 'Yes',
                cancelTitle: 'No',
                message: 'All unsaved changes will be lost. Are you sure you want to cancel?',
                deviceName: '',
                status: '',
                title: 'Unsaved Changes',
                type: type,
                isNext: false
            }
        } else if (type == 'market_levlesetup_cancel') {
            this.dialogTexts = {
                successTitle: 'Yes',
                cancelTitle: 'No',
                message: 'You will not be able to create child entities until the Market Level Setup is complete. Are you sure you want to cancel?',
                deviceName: '',
                status: '',
                title: 'Confirmation',
                type: type,
                isNext: false
            }
        } else if (type == 'tabChanged') {
            this.dialogTexts = {
                successTitle: 'Yes',
                cancelTitle: 'No',
                message: 'You are attempting to navigate away from this screen with unsaved changes - do you wish to proceed?',
                deviceName: '',
                status: '',
                title: 'Unsaved Changes',
                type: type,
                isNext: false
            }
        } else if (type == 'level-setup') {
            this.dialogTexts = {
                successTitle: 'Yes',
                cancelTitle: 'No',
                message: 'This is an irreversible action. Are you sure, you want to save the market level setup?',
                deviceName: '',
                status: '',
                title: 'Update Confirmation',
                type: type,
                isNext: false
            }
        } else {
            this.selectedFiled = element;
            this.selectedFiledIndex = i;
            this.dialogTexts = {
                successTitle: 'Yes',
                cancelTitle: 'No',
                message: 'Are you sure, you want to update',
                deviceName: this.selectedFiled['fieldDisplayName'] + '?',
                status: '',
                title: 'Update Confirmation',
                type: type,
                isNext: false
            }
        }
        this.dialogTemplateData = {
          message: this.dialogTexts.message,
          status: this.dialogTexts.status,
          deviceName: this.dialogTexts.deviceName,
        };
        const { key, component } = this.dialogService.openDialog();
        component.instance.title = this.dialogTexts.title;
        component.instance.primary = 'No';
        component.instance.secondary = 'Yes';
        component.instance.contentTemplate = this.dialogTemplate;
        const dialogSubs = new Subscription();
        dialogSubs.add(
          component.instance.primaryClick.subscribe(() => {
            this.dialogService.closeDialog(key);
            dialogSubs.unsubscribe();
          }),
        );
        dialogSubs.add(
          component.instance.secondaryClick.subscribe(() => {
            this.onConfirm(this.dialogTexts.type, this.dialogTexts.isNext);
            this.dialogService.closeDialog(key);
            dialogSubs.unsubscribe();
          }),
        );
    }

    onConfirm(type: string = 'device_status', isNext: boolean) {
        if (type !== 'editable') {
            this.resetMachineDetailsChanges();
        }

        if (type == 'machine_cancel') {
            if (this.authService.currentRoute == 'machine') {
                this.router.navigate(['/machine']);
            } else {
                this.router.navigate(['/entity']);
            }
        } else if (type == 'entity_cancel' || type == 'market_levlesetup_cancel') {
            this.router.navigate(['/entity']);
        } else if (type == 'level-setup') {
            this.updateLevelSetup(isNext);
        } else if (type == 'tabChanged') { // To revet change of current tab
            if (this.tabIndex == 0) {
                this.entityForm.patchValue(this.entityService.entityExistingData['level-' + this.levelNumber]['tab-0']);
                if (this.levelNumber == 8) {
                    let propositionTypeId = this.marketDetails['propositionTypeId'] ? this.marketDetails['propositionTypeId'] : (this.entityService.entityExistingData['level-' + this.levelNumber]['tab-0']?.propositionTypeId);
                    this.getMachineModelTypes(this.parentEntityId, propositionTypeId, true, false, 'tabChanged');
                }
            } else if (this.levelNumber == 1 && this.tabIndex == 1) { //Market level setup tab
                this.levelSetup = {};
                Object.entries(this.entityService.entityExistingData['level-' + this.levelNumber]['tab-1']).forEach(([key, value]) => {
                    this.levelSetup[key] = value;
                });
            }
            else if ((this.tabIndex == 2 && (this.levelNumber == 1 || this.levelNumber == 7 || this.levelNumber == 8)) ||
                this.tabIndex == 1 && this.levelNumber == 4) {
                this.entityConfigurationComponent.undoValues();
            }
            this.tabIndex = this.entityService.nextTabIndex;
        }
        else {
            if (this.selectedFiledIndex == -1) { //GD2-694 to do editable and non editable
                this.machineTypeEditable = true;
                this.entityForm.controls[this.selectedFiled['fieldUniqueName']].enable();
            } else {
                this.entityForm.controls[this.selectedFiled['fieldUniqueName']].enable();
                this.filedsJson[this.selectedFiledIndex]['isConfirmationPopup'] = false;
            }
        }
    }

    onConfirmValidationReject(type: string = '') {
        if (type == 'viewMachines') {
            this.viewMachines(false);
        }
        else {//[GD2-3784]
            if (this.screenType == "edit" && Object.keys(this.moveToNextEntityDetails).length) {//[GD2-3694]
                this.viewParentEntityDetails(this.moveToNextEntityDetails);
            }
            else if (this.nextRoute != undefined && this.nextRoute != '' && this.nextRoute != null)//[GD2-3694]
                this.router.navigate([this.nextRoute]);
        }
    }

    resetTradehour(e) {
        this.entityService.tradehours = [];
        e.forEach(tabElement => {
            if (!tabElement['slots'].length) {
                this.entityService.tradehours.push({
                    day: tabElement.day, slots: tabElement.slots,
                    isDayOff: tabElement.isDayOff,
                    isEdit: tabElement.isEdit
                });
            } else {
                let j = 0;
                let slotArr = [];
                tabElement['slots'].forEach(slot => {
                    slotArr.push({ start_time: slot.start_time, end_time: slot.end_time });
                    if (j === (tabElement['slots'].length - 1)) {
                        this.entityService.tradehours.push({
                            day: tabElement.day, slots: slotArr,
                            isDayOff: tabElement.isDayOff,
                            isEdit: tabElement.isEdit
                        });
                    }
                    j++;
                });
            }
        });
    }
    movedCancellation() {
      if (this.navigationFromEntityMove) {
            let cancelMoveMessage = "Entity Movement will not be completed unless all mandatory fields have been populated, do you want to proceed?"
            if (this.levelNumber == 7) {
                cancelMoveMessage = "Site Movement will not be completed unless all mandatory fields have been populated, do you want to proceed?";
            }
            this.dialogTemplateData = {
              htmlContent: cancelMoveMessage
            };
            const { key, component } = this.dialogService.openDialog();
            component.instance.title = 'Alert';
            component.instance.primary = 'No';
            component.instance.secondary = 'Yes';
            component.instance.contentTemplate = this.dialogTemplate;
            const dialogSubs = new Subscription();
            dialogSubs.add(
              component.instance.primaryClick.subscribe(() => {
                this.onConfirmAddCancel();
                this.dialogService.closeDialog(key);
                dialogSubs.unsubscribe();
              }),
            );
            dialogSubs.add(
              component.instance.secondaryClick.subscribe(() => {
                this.onConfirmAdd();
                this.dialogService.closeDialog(key);
                dialogSubs.unsubscribe();
              }),
            );
        }
        else if (this.navigationFromMachineMove) {
            const { key, component } = this.dialogService.openDialog();
            component.instance.title = 'Alert';
            component.instance.description = 'Are you sure you wish to cancel machine movement? All previously edited information will be lost.';
            component.instance.primary = 'No';
            component.instance.secondary = 'Yes';
            const dialogSubs = new Subscription();
            dialogSubs.add(
              component.instance.primaryClick.subscribe(() => {
                this.onConfirmAddCancel();
                this.dialogService.closeDialog(key);
                dialogSubs.unsubscribe();
              }),
            );
            dialogSubs.add(
              component.instance.secondaryClick.subscribe(() => {
                this.onConfirmAdd();
                this.dialogService.closeDialog(key);
                dialogSubs.unsubscribe();
              }),
            );
        }
        else {
            return;
        }
    }
    onConfirmAdd() {
        if (Object.keys(this.moveToNextEntityDetails).length) {
            this.removeStorage();
            this.removeMachineStorage();
            this.viewParentEntityDetails(this.moveToNextEntityDetails);
            this.moveToNextEntityDetails = {};
        }
        else {
            if (this.levelNumber == 8 && this.screenType == "move") {
                this.canLeaveMoveScreenType = true;
                this.removeMachineStorage();
                if (this.nextRoute) {
                    this.resetMachineDetailsChanges();
                    this.router.navigate([this.nextRoute]);
                } else {
                    this.router.navigate(['/machine']);
                }
            } else {
                this.removeStorage();
                this.removeMachineStorage();
                if (this.nextRoute) {
                    this.router.navigate([this.nextRoute]);
                } else {
                    this.router.navigate(['/entity']);
                }
            }
        }
    }
    onConfirmAddCancel() {
        this.moveToNextEntityDetails = {};
    }
    removeStorage() {
        localStorage.removeItem("selectedEntityModel");
        localStorage.removeItem("moveEntityModel");
    }
    removeMachineStorage() {
        localStorage.removeItem("selectedMachineModel");
        localStorage.removeItem("moveMachineModel");
    }
    onConfirmOk() {
        localStorage.removeItem('isOpenedConfirmationPopup')
        this.entityForm.markAllAsTouched();
        // GD2-1715 AC 6
        this.toaster.open({
            text: 'If you use the reference field you may need to update this to a new reference',
            type: 'info',
            position: 'top-right',
            duration: 20000
        });

        // GD2-1715 AC 7
        if ((this.levelNumber == 4 && localStorage.getItem('moveEntityHasPartnerEntityBillTo') == 'true') || (this.levelNumber == 7 && localStorage.getItem('moveEntityHasSiteEntitySellTo') == 'true')) {
            var infoMessage = '';
            if (this.levelNumber == 4)
                infoMessage = 'Please check the Bill To number as this may need to be updated'
            else if (this.levelNumber == 7)
                infoMessage = 'Please check the Sell To number as this may need to be updated'

            this.toaster.open({
                text: infoMessage,
                type: 'info',
                position: 'top-right',
                duration: 20000
            });
        }
    }

    async canDeactivate(nextRoute): Promise<void> {
        this.nextRoute = nextRoute;
        this.movedCancellation();
    }
    async canMachineDeactivate(nextRoute): Promise<void> {
        this.nextRoute = nextRoute;
        this.movedCancellation();
    }

    requireMatch = (fieldUniqueName, options, isParententity) => {
        if (isParententity) {
            return (control: FormControl) => {
                const selection: any = control.value;
                if (selection != '') {
                    var obj = this.parentEntityList.filter(x => x.masterDataId == selection);
                    if (obj == null || obj.length == 0) {
                        return { requireMatch: true };
                    }
                    else {
                        return null;
                    }
                } else { return null; }
            }
        }
        else {
            return (control: FormControl) => {
                const selection: any = control.value;
                if (selection != '') {
                    var lst = [];
                    if (options[fieldUniqueName] != undefined && options[fieldUniqueName] != null) {
                        options[fieldUniqueName].subscribe(x => {
                            lst = x;
                        })
                        var obj = lst.filter(x => x.masterDataId == selection);
                        if (obj == null || obj.length == 0) {
                            return { requireMatch: true };
                        }
                        else {
                            return null;
                        }
                    } else {
                        return null;
                    }
                } else { return null; }
            }
        }
    }

    saveLevelSetup(isLevelSetUp) {
        if (isLevelSetUp) {
            this.tabIndex = 2;
        } else {
            this.dialogTexts = {
                successTitle: 'Yes',
                cancelTitle: 'No',
                message: 'This is an irreversible action. Are you sure, you want to save the market level setup?',
                deviceName: '',
                status: '',
                title: 'Update Confirmation',
                type: 'level-setup',
                isNext: true
            };
            const { key, component } = this.dialogService.openDialog();
            component.instance.title = this.dialogTexts.title;
            component.instance.description = this.dialogTexts.message;
            component.instance.primary = 'No';
            component.instance.secondary = 'Yes';
            const dialogSubs = new Subscription();
            dialogSubs.add(
              component.instance.primaryClick.subscribe(() => {
                this.dialogService.closeDialog(key);
                dialogSubs.unsubscribe();
              }),
            );
            dialogSubs.add(
              component.instance.secondaryClick.subscribe(() => {
                this.onConfirm(this.dialogTexts.type, this.dialogTexts.isNext);
                this.dialogService.closeDialog(key);
                dialogSubs.unsubscribe();
              }),
            );
        }
    }

    selectedIndexInfo(selectedTabIndex) {
        this.tabIndex = selectedTabIndex;
    }

    getTradeHoursSetup(hasTradeHoursSetup) {
        this.hasTradeHoursSetup = hasTradeHoursSetup;
    }

    editScreen() {
        if (this.levelNumber != 8)
            this.router.navigate(['/entity/', this.entityId], { queryParams: { screenType: 'edit', parentEntityId: this.parentEntityId, tabIndex: this.tabIndex, nextLevelNumber: this.levelNumber, marketId: this.marketId, parentlevelNumber: this.parentlevelNumber } });
        else
            this.router.navigate(['/machine/', this.entityId], { queryParams: { nextLevelNumber: this.levelNumber, screenType: 'edit', parentlevelNumber: this.parentlevelNumber, tabIndex: this.tabIndex, parentEntityId: this.parentEntityId, marketId: this.marketId } });
        this.ngOnInit();
    }
    onConfirmSuspend() {
        this.entityForm.controls["machineTargetWorkingStatus"].patchValue("Suspended");
        this.add(this.fromSuspendRoute, true);
    }

    onConfirmCancelSuspendReason() {
        this.dropdownDrawer.closeDrawer();
        this.dropdownDrawer.resetSelectedValue();
    }

    onConfirmResumeMachine() {
        this.machineService.resumeMachine(this.machineId).subscribe(response => {
            window.location.reload();
        }, err => {
            this.toaster.open({
                text: "Something wrong happened while trying to resume the machine.",
                type: 'danger',
                position: 'top-right',
                duration: 10000
            });
        });
    }

    getmachineDetails() {
        this.spinner.show();
        this.machineService.getMachineDetailsWithForm(this.machineId, this.parentEntityId).subscribe(response => {
            this.marketDetails = response['data'];
        });
    }
    isEqualObj(x, y) {
        const ok = Object.keys, tx = typeof x, ty = typeof y;
        return x && y && tx === 'object' && tx === ty ? (
            ok(x).length === ok(y).length &&
            ok(x).every(key => this.isEqualObj(x[key], y[key]))
        ) : (x === y);
    }
    onTabChanged(event, isFromReload = false) {
        if (this.levelNumber == 7){
            this.tabIndex = event.index;
        }
        if (this.unsavedCheck()) { return }
        if (!isFromReload) {
            this.tabIndex = event.index;
        }
        this.isPageDataLoad = false;
        if (this.levelNumber == 8 && this.tabIndex == 4) {
            this.salesDetailList = [];
            this.LogHistoryList = [];
            this.drinkList = [];
            this.priceCards = [];
            this.filter = {
                drinkId: '',
                vendStatus: '',
                priceCardNameNo: '',
                manufactureFromDate: '',
                manufactureToDate: '',
                gridReceivedFromDate: '',
                gridReceivedToDate: '',
                vendFromDate: '',
                vendToDate: '',
                search: ''
            }
            this.tempSalesfilter = {//[GD2-3706]
                drinkId: '',
                vendStatus: '',
                priceCardNameNo: '',
                manufactureFromDate: '',
                manufactureToDate: '',
                gridReceivedFromDate: '',
                gridReceivedToDate: '',
                vendFromDate: '',
                vendToDate: '',
                search: ''
            }

            this.recordsTotal = 0;
            this.pageIndex = 0;
            this.params = {
                pageNumber: 1,
                pageSize: this.pageSizeOptions[0],
                searchText: '',
                sortBy: ''
            };

            this.getLastSalesDate(true);
            this.getDrinkList();
            this.getPriceCardList();
        } else if (this.levelNumber == 8 && this.tabIndex == 5) {
            this.params.sortBy = 'eventDateTime desc'
            this.eventsDetailList = [];
            this.eventNameList = [];
            this.eventDescriptionList = [];
            this.lastTradingHoursList = [];
            this.eventSiteNameList = [];
            this.selectedEventNames = [];
            this.selectedEventDescriptions = [];
            this.selectedEventTypes = [];
            this.recordsTotal = 0;
            this.pageIndex = 0;
            this.params = {
                pageNumber: 1,
                pageSize: this.pageSizeOptions[0],
                searchText: '',
                sortBy: ''
            };

            this.eventfilter = {
                search: '',
                eventNames: [],
                eventDescriptions: [],
                eventTypes: [],
                eventState: 'All',
                currentEventStates: '',
                lostTradingHours: '',
                occuredFromDate: '',
                occuredToDate: '',
                receivedFromDate: '',
                receivedToDate: ''
            }
            this.tempeventfilter = {//[GD2-3706]
                search: '',
                eventNames: [],
                eventDescriptions: [],
                eventTypes: [],
                eventState: 'All',
                currentEventStates: '',
                lostTradingHours: '',
                occuredFromDate: '',
                occuredToDate: '',
                receivedFromDate: '',
                receivedToDate: ''
            }
            this.selectedCurrentEventStates = this.currentEventStatusList;
            this.getLastRefreshEventsDate(true);
            this.getEventDataFilterLookup();
            //this.getLastTradingHoursList();
        } else if (this.levelNumber == 8 && this.tabIndex == 7) {
            this.params.sortBy = 'movementDate desc'
            this.recordsTotal = 0;
            this.pageIndex = 0;
            this.getSiteLogHistory('EntityChangeLog');

            this.params = {
                pageNumber: 1,
                pageSize: this.pageSizeOptions[0],
                searchText: '',
                sortBy: ''
            };
        } else if (this.levelNumber == 8 && this.tabIndex == 6) {
            let that = this;
            $.each(EventFilterTypesEnum, function (key, value) {
                if (Number.isInteger(value)) {
                    that.filterlookups[key] = [];
                }
            });
            that.isFilterApply = false;
            this.alarmFilter.search = '';
            this.alarmClearFilter();
            this.getFilterLookup();
            this.isAlarmPageDataLoad = false;
        }

        // to resolved bug : GD2-2781 // tabIndex add/update into query params of current url which are clicked
        this.activatedRoute.queryParams.subscribe(queryParams => {
            if (queryParams['tabIndex'] && queryParams['tabIndex'] != this.tabIndex) {
                let queryObj = {};
                for (var key in queryParams) {
                    queryObj[key] = queryParams[key];
                }
                queryObj['tabIndex'] = this.tabIndex;
                this.router.navigate(
                    [],
                    {
                        relativeTo: this.activatedRoute,
                        queryParams: queryObj,
                        queryParamsHandling: 'merge'
                    });
            }

        });
    }
    getEventDataFilterLookup() {
        let that = this;
        $.each(MachineEventFilterTypesEnum, function (key, value) {
            if (Number.isInteger(value)) {
                that.filterlookups[key] = [];
                let obj = {};
                obj['machineId'] = that.machineId;
                obj['parentEntityId'] = that.parentEntityId;
                obj['type'] = value;
                that.machineService.getmachineeventdatalookup(obj).subscribe(response => {
                    if (response) {
                        that.filterlookups[key] = response['data'];
                    }
                }, err => {
                    let errors = err.error;
                    if (Array.isArray(errors)) {
                        errors.forEach(element => {
                            that.roleService.showToasterMsg(element.msg, 'danger');
                        });
                    }
                });
            }
        });
    }

    //--alarm tab functions --//
    createLookupFromEnum() {
        let that = this;
        $.each(AlarmEventTypesEnum, function (key, value) {
            if (Number.isInteger(value)) {
                that.alarmEventTypesList.push({ value: value, text: key });
            }
        });
        $.each(AlarmEventCauseLostTradingHoursEnum, function (key, value) {
            if (Number.isInteger(value)) {
                that.alarmEventCauseLostTradingHoursList.push({ value: value, text: key });
            }
        });
        $.each(AlarmListFilterEscalationLevelsEnum, function (key, value) {
            if (Number.isInteger(value)) {
                that.escalationLevelList.push({ value: value, text: key });
            }
        });
        $.each(AlarmListFilterStatusEnum, function (key, value) {
            if (Number.isInteger(value)) {
                if (key == 'Active') {
                    key = 'Active & Resolved';
                }
                that.alarmStatusList.push({ value: value, text: key });
            }
        });
    }
    getFilterLookup() {
        let that = this;
        $.each(AlarmListFilterTypesEnum, function (key, value) {
            if (Number.isInteger(value)) {
                that.filterlookups[key] = [];
                that.alarmTemplateService.getAlarmFilterLookupByType(value, that.entityId).subscribe(response => {
                    if (response) {
                        that.filterlookups[key] = response['data'];
                    }
                }, err => {
                    let errors = err.error;
                    if (Array.isArray(errors)) {
                        errors.forEach(element => {
                            that.roleService.showToasterMsg(element.msg, 'danger');
                        });
                    }
                });
            }
        });
    }
    alarmClearSearch() {
        this.alarmFilter.search = '';
        this.alarmParams.pageNumber = 1
        this.getAlarmList();
    }
    alarmClearFilter() {
        this.isFilterClear = true;
        this.isFilterApply = false;
        this.alarmParams.pageNumber = 1;
        this.escalationDurationFrom = parseInt(this.userService.defaultUIConfigurations.alarmFilterEscalationRangeMin);
        this.escalationDurationTo = parseInt(this.userService.defaultUIConfigurations.alarmFilterEscalationRangeMax);
        this.alarmFilter['occurrenceFromDate'] = '';
        this.alarmFilter['occurrenceToDate'] = '';
        //[GD2-3706]
        this.tempAlarmFilter['search'] = '';
        this.tempAlarmFilter['occurrenceFromDate'] = '';
        this.tempAlarmFilter['occurrenceToDate'] = '';
        this.tempAlarmFilter['currentEscalationLevel'] = [];
        this.tempAlarmFilter['eventCategoryTypes'] = [];
        this.tempAlarmFilter['alarmStatus'] = [];
        this.tempAlarmFilter['alarmName'] = [];
        this.tempAlarmFilter['machineSerialNumber'] = [];
        this.tempAlarmFilter['causesLostTradingHours'] = [];
        this.tempAlarmFilter['machineModelName'] = [];
        this.tempAlarmFilter['siteName'] = [];
        this.tempAlarmFilter['serviceAgent'] = [];
        this.tempAlarmFilter['countryCode'] = [];
        this.tempAlarmFilter['customerName'] = [];
        this.tempAlarmFilter['machineType'] = [];
        this.tempAlarmFilter['escalationDurationFrom'] = this.escalationDurationFrom;
        this.tempAlarmFilter['escalationDurationTo'] = this.escalationDurationTo;

        this.getAlarmList(true);
    }
    getAlarmList(fromClear = undefined) {
        if (localStorage.getItem('fromMachineDetailTab') && this.tabIndex == 6 && this.levelNumber == 8)
            this.setAlarmListFilters();
        if (this.alarmFilter['occurrenceFromDate'] && !this.alarmFilter['occurrenceToDate']) {
            this.alarmFilter['occurrenceToDate'] = moment().format('YYYY-MM-DD');
        }
        if (!this.alarmFilter['occurrenceFromDate'] && this.alarmFilter['occurrenceToDate']) {
            this.toaster.open({
                text: "Occurred From Date cannot be empty",
                type: 'danger',
                position: 'top-right',
                duration: 10000
            });
        } else if (this.alarmFilter['occurrenceFromDate'] > this.alarmFilter['occurrenceToDate']) {
            this.toaster.open({
                text: "Occurred To Date must be greater than From Date",
                type: 'danger',
                position: 'top-right',
                duration: 10000
            });
        } else if (this.alarmFilter['occurrenceFromDate'] && moment(this.alarmFilter['occurrenceFromDate']).format('YYYY-MM-DD') < moment(this.alarmMinDate).format('YYYY-MM-DD')) {
            this.toaster.open({
                text: "Occurred From Date must be up to past 3 Months",
                type: 'danger',
                position: 'top-right',
                duration: 10000
            });
        } else {
            this.spinner.show();
            if (!this.isFilterClear) {
                this.isShowFilter = false;
                this.alarmDrawerfilter = false;
            }
            this.isFilterClear = false;

            if (fromClear == undefined)
                this.alarmDrawerfilter = false;

            let obj = {};
            this.isFilterApply = false;

            if (fromClear == "search" || fromClear == "apply" || fromClear == "refresh")
                this.alarmParams.pageNumber = 1

            obj['searchDescription'] = this.alarmFilter.search;
            if (this.alarmParams.sortBy != undefined) { obj['orderByColumn'] = this.alarmParams.sortBy };
            if (this.alarmParams.pageNumber != undefined) { obj['PageNo'] = this.alarmParams.pageNumber };
            if (this.alarmParams.pageSize != undefined) { obj['PageSize'] = this.alarmParams.pageSize };
            if (this.alarmFilter['occurrenceFromDate']) {
                obj['occurrenceFromDate'] = moment(this.alarmFilter['occurrenceFromDate']).format('YYYY-MM-DD'); obj['occurrenceToDate'] = moment(this.alarmFilter['occurrenceToDate']).format('YYYY-MM-DD');
                this.isFilterApply = true;
            } else {
                obj['occurrenceFromDate'] = moment().subtract(this.userService.defaultUIConfigurations.alarmFilterOccurrenceDateMinInDays, 'days').format('YYYY-MM-DD');
                obj['occurrenceToDate'] = moment().format('YYYY-MM-DD');
            }

            obj['escalationDurationFrom'] = this.escalationDurationFrom;
            obj['escalationDurationTo'] = this.escalationDurationTo;
            if (this.escalationDurationFrom != parseInt(this.userService.defaultUIConfigurations.alarmFilterEscalationRangeMin) || this.escalationDurationTo != parseInt(this.userService.defaultUIConfigurations.alarmFilterEscalationRangeMax)) {
                this.isFilterApply = true;
            }
            let filters = ['currentEscalationLevel', 'eventCategoryTypes', 'alarmStatus', 'alarmName', 'causesLostTradingHours', 'machineModelName', 'siteName', 'serviceAgent', 'countryCode', 'customerName', 'machineType'];
            let that = this;
            filters.forEach(key => {
                if (fromClear == true) {
                    that.alarmFilter[key] = [];
                }
                if (that.alarmFilter[key] && that.alarmFilter[key].length) {
                    let ids = [];
                    that.alarmFilter[key].forEach(e => {
                        if (e.value)
                            ids.push(e.value);
                    })
                    if (ids.length) {
                        if (key == 'alarmStatus' && ids[0] == AlarmListFilterStatusEnum.Active) {
                            obj[key] = [AlarmListFilterStatusEnum.Active, AlarmListFilterStatusEnum.Resolved]
                        } else {
                            obj[key] = ids;
                        }
                        that.isFilterApply = true;
                    }
                } else if (key == 'alarmStatus') {
                    obj[key] = [AlarmListFilterStatusEnum.Active]
                }
            });
            obj['machineId'] = this.entityId;
            obj['isGlobalRequest'] = false;
            this.alarmTemplateService.getAlarmList(obj).subscribe(response => {
                this.exportFilter = obj;
                this.isAlarmPageDataLoad = true;
                this.recordsTotal = response?.params?.count;
                this.alarmTemplateService.resetMachineAlarmFilters();
                this.spinner.hide();
                if (response && response['data'].length) {
                    this.alarmList = response['data']
                } else {
                    this.alarmList = [];
                }
                if (fromClear == "apply") {
                    this.alarmDrawerfilter = false;
                    $(".sidebar-mini").removeClass("filter-open");
                    this.roleService.resetPopupScroll();
                }
            }, err => {
                this.isAlarmPageDataLoad = true;
                this.spinner.hide();
                this.alarmList = [];
                let errors = err.error;
                if (Array.isArray(errors)) {
                    errors.forEach(element => {
                        this.roleService.showToasterMsg(element.msg, 'danger');
                    });
                }
            });
        }
    }
    export(filename: string, base64: string, contentType: string) {
        var filetype = contentType;
        let byteCharacters = atob(base64);
        let byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        this.blob = new Blob([byteArray], { type: filetype });
        FileSaver.saveAs(this.blob, filename);
        $('.ajax-investmentUpload-request').hide();
    }
    clickToExport() {
        this.spinner.show();
        this.alarmTemplateService.exportAlrarmData(this.exportFilter).subscribe(response => {
            if (response != null) {
                this.export(response.fileDownloadName, response.fileContents, response.contentType);
            }
            this.spinner.hide();
        }, err => {
            this.spinner.hide();
            let errors = err.error;
            errors.forEach(element => {
                this.toaster.open({
                    text: element.msg,
                    type: 'danger',
                    position: 'top-right',
                    duration: 10000
                });
            });
        });
    }

    applyAlarmFilter() {//[GD2-3706]
        this.assignFilterToTempFilter();
        this.getAlarmList('apply');
    }

    assignFilterToTempFilter() {//[GD2-3706]
        this.filterAlarmColumns.forEach(key => {
            this.tempAlarmFilter[key] = this.alarmFilter[key];
            if (key == 'escalationDurationFrom' && this.escalationDurationFrom != this.alarmFilter[key]) {
                this.tempAlarmFilter['escalationDurationFrom'] = this.escalationDurationFrom;
            }
            if (key == 'escalationDurationTo' && this.escalationDurationTo != this.alarmFilter[key]) {
                this.tempAlarmFilter['escalationDurationTo'] = this.escalationDurationTo;
            }
        });
    }
    alarmChangefilter() {
        this.roleService.setPopupScroll('section-alarmfilter');
        if (!this.alarmDrawerfilter) {
            $(".sidebar-mini").addClass("filter-open");
        }
        else {
            this.filterAlarmColumns.forEach(key => {//[GD2-3706]
                this.alarmFilter[key] = this.tempAlarmFilter[key];
                if (key == 'escalationDurationFrom' && this.escalationDurationFrom != this.tempAlarmFilter[key]) {
                    this.escalationDurationFrom = parseInt(this.userService.defaultUIConfigurations.alarmFilterEscalationRangeMin);
                    this.alarmFilter['escalationDurationFrom'] = this.escalationDurationFrom;
                }
                if (key == 'escalationDurationTo' && this.escalationDurationTo != this.tempAlarmFilter[key]) {
                    this.escalationDurationTo = parseInt(this.userService.defaultUIConfigurations.alarmFilterEscalationRangeMax);
                    this.alarmFilter['escalationDurationTo'] = this.escalationDurationTo;
                }
            });
            $(".sidebar-mini").removeClass("filter-open");
            this.roleService.resetPopupScroll();
        }
        setTimeout(() => {
            this.alarmDrawerfilter = !this.alarmDrawerfilter;
        }, this.roleService.scrollSetTimeoutTime);
    }
    alarmSortData(e) {
        e.direction = (e.active == 'isActive' && e.direction != '' ? (e.direction == 'asc' ? 'desc' : 'asc') : e.direction);
        this.alarmParams.sortBy = (e.active != '' && e.direction != '' ? e.active + ' ' + e.direction : (e.active != '' && e.direction == '') ? e.active + ' asc' : 'cells_name asc');
        this.alarmParams.pageNumber = 1;
        this.getAlarmList();
    }
    alarmPageChange(e) {
        this.alarmParams.pageNumber = (e.pageIndex > 0 ? (e.pageIndex + 1) : 1);
        this.alarmParams.pageSize = (e.pageSize > 0 ? e.pageSize : this.pageSizeOptions[0]);
        this.getAlarmList();
    }
    //-- alarm tab function --//

    getLastSalesDate(fromClear) {
        this.spinner.show();
        this.machineSerialNo = '';
        this.uniqueMachineNumber = '';
        this.machineType = '';
        this.salesService.getMachineLastSalesDate(this.machineId).subscribe(response => {
            let resDate = moment(response.lastSalesDate).format('YYYY-MM-DD');

            this.salesInfo.salesReceivedDate = response.lastSalesDate;
            this.salesInfo.machineSerialNo = response.machineSerialNo;
            this.salesInfo.uniqueMachineNumber = response.uniqueMachineNumber;
            this.salesInfo.machineType = response.machineType;
            this.selectedPropositionType = response.propositionType;//[GD2-2876]

            if (response.lastSalesDate && response.lastSalesDate.charAt(response.lastSalesDate.length - 1).toLowerCase() != 'z') {
                response.lastSalesDate = response.lastSalesDate + 'z';
            }
            if (!this.salesInfo.salesReceivedDate) {
                let currentDate = moment();
                this.filter.gridReceivedToDate = moment(currentDate).utcOffset(0);
                this.filter.gridReceivedToDate = moment(this.filter.gridReceivedToDate).startOf('day').utcOffset(0);
                this.filter.gridReceivedFromDate = moment(this.filter.gridReceivedToDate).subtract(1, 'days');
                this.tempSalesfilter.gridReceivedFromDate = this.filter.gridReceivedFromDate;//[GD2-3706]
                this.tempSalesfilter.gridReceivedToDate = this.filter.gridReceivedToDate;//[GD2-3706]

            } else {
                this.filter.gridReceivedFromDate = moment(resDate).subtract(1, 'days').format('YYYY-MM-DD');
                this.filter.gridReceivedToDate = moment(resDate).format('YYYY-MM-DD');
                this.tempSalesfilter.gridReceivedFromDate = this.filter.gridReceivedFromDate;//[GD2-3706]
                this.tempSalesfilter.gridReceivedToDate = this.filter.gridReceivedToDate;//[GD2-3706]

                //Vend Datetime filter
                let resDateLocalDateTime: any = moment(moment(response.localLastSalesDate).format('YYYY-MM-DD'));
                //If time zone offset is greater then UTC so. need to handle corner cases
                if (resDateLocalDateTime > this.maxDateVendDateTime) {
                    this.salesMinDateVendDateTime = moment(resDateLocalDateTime).subtract(this.userService.defaultUIConfigurations.machineSalesFilterDateMinInMonths, 'months');
                    this.maxDateVendDateTime = resDateLocalDateTime;
                }

                //Manufacturer Datetime filter
                //If time zone offset is greater then UTC so. need to handle corner cases
                if (resDateLocalDateTime > this.maxDateManufacturerReceivedDateTime) {
                    this.salesMinDateManufacturerReceivedDateTime = moment(resDateLocalDateTime).subtract(this.userService.defaultUIConfigurations.machineSalesFilterDateMinInMonths, 'months');
                    this.maxDateManufacturerReceivedDateTime = resDateLocalDateTime;
                }
            }
            this.getSalesDetailList(fromClear);
        });
    }


    // Sales details api
    getDrinkList() {
        this.priceCardTemplateService.getMasterLookup(MasterLookupEnum.Drink).subscribe(response => {
            if (response && response['data'].length) {
                this.drinkList = response['data'];
            }
        });
    }

    getMilkList() {
        this.priceCardTemplateService.getMasterLookup(MasterLookupEnum.Milk).subscribe(response => {
            if (response && response['data'].length) {
                this.milkList = response['data'];
            }
        });
    }

    getCoffeeList() {
      this.priceCardTemplateService.getMasterLookup(MasterLookupEnum.Coffee).subscribe(response => {
        if (response && response['data'].length) {
          this.coffeeList = response['data'];
        }
      });
    }

    formatCoffeeCode(code): string {
      let value = code;
      this.coffeeList.forEach(element => {
        if (element.value === code) {
          value = `${element.text} - ${code}`;
        }
      });
      return value;
    }

    formatMilkCode(code): string {
      let value = code;
      this.milkList.forEach(element => {
        if (element.value === code) {
          value = `${element.text} - ${code}`;
        }
      });
      return value;
    }

    formatCupVolume(code): string {
      return `${code} oz`;
    }

    formatHasMilk(code): string {
      return code === 'True' ? 'Yes' : 'No';
    }

    getPriceCardList() {
        this.priceCardService.getPriceCardNameLookup().subscribe(response => {
            if (response && response['data'].length) {
                this.priceCards = response['data'];
            }
        });
    }
    applySalesFilter() {//[GD2-3706]
        this.assignSalesFilterToTempFilter();
        this.getSalesDetailList('apply');
    }

    assignSalesFilterToTempFilter() {//[GD2-3706]
        this.filterSalesColumns.forEach(key => {
            this.tempSalesfilter[key] = this.filter[key];
        });
    }

    changeSalesfilter() {
        this.roleService.setPopupScroll('section-sales-filter');
        if (!this.drawerfilter) {
            $(".sidebar-mini").addClass("filter-open");
        }
        else {
            this.filterSalesColumns.forEach(key => {//[GD2-3706]
                this.filter[key] = this.tempSalesfilter[key];
            });
            $(".sidebar-mini").removeClass("filter-open");
            this.roleService.resetPopupScroll()
        }
        setTimeout(() => {
            this.drawerfilter = !this.drawerfilter;

        }, this.roleService.scrollSetTimeoutTime);
    }

    clickFilter(val) {
        this.isShowFilter = val;

    }
    clearSalesFilter() {

        this.spinner.show();
        this.isFilterClear = true;
        this.isFilterApply = false;
        let fromClear = true;
        this.params.pageNumber = 1
        this.filter = {
            search: this.filter.search,
            priceCardNameNo: '',
            drinkId: '',
            vendStatus: '',
            manufactureFromDate: '',
            manufactureToDate: '',
            gridReceivedFromDate: '',
            gridReceivedToDate: '',
            vendFromDate: '',
            vendToDate: ''
        }
        this.assignSalesFilterToTempFilter();//[GD2-3706]
        this.getLastSalesDate(fromClear);
    }
    clearSearch() {
        this.filter.search = '';
        this.eventfilter.search = '';
        this.tempeventfilter.search = '';//[GD2-3706]
        this.params.pageNumber = 1
        if (this.tabIndex == 4) {
            this.getSalesDetailList();
        }
        else if (this.tabIndex == 5) {
            this.getEventsDetailList();
        }
    }
    closeSalesDetails() {
        this.roleService.setPopupScroll('section-entity-filter');

        $(".sidebar-mini").removeClass("filter-open");
        this.roleService.resetPopupScroll();

        setTimeout(() => {
            this.isOpenSalesDetails = false;

        }, this.roleService.scrollSetTimeoutTime);

    }
    sortData(e) {
        e.direction = (e.active == 'isActive' && e.direction != '' ? (e.direction == 'asc' ? 'desc' : 'asc') : e.direction);
        this.params.sortBy = (e.active != '' && e.direction != '' ? e.active + ' ' + e.direction : (e.active != '' && e.direction == '') ? e.active + ' asc' : 'cells_name asc');
        this.params.pageNumber = 1;
        if (this.tabIndex == 4) {
            this.getSalesDetailList();
        }
        else if (this.tabIndex == 5) {
            this.getEventsDetailList();
        }
        else if (this.tabIndex == 7) {
            this.getSiteLogHistory('EntityChangeLog');
        }
    }
    pageChange(e) {
        this.params.pageNumber = (e.pageIndex > 0 ? (e.pageIndex + 1) : 1);
        this.params.pageSize = (e.pageSize > 0 ? e.pageSize : this.pageSizeOptions[0]);
        if (this.tabIndex == 4) {
            this.getSalesDetailList();
        }
        else if (this.tabIndex == 5) {
            this.getEventsDetailList();
        }
        else if (this.tabIndex == 7) {
            this.getSiteLogHistory('EntityChangeLog');
        }
    }
    getSiteLogHistory(type, isSpinnerShow = true) {
        if (isSpinnerShow)
            this.spinner.show();
        let obj = {};
        obj['machineId'] = this.machineId;

        if (type == 'EntityChangeLog') {
            if (this.params.pageNumber != undefined) { obj['PageNo'] = this.params.pageNumber };
            if (this.params.pageSize != undefined) { obj['PageSize'] = this.params.pageSize };
            if (this.params.sortBy != undefined) { obj['OrderByColumn'] = this.params.sortBy };
        }

        this.SalesService.getSiteLogs(obj).subscribe(response => {
            if (isSpinnerShow) {
                this.spinner.hide();
                this.isPageDataLoad = true;
            }
            this.recordsTotal = response?.params?.count;
            if (response && response['data']) {
                this.LogHistoryList = response['data'];
            } else {
                this.LogHistoryList = [];
            }
        }, err => {
            let errors = err.errors == undefined ? err.error : err.errors;
            errors.forEach(element => {
                this.toaster.open({
                    text: element.msg,
                    type: 'danger',
                    position: 'top-right',
                    duration: 10000
                });
            });
        });
    }
    getSalesDetails(id) {
        this.spinner.show();
        this.roleService.setPopupScroll('section-sales-details');
        setTimeout(() => {
            this.salesDetails = {};
            this.isOpenSalesDetails = true;
            this.SalesService.getSalseDetails(id, this.machineId).subscribe(response => {
                this.spinner.hide();
                if (response && response['data']) {
                    this.salesDetails = response['data'];
                    $(".sidebar-mini").addClass("filter-open");
                }
            }, err => {
                this.spinner.hide();
                this.isOpenSalesDetails = true;
                let errors = err.errors == undefined ? err.error : err.errors;
                errors.forEach(element => {
                    this.toaster.open({
                        text: element.msg,
                        type: 'danger',
                        position: 'top-right',
                        duration: 10000
                    });
                });
            });
        }, this.roleService.scrollSetTimeoutTime);
    }
    getSalesDetailList(fromClear = undefined) {
        if (
            !this.filter.vendFromDate &&
            !this.filter.vendToDate &&
            !this.filter.gridReceivedFromDate &&
            !this.filter.gridReceivedToDate &&
            !this.filter.manufactureFromDate &&
            !this.filter.manufactureToDate
        ) {
            this.spinner.show();
            this.getLastSalesDate("apply");
        } else {
            if (this.filter.vendFromDate && !this.filter.vendToDate) {
                this.filter.vendToDate = moment();
            }
            if (this.filter.gridReceivedFromDate && !this.filter.gridReceivedToDate) {
                this.filter.gridReceivedToDate = moment();
            }
            if (this.filter.manufactureFromDate && !this.filter.manufactureToDate) {
                this.filter.manufactureToDate = moment();
            }
            if (!this.filter.vendFromDate && this.filter.vendToDate) {
                this.toaster.open({
                    text: "Vend from date cannot be empty",
                    type: "danger",
                    position: "top-right",
                    duration: 10000,
                });
                this.spinner.hide();
            } else if (!this.filter.manufactureFromDate && this.filter.manufactureToDate) {
                this.toaster.open({
                    text: "Manufacturer Received from date cannot be empty",
                    type: "danger",
                    position: "top-right",
                    duration: 10000,
                });
                this.spinner.hide();
            } else if (!this.filter.gridReceivedFromDate && this.filter.gridReceivedToDate) {
                this.toaster.open({
                    text: "Sales Received in Grid from date cannot be empty",
                    type: "danger",
                    position: "top-right",
                    duration: 10000,
                });
                this.spinner.hide();
            } else if (this.filter.vendFromDate > this.filter.vendToDate) {
                this.toaster.open({
                    text: "Vend to date must be greater than from date",
                    type: "danger",
                    position: "top-right",
                    duration: 10000,
                });
                this.spinner.hide();
            } else if (this.filter.manufactureFromDate > this.filter.manufactureToDate) {
                this.toaster.open({
                    text: "Manufacturer Received to date must be greater than from date",
                    type: "danger",
                    position: "top-right",
                    duration: 10000,
                });
                this.spinner.hide();
            } else if (this.filter.gridReceivedFromDate > this.filter.gridReceivedToDate) {
                this.toaster.open({
                    text: "Sales Received in Grid to date must be greater than from date",
                    type: "danger",
                    position: "top-right",
                    duration: 10000,
                });
                this.spinner.hide();
            } else if (this.filter.vendFromDate && this.filter.vendFromDate < this.salesMinDateVendDateTime) {
                this.toaster.open({
                    text: "Vend from date must be up to past " + this.userService.defaultUIConfigurations.machineSalesFilterDateMinInMonths + " Months",
                    type: "danger",
                    position: "top-right",
                    duration: 10000,
                });
                this.spinner.hide();
            } else if (this.filter.manufactureFromDate && this.filter.manufactureFromDate < this.salesMinDateManufacturerReceivedDateTime) {
                this.toaster.open({
                    text: "Manufacturer Received from date must be up to past " + this.userService.defaultUIConfigurations.machineSalesFilterDateMinInMonths + " Months",
                    type: "danger",
                    position: "top-right",
                    duration: 10000,
                });
                this.spinner.hide();
            } else if (this.filter.gridReceivedFromDate && this.filter.gridReceivedFromDate < this.salesMinDate) {
                this.toaster.open({
                    text: "Sales Received in Grid from date must be up to past " + this.userService.defaultUIConfigurations.machineSalesFilterDateMinInMonths + " Months",
                    type: "danger",
                    position: "top-right",
                    duration: 10000,
                });
                this.spinner.hide();
            } else {
                this.spinner.show();
                if (!this.isFilterClear) {
                    this.isShowFilter = false;
                }
                this.isFilterClear = false;
                if (fromClear == "apply") {
                    this.changeSalesfilter();
                }
                if (fromClear == undefined) this.drawerfilter = false;
                if (fromClear == "search" || fromClear == "apply" || fromClear == "refresh") this.params.pageNumber = 1;
                let obj = {};
                this.isFilterApply = false;
                obj["machineId"] = this.machineId;
                if (this.filter.search != "") {
                    obj["SearchText"] = this.filter.search;
                }
                if (this.params.pageNumber != undefined) {
                    obj["PageNo"] = this.params.pageNumber;
                }
                if (this.params.pageSize != undefined) {
                    obj["PageSize"] = this.params.pageSize;
                }
                if (this.params.sortBy != undefined) {
                    obj["OrderByColumn"] = this.params.sortBy;
                }
                if (this.filter.drinkId != "" && this.filter.drinkId != undefined) {
                    obj["drinkId"] = this.filter.drinkId;
                    this.isFilterApply = true;
                }
                if (this.filter.priceCardNameNo != "" && this.filter.priceCardNameNo != undefined) {
                    obj["priceCardNameNo"] = this.filter.priceCardNameNo;
                    this.isFilterApply = true;
                }
                if (this.filter.vendStatus != "" && this.filter.vendStatus != undefined) {
                    obj["vendStatus"] = this.filter.vendStatus;
                    this.isFilterApply = true;
                }
                if (this.filter.vendFromDate != "" && this.filter.vendFromDate != undefined) {
                    obj["vendedFromDate"] = this.filter.vendFromDate;
                    this.isFilterApply = true;
                }
                if (this.filter.vendToDate != "" && this.filter.vendToDate != undefined) {
                    obj["vendedToDate"] = this.filter.vendToDate;
                    this.isFilterApply = true;
                }
                if (this.filter.gridReceivedFromDate != "" && this.filter.gridReceivedFromDate != undefined) {
                    obj["gridFromDate"] = this.filter.gridReceivedFromDate;
                    this.isFilterApply = true;
                }
                if (this.filter.gridReceivedToDate != "" && this.filter.gridReceivedToDate != undefined) {
                    obj["gridToDate"] = this.filter.gridReceivedToDate;
                    this.isFilterApply = true;
                }
                if (this.filter.manufactureFromDate != "" && this.filter.manufactureFromDate != undefined) {
                    obj["manufacturerFromDate"] = this.filter.manufactureFromDate;
                    this.isFilterApply = true;
                }
                if (this.filter.manufactureToDate != "" && this.filter.manufactureToDate != undefined) {
                    obj["manufacturerToDate"] = this.filter.manufactureToDate;
                    this.isFilterApply = true;
                }
                //[GD2-2876]
                if (this.selectedPropositionType.toLowerCase() == PropositionMasterEnum.Capsules.toLowerCase()) {
                    this.displayedColumns = this.displayedColumnsCapsules;
                }
                else if (this.selectedPropositionType.toLowerCase() == PropositionMasterEnum.CoffeeCounter.toLowerCase()) {
                    this.displayedColumns = this.displayedColumnsCounterTop;
                } else if (this.selectedPropositionType.toLowerCase() == PropositionMasterEnum.Autonomous.toLowerCase()) {
                    this.displayedColumns = this.displayedColumnsAutonomous;
                } else if (this.selectedPropositionType.toLowerCase() == PropositionMasterEnum.Concession.toLowerCase()) {
                    this.displayedColumns = this.displayedColumnsConcession;
                }
                this.SalesService.getSalesDetailList(obj).subscribe(
                    (response) => {
                        this.recordsTotal = response?.params?.count;
                        this.spinner.hide();
                        this.isPageDataLoad = true;
                        if (response && response["data"]) {
                            this.salesDetailList = response["data"];
                        } else {
                            this.salesDetailList = [];
                        }
                    },
                    (err) => {
                        let errors = err.errors == undefined ? err.error : err.errors;
                        errors.forEach((element) => {
                            this.toaster.open({
                                text: element.msg,
                                type: "danger",
                                position: "top-right",
                                duration: 10000,
                            });
                        });
                    }
                );
            }
        }
    }

    getEntityStatusLogs() {
        this.hasStatuslogsLoad = false;
        this.spinner.show();
        if (this.entityId) {
            this.levelSetup.marketId = this.entityId;
            this.entityService.getEntityStatusLogs(this.entityId, this.levelNumber).subscribe(response => {
                this.hasStatuslogsLoad = true;
                if (response) {
                    this.entityStatusLogs = response.data;
                } else {
                    this.entityStatusLogs = [];
                }
                this.spinner.hide();
            }, err => {
                this.hasStatuslogsLoad = true;
                this.entityStatusLogs = [];
                this.spinner.hide();
                let errors = err.error;
                errors.forEach(element => {
                    this.toaster.open({
                        text: element.msg,
                        type: 'danger',
                        position: 'top-right',
                        duration: 10000
                    });
                });
            });
        }
    }
    clickStatusLogs(type) {
        if (type == 'open') {
            this.roleService.setPopupScroll('section-status-logs');
            $(".sidebar-mini").addClass("filter-open");
            setTimeout(() => {
                this.isOpenStatusLogs = true;
                this.getEntityStatusLogs();
            }, this.roleService.scrollSetTimeoutTime);
        } else {
            this.roleService.setPopupScroll('section-status-logs');
            $(".sidebar-mini").removeClass("filter-open");
            this.roleService.resetPopupScroll()
            setTimeout(() => {
                this.isOpenStatusLogs = false;

            }, this.roleService.scrollSetTimeoutTime);


        }
    }

    // Event Detils Apis

    selectAllEventType(type = '') {
        this.selectedEventTypes = [];
        this.spinner.show();
        if (type == 'selectAll') {
            this.selectedEventTypes = this.eventTypeList;
        } else if (type == 'deSelectAll') {
            this.selectedEventTypes = [];
        }
        this.spinner.hide();
    }

    selectAllCurrentEventStateType(type = '') {
        this.selectedCurrentEventStates = [];
        this.spinner.show();
        if (type == 'All') {
            this.selectedCurrentEventStates = this.currentEventStatusList;
        } else if (type == 'deSelectAll') {
            this.selectedCurrentEventStates = [];
        }
        this.spinner.hide();
    }
    applyEventDataFilter() {//[GD2-3706]
        this.assignEventFilterToTempFilter();
        this.getEventsDetailList('apply');
    }

    assignEventFilterToTempFilter() {//[GD2-3706]
        this.eventfilter.eventNames = [];
        this.eventfilter.eventDescriptions = [];
        this.eventfilter.currentEventStates = [];
        this.eventfilter.eventTypes = [];
        this.filterEventDataColumns.forEach(key => {
            if (key == 'eventNames') {
                if (this.selectedEventNames.length > 0) {
                    this.selectedEventNames.forEach(element => {
                        if (!this.eventfilter.eventNames.includes(element['eventValue']))
                            this.eventfilter.eventNames.push(element['eventValue'])
                    });
                };
            }
            if (key == 'eventDescriptions') {
                if (this.selectedEventDescriptions.length > 0) {
                    this.selectedEventDescriptions.forEach(element => {
                        if (!this.eventfilter.eventDescriptions.includes(element['eventValue']))
                            this.eventfilter.eventDescriptions.push(element['eventValue'])
                    });
                };
            }
            if (key == 'currentEventStates') {
                if (this.selectedCurrentEventStates.length > 0) {
                    this.eventfilter.currentEventStates = [];
                    this.selectedCurrentEventStates.forEach(element => {
                        if (!this.eventfilter.currentEventStates.includes(element['currentEventState']))
                            this.eventfilter.currentEventStates.push(element['currentEventState'])
                    });
                };
            }
            if (key == 'eventTypes') {
                if (this.selectedEventTypes.length > 0) {
                    this.selectedEventTypes.forEach(element => {
                        if (!this.eventfilter.eventTypes.includes(element['eventValue']))
                            this.eventfilter.eventTypes.push(element['eventValue'])
                    });
                };
            }
            this.tempeventfilter[key] = this.eventfilter[key];
        });
    }
    changeEventsfilter() {
        this.roleService.setPopupScroll('section-events-filter');
        if (!this.eventdrawerfilter) {
            $(".sidebar-mini").addClass("filter-open");
        }
        else {
            this.filterEventDataColumns.forEach(key => {//[GD2-3706]
                this.eventfilter[key] = this.tempeventfilter[key];
                if (key == "eventNames") { this.selectedEventNames = ((this.eventfilter[key].length) ? this.eventfilter[key].map(x => ({ eventValue: x, eventText: x })) : []); }
                if (key == "eventDescriptions") { this.selectedEventDescriptions = ((this.eventfilter[key].length) ? this.eventfilter[key].map(x => ({ eventValue: x, eventText: x })) : []); }
                if (key == "eventTypes") { this.selectedEventTypes = ((this.eventfilter[key].length) ? this.eventfilter[key].map(x => ({ eventValue: x, eventText: x })) : []); }
                if (key == "currentEventStates") { this.selectedCurrentEventStates = ((this.eventfilter[key].length) ? this.eventfilter[key].map(x => ({ currentEventState: x })) : this.currentList); }
            });
            $(".sidebar-mini").removeClass("filter-open");
            this.roleService.resetPopupScroll()
        }
        setTimeout(() => {
            this.eventdrawerfilter = !this.eventdrawerfilter;

        }, this.roleService.scrollSetTimeoutTime);
    }

    clearEventsFilter() {
        this.spinner.show();
        this.isFilterClear = true;
        this.isFilterApply = false;
        let fromClear = true;
        this.selectedEventNames = [];
        this.selectedEventDescriptions = [];
        this.selectedEventTypes = [];
        this.params.pageNumber = 1
        this.selectedCurrentEventStates = this.currentList;
        this.eventfilter = {
            search: this.eventfilter.search,
            eventNames: [] = [],
            eventDescriptions: [] = [],
            eventTypes: [] = [],
            eventState: 'All',
            lostTradingHours: '',
            currentEventStates: [] = this.currentList,
            occuredFromDate: '',
            occuredToDate: '',
            receivedFromDate: '',
            receivedToDate: ''
        }
        this.assignEventFilterToTempFilter();//[GD2-3706]
        this.getLastRefreshEventsDate(fromClear);
    }

    getLastRefreshEventsDate(fromClear) {
        this.spinner.show();
        this.machineSerialNo = '';
        this.uniqueMachineNumber = '';
        this.machineType = '';
        let obj = {};
        obj['machineId'] = this.machineId;
        obj['parentEntityId'] = this.parentEntityId;
        this.eventsService.getEventLastRefreshDate(obj).subscribe(response => {
            let resDate = moment(response.lastRefreshedDate).format('YYYY-MM-DD');

            this.eventInfo.eventRefreshDate = response.lastRefreshedDate;
            this.eventInfo.uniqueMachineNumber = response.uniqueMachineNumber;
            this.eventInfo.machineType = response.machineType;

            if (response.lastRefreshedDate && response.lastRefreshedDate.charAt(response.lastRefreshedDate.length - 1).toLowerCase() != 'z') {
                response.lastRefreshedDate = response.lastRefreshedDate + 'z';
            }
            if (!this.eventInfo.eventRefreshDate) {
                let currentDate = moment();
                this.eventfilter.receivedToDate = moment(currentDate).utcOffset(0);
                this.eventfilter.receivedToDate = moment(this.eventfilter.receivedToDate).startOf('day').utcOffset(0);
                this.eventfilter.receivedFromDate = moment(this.eventfilter.receivedToDate).subtract(1, 'days');
                this.tempeventfilter.receivedFromDate = this.eventfilter.receivedFromDate;//[GD2-3706]
                this.tempeventfilter.receivedToDate = this.eventfilter.receivedToDate;//[GD2-3706]

            } else {
                this.eventfilter.receivedFromDate = moment(resDate).subtract(1, 'days').format('YYYY-MM-DD');
                this.eventfilter.receivedToDate = moment(resDate).format('YYYY-MM-DD');
                this.tempeventfilter.receivedFromDate = this.eventfilter.receivedFromDate;//[GD2-3706]
                this.tempeventfilter.receivedToDate = this.eventfilter.receivedToDate;//[GD2-3706]

                //Occured Datetime filter
                let resDateOccuredDateTime: any = moment(moment(response.localLastRefreshedDate).format('YYYY-MM-DD'));
                //If time zone offset is greater then UTC so. need to handle corner cases
                if (resDateOccuredDateTime > this.maxDateOccuredDateTime) {
                    this.eventMinDateOccuredDateTime = moment(resDateOccuredDateTime).subtract(this.userService.defaultUIConfigurations.eventDataFilterDateMinInMonths, 'months');
                    this.maxDateOccuredDateTime = resDateOccuredDateTime;
                }
            }
            this.getEventsDetailList(fromClear);
        });
    }

    getEventsDetailList(fromClear = undefined) {
        if (!this.eventfilter.occuredFromDate && !this.eventfilter.occuredToDate && !this.eventfilter.receivedFromDate && !this.eventfilter.receivedToDate) {
            this.spinner.show();
            this.getLastRefreshEventsDate('apply');
        } else {
            if (this.eventfilter.occuredFromDate && !this.eventfilter.occuredToDate) {
                this.eventfilter.occuredToDate = moment();
            }
            if (this.eventfilter.receivedFromDate && !this.eventfilter.receivedToDate) {
                this.eventfilter.receivedToDate = moment();
            }
            if (this.eventfilter.receivedFromDate && !this.eventfilter.receivedToDate) {
                this.eventfilter.receivedToDate = moment();
            }
            if (!this.eventfilter.occuredFromDate && this.eventfilter.occuredToDate) {
                this.toaster.open({
                    text: "Occurred from date cannot be empty",
                    type: 'danger',
                    position: 'top-right',
                    duration: 10000
                });
                this.spinner.hide();
            } else if (this.eventfilter.occuredFromDate > this.eventfilter.occuredToDate) {
                this.toaster.open({
                    text: "Occurred From Date must precede To Date",
                    type: 'danger',
                    position: 'top-right',
                    duration: 10000
                });
                this.spinner.hide();
            } else if (this.eventfilter.occuredFromDate && this.eventfilter.occuredFromDate < this.eventMinDateOccuredDateTime) {
                this.toaster.open({
                    text: "Occurred From Date must be up to past " + this.userService.defaultUIConfigurations.eventDataFilterDateMinInMonths + " Months",
                    type: 'danger',
                    position: 'top-right',
                    duration: 10000
                });
                this.spinner.hide();
            } else if (this.eventfilter.occuredFromDate && this.eventfilter.occuredToDate && this.eventfilter.occuredToDate.diff(this.eventfilter.occuredFromDate, 'days') > 29) {
                this.toaster.open({
                    text: "Occurred From Date & To Date must not extend beyond 30 days",
                    type: 'danger',
                    position: 'top-right',
                    duration: 10000
                });
                this.spinner.hide();
            } else if (!this.eventfilter.receivedFromDate && this.eventfilter.receivedToDate) {
                this.toaster.open({
                    text: "GRID Received From Date cannot be empty",
                    type: 'danger',
                    position: 'top-right',
                    duration: 10000
                });
                this.spinner.hide();
            } else if (this.eventfilter.receivedFromDate > this.eventfilter.receivedToDate) {
                this.toaster.open({
                    text: "GRID Received From Date must precede To Date",
                    type: 'danger',
                    position: 'top-right',
                    duration: 10000
                });
                this.spinner.hide();
            } else if (this.eventfilter.receivedFromDate && this.eventfilter.receivedFromDate < this.eventMinDateOccuredDateTime) {
                this.toaster.open({
                    text: "GRID Received From Date must be up to past " + this.userService.defaultUIConfigurations.eventDataFilterDateMinInMonths + " Months",
                    type: 'danger',
                    position: 'top-right',
                    duration: 10000
                });
                this.spinner.hide();
            } else if (this.eventfilter.receivedFromDate && this.eventfilter.receivedToDate && moment(this.eventfilter.receivedToDate).diff(moment(this.eventfilter.receivedFromDate), 'days') > 29) {
                this.toaster.open({
                    text: "GRID Received From Date & To Date must not extend beyond 30 days",
                    type: 'danger',
                    position: 'top-right',
                    duration: 10000
                });
                this.spinner.hide();
            } else {
                this.spinner.show();
                if (!this.isFilterClear) {
                    this.isShowFilter = false;
                }
                this.isFilterClear = false;
                if (fromClear == "apply") {
                    this.changeEventsfilter();
                }
                if (fromClear == undefined)
                    this.eventdrawerfilter = false;
                if (fromClear == "search" || fromClear == "apply" || fromClear == "refresh")
                    this.params.pageNumber = 1
                let obj = {};
                this.isFilterApply = false;
                obj['machineId'] = this.machineId;
                if (this.eventfilter.siteName) {
                    obj['siteName'] = [this.eventfilter.siteName];
                }
                obj['parentEntityId'] = this.parentEntityId;
                if (this.eventfilter.search != '') {
                    obj['SearchText'] = this.eventfilter.search;
                };

                if (this.params.pageNumber != undefined) {
                    obj['PageNo'] = this.params.pageNumber
                };

                if (this.params.pageSize != undefined) {
                    obj['PageSize'] = this.params.pageSize
                };

                if (this.params.sortBy != undefined) {
                    obj['OrderByColumn'] = this.params.sortBy
                };

                this.eventfilter.eventNames = [];
                this.eventfilter.eventDescriptions = [];
                this.eventfilter.currentEventStates = [];
                this.eventfilter.eventTypes = [];

                if (this.selectedEventNames.length > 0) {
                    this.selectedEventNames.forEach(element => {
                        if (!this.eventfilter.eventNames.includes(element['eventValue']))
                            this.eventfilter.eventNames.push(element['eventValue'])
                    });
                    obj['eventNames'] = this.eventfilter.eventNames;
                    this.isFilterApply = true;
                };

                if (this.selectedEventDescriptions.length > 0) {
                    this.selectedEventDescriptions.forEach(element => {
                        if (!this.eventfilter.eventDescriptions.includes(element['eventValue']))
                            this.eventfilter.eventDescriptions.push(element['eventValue'])
                    });
                    obj['eventDescriptions'] = this.eventfilter.eventDescriptions;
                    this.isFilterApply = true;
                };

                if (this.selectedCurrentEventStates.length > 0) {
                    this.selectedCurrentEventStates.forEach(element => {
                        if (!this.eventfilter.currentEventStates.includes(element['currentEventState']))
                            this.eventfilter.currentEventStates.push(element['currentEventState'])
                    });
                    obj['currentEventStates'] = this.eventfilter.currentEventStates;
                    this.isFilterApply = true;
                };

                if (this.selectedEventTypes.length > 0) {
                    this.selectedEventTypes.forEach(element => {
                        if (!this.eventfilter.eventTypes.includes(element['eventValue']))
                            this.eventfilter.eventTypes.push(element['eventValue'])
                    });
                    obj['eventTypes'] = this.eventfilter.eventTypes;
                    this.isFilterApply = true;
                };

                if (this.eventfilter.lostTradingHours != '' && this.eventfilter.lostTradingHours != undefined) {
                    obj['lostTradingHours'] = this.eventfilter.lostTradingHours;
                    this.isFilterApply = true;
                }

                if (this.eventfilter.eventState != '' && this.eventfilter.eventState != undefined) {
                    obj['eventState'] = this.eventfilter.eventState;
                    this.isFilterApply = true;
                }

                if (this.eventfilter.occuredFromDate != '' && this.eventfilter.occuredFromDate != undefined) {
                    obj['eventFromDate'] = this.eventfilter.occuredFromDate;
                    this.isFilterApply = true;
                }
                if (this.eventfilter.occuredToDate != '' && this.eventfilter.occuredToDate != undefined) {
                    obj['eventToDate'] = this.eventfilter.occuredToDate;
                    this.isFilterApply = true;
                }


                if (this.eventfilter.receivedFromDate != '' && this.eventfilter.receivedFromDate != undefined) {
                    obj['gridFromDate'] = this.eventfilter.receivedFromDate;
                    this.isFilterApply = true;
                }
                if (this.eventfilter.receivedToDate != '' && this.eventfilter.receivedToDate != undefined) {
                    obj['gridToDate'] = this.eventfilter.receivedToDate;
                    this.isFilterApply = true;
                }

                this.eventsService.getEventsDetailList(obj).subscribe(response => {
                    this.recordsTotal = response?.params?.count;
                    this.spinner.hide();
                    this.isPageDataLoad = true;
                    if (response && response['data']) {
                        this.eventsDetailList = response['data'];

                    } else {
                        this.eventsDetailList = [];
                    }
                }, err => {
                    let errors = err.errors == undefined ? err.error : err.errors;
                    errors.forEach(element => {
                        this.toaster.open({
                            text: element.msg,
                            type: 'danger',
                            position: 'top-right',
                            duration: 10000
                        });
                    });
                });
            }
        }
    }

    closeAlarm(type, element = null) {
        if (type == 'close') {
            // GD2-2434
            this.dialogTexts = {
                successTitle: 'Yes',
                cancelTitle: 'No',
                message: 'Are you sure you wish to close this alarm?',
                deviceName: '',
                status: '',
                title: 'Confirmation',
                type: '',
                isNext: false
            }
            this.closeAlarmId = element['eventAlarmId'];
            this.dialogTemplateData = {
              message: this.dialogTexts.message,
              status: this.dialogTexts.status,
              deviceName: this.dialogTexts.deviceName,
            };
            const { key, component } = this.dialogService.openDialog();
            component.instance.title = 'Confirmation';
            component.instance.primary = 'No';
            component.instance.secondary = 'Yes';
            component.instance.contentTemplate = this.dialogTemplate;
            const dialogSubs = new Subscription();
            dialogSubs.add(
              component.instance.primaryClick.subscribe(() => {
                this.dialogService.closeDialog(key);
                dialogSubs.unsubscribe();
              }),
            );
            dialogSubs.add(
              component.instance.secondaryClick.subscribe(() => {
                this.onCloseAlarmConfirm();
                this.dialogService.closeDialog(key);
                dialogSubs.unsubscribe();
              }),
            );
        }
    }

    onCancelLowAdminSetup() {
      this.isLowAdminActive = this.isLowAdminCurrentState;
    }

    onConfirmLowAdminSetup() {
      this.isLowAdminCurrentState = this.isLowAdminActive;
    }

    onCloseAlarmConfirm() {
        this.spinner.show();
        let obj = {};
        obj['eventAlarmId'] = this.closeAlarmId;
        this.alarmTemplateService.closeAlarm(obj).subscribe(response => {
            if (response != null) {
                this.getAlarmList();
            }
            this.spinner.hide();
        }, err => {
            this.spinner.hide();
            let errors = err.error;
            errors.forEach(element => {
                this.toaster.open({
                    text: element.msg,
                    type: 'danger',
                    position: 'top-right',
                    duration: 10000
                });
            });
        });
    }

    viewAlarmEscalations(element) {
        // store applied alarm list filters
        localStorage.setItem('fromMachineDetailTab', 'true');
        let machineAlarmFilter: any = {};
        machineAlarmFilter['machineId'] = this.machineId;
        machineAlarmFilter['nextLevelNumber'] = this.levelNumber;
        machineAlarmFilter['screenType'] = this.screenType;
        machineAlarmFilter['parentlevelNumber'] = this.parentlevelNumber;
        if (this.alarmFilter.search != null && this.alarmFilter.search != '') {
            machineAlarmFilter['searchDescription'] = this.alarmFilter.search;
        }
        if (this.alarmParams.sortBy != null && this.alarmParams.sortBy != '') {
            machineAlarmFilter['orderByColumn'] = this.alarmParams.sortBy;
        }
        machineAlarmFilter['pageNo'] = this.alarmParams.pageNumber;
        machineAlarmFilter['pageSize'] = this.alarmParams.pageSize;

        if (this.alarmFilter['occurrenceFromDate']) {
            machineAlarmFilter['occurrenceFromDate'] = moment(this.alarmFilter['occurrenceFromDate']).format('YYYY-MM-DD');
        }
        if (this.alarmFilter['occurrenceToDate']) {
            machineAlarmFilter['occurrenceToDate'] = moment(this.alarmFilter['occurrenceToDate']).format('YYYY-MM-DD');
        }

        machineAlarmFilter['escalationDurationFrom'] = this.escalationDurationFrom;
        machineAlarmFilter['escalationDurationTo'] = this.escalationDurationTo;
        machineAlarmFilter['alarmStatus'] = this.alarmFilter.alarmStatus;
        machineAlarmFilter['alarmName'] = this.alarmFilter.alarmName;
        machineAlarmFilter['causesLostTradingHours'] = this.alarmFilter.causesLostTradingHours;
        machineAlarmFilter['machineModelName'] = this.alarmFilter.machineModelName;
        machineAlarmFilter['siteName'] = this.alarmFilter.siteName;
        machineAlarmFilter['serviceAgent'] = this.alarmFilter.serviceAgent;
        machineAlarmFilter['countryCode'] = this.alarmFilter.countryCode;
        machineAlarmFilter['customerName'] = this.alarmFilter.customerName;
        machineAlarmFilter['machineType'] = this.alarmFilter.machineType;
        machineAlarmFilter['eventCategoryTypes'] = this.alarmFilter.eventCategoryTypes;
        machineAlarmFilter['currentEscalationLevel'] = this.alarmFilter.currentEscalationLevel;

        localStorage.setItem('machineAlarmFilter', JSON.stringify(machineAlarmFilter));
        this.router.navigate(['/alarms/', element.eventAlarmId], { queryParams: { tabIndex: 1 } });
    }

    setAlarmListFilters() {
        // apply stored alarm list filters (redirect from alarm escalation details)
        this.defaultAlarmFilter = localStorage.getItem('machineAlarmFilter') ? JSON.parse(localStorage.getItem('machineAlarmFilter')) : {};
        if (Object.keys(this.defaultAlarmFilter).length > 0 && this.defaultAlarmFilter['machineId'] != null && this.defaultAlarmFilter['machineId'] != '') {
            if (this.defaultAlarmFilter['searchDescription'] != null && this.defaultAlarmFilter['searchDescription'] != '')
                this.alarmFilter.search = this.defaultAlarmFilter['searchDescription'];
            if (this.defaultAlarmFilter['orderByColumn'] != null && this.defaultAlarmFilter['orderByColumn'] != '')
                this.alarmParams.sortBy = this.defaultAlarmFilter['orderByColumn'];

            this.alarmParams.pageNumber = this.defaultAlarmFilter['pageNo'];
            this.alarmParams.pageSize = this.defaultAlarmFilter['pageSize'];

            if (this.defaultAlarmFilter['occurrenceFromDate'] != null && this.defaultAlarmFilter['occurrenceFromDate'] != '') {
                this.alarmFilter['occurrenceFromDate'] = this.defaultAlarmFilter['occurrenceFromDate'];
            }
            if (this.defaultAlarmFilter['occurrenceToDate'] != null && this.defaultAlarmFilter['occurrenceToDate'] != '') {
                this.alarmFilter['occurrenceToDate'] = this.defaultAlarmFilter['occurrenceToDate'];
            }
            if (this.defaultAlarmFilter['escalationDurationFrom'] != null) {
                this.alarmFilter['escalationDurationFrom'] = this.defaultAlarmFilter['escalationDurationFrom'];
                this.escalationDurationFrom = this.defaultAlarmFilter['escalationDurationFrom'];
            }
            if (this.defaultAlarmFilter['escalationDurationTo'] != null && this.defaultAlarmFilter['escalationDurationTo'] != '') {
                this.alarmFilter['escalationDurationTo'] = this.defaultAlarmFilter['escalationDurationTo'];
                this.escalationDurationTo = this.defaultAlarmFilter['escalationDurationTo'];
            }
            if (this.defaultAlarmFilter['alarmName'] != null && this.defaultAlarmFilter['alarmName'].length > 0) {
                this.alarmFilter['alarmName'] = this.defaultAlarmFilter['alarmName'];
            }
            if (this.defaultAlarmFilter['alarmStatus'] != null && this.defaultAlarmFilter['alarmStatus'].length > 0)
                this.alarmFilter['alarmStatus'] = this.defaultAlarmFilter['alarmStatus'];
            if (this.defaultAlarmFilter['causesLostTradingHours'] != null && this.defaultAlarmFilter['causesLostTradingHours'].length > 0)
                this.alarmFilter['causesLostTradingHours'] = this.defaultAlarmFilter['causesLostTradingHours'];
            if (this.defaultAlarmFilter['machineModelName'] != null && this.defaultAlarmFilter['machineModelName'].length > 0)
                this.alarmFilter['machineModelName'] = this.defaultAlarmFilter['machineModelName'];
            if (this.defaultAlarmFilter['siteName'] != null && this.defaultAlarmFilter['siteName'].length > 0)
                this.alarmFilter['siteName'] = this.defaultAlarmFilter['siteName'];
            if (this.defaultAlarmFilter['serviceAgent'] != null && this.defaultAlarmFilter['serviceAgent'].length > 0)
                this.alarmFilter['serviceAgent'] = this.defaultAlarmFilter['serviceAgent'];
            if (this.defaultAlarmFilter['countryCode'] != null && this.defaultAlarmFilter['countryCode'].length > 0)
                this.alarmFilter['countryCode'] = this.defaultAlarmFilter['countryCode'];
            if (this.defaultAlarmFilter['customerName'] != null && this.defaultAlarmFilter['customerName'].length > 0)
                this.alarmFilter['customerName'] = this.defaultAlarmFilter['customerName'];
            if (this.defaultAlarmFilter['machineType'] != null && this.defaultAlarmFilter['machineType'].length > 0)
                this.alarmFilter['machineType'] = this.defaultAlarmFilter['machineType'];
            if (this.defaultAlarmFilter['currentEscalationLevel'] != null && this.defaultAlarmFilter['currentEscalationLevel'].length > 0)
                this.alarmFilter['currentEscalationLevel'] = this.defaultAlarmFilter['currentEscalationLevel'];
            if (this.defaultAlarmFilter['eventCategoryTypes'] != null && this.defaultAlarmFilter['eventCategoryTypes'].length > 0)
                this.alarmFilter['eventCategoryTypes'] = this.defaultAlarmFilter['eventCategoryTypes'];

            this.assignFilterToTempFilter();//[GD2-3706]
        }
        this.alarmTemplateService.resetMachineAlarmFilters();
    }

    getMachineTypes() {
        // this.spinner.show();
        if (this.machineModels.length > 0) {
            this.roleService.setPopupScroll('section-machineTypeList');
            if (!this.isOpenMachineTypesPopUp)
                $(".sidebar-mini").addClass("filter-open");
            else {
                $(".sidebar-mini").removeClass("filter-open");
                this.roleService.resetPopupScroll();
            }
            setTimeout(() => {
                this.isOpenMachineTypesPopUp = !this.isOpenMachineTypesPopUp;
            }, this.roleService.scrollSetTimeoutTime);
        }
    }

    closeMachineTypePopup() {
        this.isOpenMachineTypesPopUp = false;
        $(".sidebar-mini").removeClass("filter-open");
        this.roleService.resetPopupScroll();
    }

    async onMachineTypeChange(machineModelId) {
        let serialNo = this.entityForm.controls['machineSerialNumber']?.value;
        machineModelId = machineModelId == null || machineModelId == '' ? this.entityForm.controls['machineModelId']?.value : machineModelId;
        let machineType = (machineModelId == null || machineModelId == '') ? '' : this.machineModelTypes.find(x => x.machineModelId == machineModelId)?.schaererMachineType;

        this.machineLegacyId = await this.machineService.getUniqueMachineName(serialNo, machineType, this.selectedPropositionType);

        if (!this.initialFormValues.machineModelId) this.initialFormValues.machineModelId = machineModelId;
    }

    getCurrentEntityHierarchy() {
        this.entityHierarchy = [];
        if (this.parentEntityId != null && this.parentEntityId != '' && this.parentEntityId != undefined && this.levelNumber > this.roleService.levelNumber && this.levelNumber > 1) {
            this.spinner.show();
            var obj: any = {};
            obj['parentEntityId'] = this.parentEntityId;
            obj['marketId'] = this.marketId;
            this.entityService.getEntityHierarchy(obj).subscribe(response => {
                if (response && response['data'].length) {
                    this.entityHierarchy = response['data'];
                } else {
                    this.entityHierarchy = [];
                }
                this.spinner.hide();
            }, err => {
                this.spinner.hide();
                let errors = err.error;
                errors.forEach(element => {
                    this.toaster.open({
                        text: element.msg,
                        type: 'danger',
                        position: 'top-right',
                        duration: 10000
                    });
                });
            });
        }
    }

    viewParentEntityDetails(element) {
        this.isFromBreadcrum = true;
        this.entityId = element.entityId;
        this.screenType = element.screenType;
        this.parentEntityId = element.parentEntityId;
        this.levelNumber = element.nextLevelNumber;
        this.marketId = element.marketId;
        this.parentlevelNumber = element.parentLevelNumber;
        this.tabIndex = element.tabIndex;
        this.navigationFromEntityMove = false;
        this.lable = this.enableGridImprovementsFT ? 'Record' : 'Entity';

        const params =
        {
            screenType: element.screenType,
            parentEntityId: element.parentEntityId,
            nextLevelNumber: element.nextLevelNumber,
            marketId: element.marketId,
            parentlevelNumber: element.parentLevelNumber,
            tabIndex: element.tabIndex
        };
        this.router.navigate(['/entity/', element.entityId], { queryParams: params });

        if (this.levelNumber == 1) {
            this.lable = 'Market';
        } else if (this.levelNumber == 4) {
            this.lable = 'Customer';
        } else if (this.levelNumber == 7) {
            this.lable = 'Site';
            if (this.screenType == 'edit' || this.screenType == 'read') {
                this.getSiteTradeHours();
            }
        } else if (this.levelNumber == 8) {
            this.lable = 'Machine';
            if (this.roleService.objRole.isSystemAdmin || this.roleService.objRole.isGlobalMarketAdmin || this.roleService.objRole.isMarketAdmin || this.roleService.objRole.isLevel2Admin || this.roleService.objRole.isLevel3Admin || (this.roleService.objRole.isSiteSupportL1 || this.roleService.objRole.isSiteSupportL2 || this.roleService.objRole.isSiteSupportL3)) {
                this.hasCloseAlarmAccess = true;
            }
        }

        if (this.entityId) {
            this.entityId = this.entityId;
            if (this.levelNumber == 1) {
                this.getMarketSetup(this.entityId);
            }

            if (this.levelNumber.toString() == "8" && (this.screenType == "read" || this.screenType == "edit" || this.screenType == "move")) {
                if (!this.navigationFromMachineMove)
                    this.getMachineFormsFileds(this.entityId);
                else {

                    this.getMachineFormsFileds(this.entityId, this.parentEntityId);
                }
            }
            else {
                if (!this.navigationFromEntityMove)
                    this.getFormsFileds(this.entityId);
                else {
                    this.getFormsFileds(this.entityId, this.parentEntityId);
                }
            }
        } else {
            if (this.levelNumber == 1) {
                this.parentEntityId = this.cookieService.get(this.cookieName + 'root_entity');
            }

            if (this.levelNumber == 8) {
                this.marketDetails = {
                    'isWarehouse': 'false',
                    'machineModelType': 'Legacy', //set default Legacy #GD2-694
                    'isCodeGenerated': false//set default false #GD2-694
                }
            }
            this.getFormsFileds();
        }
        setTimeout(() => {
            this.isFromBreadcrum = false;
        }, 200);
    }

    checkValueChangeBeforeBreadcrumClick(element) {
      if(this.levelNumber == 8 &&
        this.screenType != 'move' &&
        this.unsavedCheck(() => this.checkValueChangeBeforeBreadcrumClick(element))) {
          return;
        }
        this.moveToNextEntityDetails = element;
        let moveToNext = false;
        moveToNext = this.checkValueChangeAtAnyEntityLevel();
        if (moveToNext && this.screenType != 'move') {
            this.moveToNextEntityDetails = {};
            this.viewParentEntityDetails(element);
        } else if (this.screenType == 'move') {
            this.movedCancellation();
        }
    }

    onConfirmMoveNextEntityDetail() {
        localStorage.removeItem("checkEntityValueChanged");
        if (Object.keys(this.moveToNextEntityDetails).length > 0)
            this.viewParentEntityDetails(this.moveToNextEntityDetails);
        else if (this.nextRoute != undefined && this.nextRoute != '' && this.nextRoute != null)
            this.router.navigate([this.nextRoute]);
    }

    openConfirmMoveToNextDialog(requestType: string = '') {
        this.dialogTexts = {
            successTitle: 'Yes',
            cancelTitle: 'No',
            message: 'You are attempting to navigate away from this screen with unsaved changes - do you wish to proceed?',
            deviceName: '',
            status: '',
            title: 'Confirmation',
            type: requestType,
            isNext: false
        };
        const { key, component } = this.dialogService.openDialog();
        component.instance.title = this.dialogTexts.title;
        component.instance.description = this.dialogTexts.message;
        component.instance.primary = 'No';
        component.instance.secondary = 'Yes';
        const dialogSubs = new Subscription();
        dialogSubs.add(
          component.instance.secondaryClick.subscribe(() => {
            if (requestType == '') {
              this.onConfirmMoveNextEntityDetail();
            }
            else {
              this.onConfirmValidationReject();
            }
            this.dialogService.closeDialog(key);
            dialogSubs.unsubscribe();
          }),
        );
        dialogSubs.add(
          component.instance.primaryClick.subscribe(() => {
            this.dialogService.closeDialog(key);
            dialogSubs.unsubscribe();
          }),
        );
    }

    checkValueChangeAtAnyEntityLevel(requestType: string = '') {
        let moveToNext = false;
        let existingData = this.entityService.entityExistingData['level-' + this.levelNumber]['tab-' + this.tabIndex];
        let latestData = this.entityService.entityLatestData['level-' + this.levelNumber]['tab-' + this.tabIndex];

        if (this.tabIndex == 1 && this.levelNumber == 1) { //Market level setup tab
            latestData = this.levelSetup;
        } else if (this.tabIndex == 1 && this.levelNumber == 7) { //Trade hours setup tab
            latestData = this.entityService.tradehours
        }

        if (existingData && latestData && Object.keys(existingData).length != 0 && Object.keys(latestData).length != 0) {
            existingData = Object.keys(existingData).reduce((a, c) => (a[c] = existingData[c], a), {});
            latestData = Object.keys(latestData).reduce((a, c) => (a[c] = latestData[c], a), {});
        }

        if (this.tabIndex == 1 && this.levelNumber == 7) { //Trade hours setup tab
            existingData = JSON.stringify(existingData)
            latestData = JSON.stringify(latestData)
            existingData = existingData.replace(/,"isDefault":true/g, '');
            latestData = latestData.replace(/,"isDefault":true/g, '');
            if (existingData != latestData) {
                this.openConfirmMoveToNextDialog(requestType);
            } else {
                moveToNext = true;
            }
        } else if ((this.tabIndex == 2 && (this.levelNumber == 1 || this.levelNumber == 7 || this.levelNumber == 8)) ||
            this.tabIndex == 1 && this.levelNumber == 4) { //configuration
            if (JSON.stringify(existingData['cupsize']) != JSON.stringify(latestData['cupsize']) ||
                JSON.stringify(existingData['manageIngrdient']) != JSON.stringify(latestData['manageIngrdient']) ||
                JSON.stringify(existingData['ingredientSetup']) != JSON.stringify(latestData['ingredientSetup'])) {
                this.openConfirmMoveToNextDialog(requestType);
            } else {
                moveToNext = true;
            }
        } else if (!this.isEqualObj(existingData, latestData) && this.screenType != 'move') {
            this.openConfirmMoveToNextDialog(requestType);
        } else {
            moveToNext = true;
        }
        return moveToNext;
    }

    moveToDashboard(route) {
        if (this.screenType == 'add' || this.screenType == 'edit') {
            let moveToNext = false;
            moveToNext = this.checkValueChangeAtAnyEntityLevel();
            if (moveToNext) {
                this.router.navigate(['/' + route]);
            } else {
                this.moveToNextEntityDetails = {};
                this.nextRoute = '/' + route;
            }
        } else {
            this.router.navigate(['/' + route]);
        }
    }

    viewMachines(isCheckFormEdited = true) {
        let allowedViewMachine = false;
        if (isCheckFormEdited) {
            allowedViewMachine = this.checkValueChangeAtAnyEntityLevel('viewMachines');
        }
        else {
            allowedViewMachine = true;
        }
        if (allowedViewMachine) {
            localStorage.setItem("filterMachinesByPreviousEntity", JSON.stringify({ entityId: this.entityId, levelNumber: this.levelNumber }));
            this.router.navigate(['/machine']);
        }
    }

    propositionTypeLookup(updateMachineModelJson = false) {
      let route = "";
      let hasParentId = (this.parentEntityId != null && this.parentEntityId != "" && this.parentEntityId != undefined);

      if (!hasParentId) {
          route = "/api/v1/master/machine/getpropositiontype";
          if (this.levelNumber == 8 && this.marketId != '' && this.marketId != null && this.marketId != undefined) { // GD2-3635
              route = route + '/' + this.marketId;
          }
      }
      else {
          route = "/api/v1/entitypropositions/" + this.parentEntityId;
      }

      this.entityService.getLookup(route).subscribe(response => {
          if (response && response['data'].length) {
              let value = '';
              if (!hasParentId) {
                  this.propositionTypeList = response['data'];
                  value = (this.marketDetails['propositionTypeId']) ? this.marketDetails['propositionTypeId'] : '';
              }
              else {
                  this.propositionTypeList = response['data']
                      .filter(x => (x.isEnabled && x.isParentEnabled))
                      .map(element => ({ masterDataId: element.propositionTypeId, text: element.name }));

                  if (this.isWareHouseMultiProposition) {
                      this.propositionTypeList = response['data']
                          .map(element => ({ masterDataId: element.propositionTypeId, text: element.name }));
                  }

                  value = (this.marketDetails['propositionTypeId'])
                      ? ((this.propositionTypeList.filter(x => x.masterDataId?.toLowerCase() == this.marketDetails['propositionTypeId']?.toLowerCase()).length > 0) ? this.marketDetails['propositionTypeId'] : '')
                      : '';
              }
              this.entityForm.patchValue({ 'propositionTypeId': value });
              this.onPropositionTypeChange(value, false, updateMachineModelJson);

              if (this.marketDetails['machineTwinValidated'] == true)
                  this.entityForm.controls.propositionTypeId.disable();

              this.entityService.entityExistingData['level-' + this.levelNumber]['tab-0'] = this.entityForm.getRawValue();
              this.entityService.entityLatestData['level-' + this.levelNumber]['tab-0'] = this.entityForm.getRawValue();
          }
          this.setPropositionTypeFieldRequired();
      }), err => {
          this.setPropositionTypeFieldRequired();
          this.spinner.hide();
          let errors = err.errors == undefined ? err.error : err.errors;
          errors.forEach(element => {
              this.toaster.open({
                  text: element.msg,
                  type: 'danger',
                  position: 'top-right',
                  duration: 10000
              });
          });
      };
  }

  onPropositionTypeChange(propositionTypeId, loader = false, updateMachineModelJson = false) {
      this.siteSelectedPropositionTypeId = propositionTypeId; //GD2-4427
      this.machineModelTypes = [];
      this.machineModels = [];
      if (propositionTypeId != null && propositionTypeId != '' && propositionTypeId != undefined) {
          this.getMachineModelTypes(this.parentEntityId, propositionTypeId, updateMachineModelJson, loader);
          let propositionType = this.propositionTypeList.filter(x => x.masterDataId == propositionTypeId);

          if (propositionType != null && propositionType != undefined && propositionType?.length > 0)
              this.selectedPropositionType = this.propositionTypeList.filter(x => x.masterDataId == propositionTypeId)[0].text?.trim();
      } else {
          this.entityForm.patchValue({ 'machineModelId': '' }); // ref GD2-3547
      }

      this.setSerialNumberValidationByProposition(this.selectedPropositionType);
  }

    setSerialNumberValidationByProposition(propositionType: string) {
        let minLength: number = 0;
        let maxLength: number = propositionType === PropositionMasterEnum.Autonomous ? 20 : 15;

        this.entityForm.controls["machineSerialNumber"].setValidators([Validators.minLength(minLength), Validators.maxLength(maxLength), Validators.required]);
        this.entityForm.controls['machineSerialNumber'].updateValueAndValidity();
    }

    setPropositionTypeFieldRequired() {
        this.entityForm.controls["propositionTypeId"].setValidators([Validators.required]);
        this.entityForm.controls['propositionTypeId'].updateValueAndValidity();
    }

    //GD2-3677
    getMobileStatusFieldDetail(fieldName, type) {
        fieldName = fieldName?.replace('#', '') + 'Status';
        if (type == 'fieldExists') {
            return (this.mobileVerificationStatusFields.filter(x => x.fieldUniqueName == fieldName && x.value != '')?.length > 0);
        }
        else if (type == 'toolTipRequire') {
            return (this.mobileVerificationStatusFields.filter(x => x.fieldUniqueName == fieldName && x.value != '')?.length > 0)
                ? this.mobileVerificationStatusFields.filter(x => x.fieldUniqueName == fieldName && x.value != '')[0].fieldToolTip
                : '';
        }
        else if (type == 'status') {
            return (this.mobileVerificationStatusFields.filter(x => x.fieldUniqueName == fieldName && x.value != '')?.length > 0)
                ? this.mobileVerificationStatusFields.filter(x => x.fieldUniqueName == fieldName && x.value != '')[0].value
                : '';
        }
    }

    // GD2-4423 start
    onSitePropositionTypeChange(propositionTypeId) {
        this.siteSelectedPropositionTypeId = propositionTypeId;

        this.entityService.getEntityPropositions(this.parentEntityId).subscribe(response => {
            if (response && response['data']) {
                const propositionSelected = response['data'].filter(x => x.propositionTypeId == propositionTypeId && x.isEnabled)
                this.selectedPropositionTypeName = propositionSelected[0].name;
                this.setSiteLowAdminStatus();
                this.getLowAdminSiteMarket();
            }
        });
    }

    private getLowAdminSiteMarket() {
        if (this.selectedPropositionTypeName == this.propositionMasterEnum.Capsules) {
            const marketLevel = 1;
            this.entityService.getLowAdminProposition(this.marketId, this.siteSelectedPropositionTypeId, marketLevel).subscribe (
              (response) => {
                if (response) {
                  this.isLowAdminMarketSetup = response.isLowAdmin;
                }
              },
              (err: HttpErrorResponse) => {
                if (err.status !== 404) {
                  this.spinner.hide();
                  this.toaster.open({
                    text: 'Something wrong happened while trying to retrieve Low Admin information.',
                    type: 'danger',
                    position: 'top-right',
                    duration: 10000,
                  });
                }
              },
            );
        }
    }

    private propsitionTypeChangeConfirmation() {
        this.propositionConfirmDataModel = new AlertConfirmDialogModel();
        this.propositionConfirmDataModel.action = "Information";
        this.propositionConfirmDataModel.dialogId = "propositiontype-change-confirmation";
        this.propositionConfirmDataModel.okButtonName = "OK";
        this.propositionConfirmDataModel.isVisibleCancel = false;
        this.propositionConfirmDataModel.title = "Confirmation";
        this.propositionConfirmDataModel.message = "This site’s configuration details will be updated to reflect the new proposition type selected";
        const { key, component } = this.dialogService.openDialog();
        component.instance.title = this.propositionConfirmDataModel.title;
        component.instance.description = this.propositionConfirmDataModel.message;
        component.instance.primary = 'No';
        component.instance.secondary = 'Yes';
        const dialogSubs = new Subscription();
        dialogSubs.add(
          component.instance.primaryClick.subscribe(() => {
            this.dialogService.closeDialog(key);
            dialogSubs.unsubscribe();
          }),
        );
        dialogSubs.add(
          component.instance.secondaryClick.subscribe(() => {
            this.onConfirmPropositionChange();
            this.dialogService.closeDialog(key);
            dialogSubs.unsubscribe();
          }),
        );
    }

    onConfirmPropositionChange() {
        this.add('', false, true);
    }
    // GD2-4423 end

    //GD2-4536
    checkParentHasPropositionOn(event) {
        this.entityService.getEntityPropositions(this.parentEntityId).subscribe(response => {
            if (response && response['data']) {
                let sitePropositionId = this.entityForm.controls['sitePropositionType'].value;
                if (response['data'].filter(x => x.propositionTypeId == sitePropositionId && x.isEnabled).length == 0) {
                    this.toaster.open({
                        text: 'Site cannot be re-activated as its proposition type is no longer active for the Customer. Please contact your market admin to resolve this issue',
                        type: 'danger',
                        position: 'top-right',
                        duration: 10000
                    });
                    this.radioObj[event.fieldUniqueName] = false;
                }
            }
        });
    }

    //GD2-4083
    private validateMachineSerialNumber() {
        if (this.selectedPropositionType == 'Capsules') {
            var machineModelId = this.entityForm.controls['machineModelId']?.value
            var machineType = (machineModelId == null || machineModelId == '') ? '' : this.machineModelTypes.find(x => x.machineModelId == machineModelId)?.schaererMachineType;
            if (machineType.toUpperCase() != this.machineLegacyId.toUpperCase().substring(0, 5)) {
                return false;
            }
        }
        return true;
    }

    shouldBlockFieldsForConcessions = false;
    disableLockdownFieldsForConcessionsFT = false;
    lockdownFields = [];
    customerLockdownFields = [
        "partnerName",
        "partnerCountryCode",
        "partnerTradeCode",
        "partnerBrandType",
        "partnerBrandCode",
        "partnerContactPhone",
        "partnerContactEmail"
    ];
    customerLockdownCountryCodeFields = [
        "partnerContactPhoneCountry"
    ];

    siteLockdownFields = [
        "siteName",
        "siteAddress",
        "siteAddress2",
        "siteCity",
        "siteLocality",
        "sitePostalCode",
        "siteCountryCode",
        "siteTradeCode",
        "siteBrandType",
        "siteBrandCode",
        "siteEmail",
        "sitePhoneNumber",
        "siteLanguage",
        "sitePropositionType"
    ];
    siteLockdownCountryCodeFields = [
        "sitePhoneNumberCountry"
    ];

    machineLockdownFields = [
        "machinePrimaryAlarmFirstContactMobile#",
        "machineSecondaryAlarmFirstContactMobile#",
        "machineLocation",
        "machineGeoPositionLong",
        "machineGeoPositionLat",
        "machineWorkingStatus",
        "machineclickCancelSuspendReason",
        "machineReference",
        "machineSerialNumber",
        "machineSuspendReason",
        "machineCommissioningStatus",
        "machinePrimaryAlarmFirstContactEmail",
        "machineSecondaryAlarmFirstContactEmail",
        "machinePrimaryAlarmsecondContactMobile#",
        "machineSecondaryAlarmsecondContactMobile#",
        "machinePrimaryAlarmsecondContactEmail",
        "machineSecondaryAlarmsecondContactEmail",
        "machineServiceAgentEmail",
        "machineServiceAgentMobileNumberCountry",
        "machineServiceAgentMobileNumber",
        "machinePaymentTerminalID",
        "machineStatus"
    ]
    machineLockdownCountryCodeFields = [
        "machinePrimaryAlarmFirstContactMobileCountry#",
        "machineSecondaryAlarmFirstContactMobileCountry#",

        "machinePrimaryAlarmsecondContactPhoneCountry#",
        "machineSecondaryAlarmsecondContactMobileCountry#",

        "machineServiceAgentMobileNumberCountry"
    ]
    //GD2-5491//

    private getEntityPropositionAsPromise(): Promise<void> {
        return new Promise((resolve) => {
            let route = "";
            let hasParentId = (this.parentEntityId != null && this.parentEntityId != "" && this.parentEntityId != undefined);
            let propositionTypeList = [];
            if (!hasParentId) {
                route = "/api/v1/master/machine/getpropositiontype";
                if (this.levelNumber == 8 && this.marketId != '' && this.marketId != null && this.marketId != undefined) { // GD2-3635
                    route = route + '/' + this.marketId;
                }
            }
            else if(this.levelNumber == 8){
                route = "/api/v1/entitypropositions/" + this.parentEntityId;
            }
            else {
                route = "/api/v1/entitypropositions/" + this.entityId;
            }

            this.entityService.getLookup(route).subscribe(response => {
                if (response && response['data'].length) {
                    let value = '';
                    if (!hasParentId) {
                        value = (this.marketDetails['propositionType']) ? this.marketDetails['propositionType'] : '';
                    }
                    else {
                        propositionTypeList = response['data']
                            .filter(x => (x.isEnabled && x.isParentEnabled))
                            .map(element => ({ masterDataId: element.propositionTypeId, text: element.name }));

                        if (this.isWareHouseMultiProposition) {
                            propositionTypeList = response['data']
                                .map(element => ({ masterDataId: element.propositionTypeId, text: element.name }));
                        }

                        if(this.marketDetails['propositionType']){
                            value = (this.marketDetails['propositionType'])
                            ? ((propositionTypeList.filter(x => x.masterDataId?.toLowerCase() == this.marketDetails['propositionTypeId']?.toLowerCase()).length > 0) ? this.marketDetails['propositionType'] : '')
                            : '';
                        }
                        else {

                            if(propositionTypeList.length == 1){
                                var concessionProposition = propositionTypeList.find(x => x.text.toLowerCase() == PropositionMasterEnum.Concession.toLowerCase());
                                if(concessionProposition)
                                    value = concessionProposition.text;
                            }

                        }
                    }

                    this.shouldBlockFieldsForConcessions = this.checkIfShouldBlockFieldsForConcessions(value);
                    resolve(null);
                }
            }, err => {
                resolve(null);
            });
        });
    }

    private checkIfShouldBlockFieldsForConcessions(value: string): boolean {
        const isConcession = value.toLowerCase() === PropositionMasterEnum.Concession.toLowerCase();
        const isLevelInRange = (this.levelNumber >= EntityLevelNumber.Customer && this.levelNumber < EntityLevelNumber.Site) || this.levelNumber == EntityLevelNumber.Machine;
        const isSiteLevel = this.levelNumber == EntityLevelNumber.Site && this.marketDetails.siteHaveMachines;

        return isConcession && (isLevelInRange || isSiteLevel);
    }

    disableAdditionalMachineTabForConcession(){
        const propositionIsConcessions = this.selectedPropositionType === this.propositionMasterEnum.Concession;
        if(propositionIsConcessions){
            return (!this.disableLockdownFieldsForConcessionsFT && this.isGridOneIngested && !this.isConcessionMigrated)
        }
        return false;
    }

    private isLockdownProperty(element) {
        if (!element || !this.shouldBlockFieldsForConcessions || this.screenType != 'edit')
            return false;

        if (this.levelNumber == 4)
            this.lockdownFields = this.customerLockdownFields;
        else if (this.levelNumber == 7)
            this.lockdownFields = this.siteLockdownFields;
        else if (this.levelNumber == 8)
            this.lockdownFields = this.machineLockdownFields;

        if (element.hasOwnProperty('fieldUniqueName') && this.lockdownFields.some(field => field == element['fieldUniqueName']))
            return true;
    }

    private disableAdditionalApiFields() {
        if (!this.shouldBlockFieldsForConcessions || this.screenType != 'edit') {
            return;
        }

        // start - GD2-15056 - Temporary blocking all fields
        // if (this.levelNumber == 4) {
        //     this.lockdownFields = this.customerLockdownFields;
        //     this.countryCodes.filter(f => this.customerLockdownCountryCodeFields.some(s => s == f.fieldUniqueName)).forEach(element => {
        //         element.isEditable = false;
        //     });
        // }
        // else if (this.levelNumber == 7) {
        //     this.lockdownFields = this.siteLockdownFields;
        //     this.countryCodes.filter(f => this.siteLockdownCountryCodeFields.some(s => s == f.fieldUniqueName)).forEach(element => {
        //         element.isEditable = false;
        //     });
        // }
        // else if (this.levelNumber == 8) {
        //     this.machineTypeEditable = false;
        //     this.lockdownFields = this.machineLockdownFields;
        //     this.countryCodes.filter(f => this.machineLockdownCountryCodeFields.some(s => s == f.fieldUniqueName)).forEach(element => {
        //         element.isEditable = false;
        //     });
        // }

        // this.filedsJson.filter(f => this.lockdownFields.some(field => field == f.fieldUniqueName)).forEach(element => {
        //     element.isEditable = false;
        // });

        this.countryCodes.forEach(element => {
            element.isEditable = false;
        });

        this.filedsJson.forEach(element => {
            element.isEditable = false;
        });
        // end - GD2-15056 - Temporary blocking all fields
    }

    private async SetFeatureFlagsConfigurations() {
        this.disableLockdownFieldsForConcessionsFT = await this.azureService.isDisableConcessionLockdownPropertiesFT();
        this.autonomousMachinesFT = await this.azureService.isAutonomousMachinesFT();
        this.globalChangesFT = await this.azureService.isGlobalChangesFT();
        this.enableCreateConcessionFT = await this.azureService.isEnableCreateConcessionFT();
        this.displayedColumnsAutonomous = this.autonomousMachinesFT ? ['drink', 'siteName', 'gridPrice', 'amountPaid', 'currency', 'basketId', 'vendDateTime', 'salesReceivedDate', 'vendStatus', 'actions'] : ['vendId', 'drink', 'siteName', 'manufacturePrice', 'gridPrice', 'currency', 'vendDateTime', 'salesManufactureDate', 'salesReceivedDate', 'vendStatus', 'actions'];
        this.displayedColumnsCounterTop = this.globalChangesFT ? ['drink', 'siteName', 'machinePrice', 'gridPrice', 'amountPaid', 'currency', 'vendDateTime', 'salesReceivedDate', 'vendStatus', 'actions'] : ['vendId', 'drink', 'siteName', 'manufacturePrice', 'gridPrice', 'currency', 'vendDateTime', 'salesManufactureDate', 'salesReceivedDate', 'vendStatus', 'actions'];//[GD2-2876]
        this.displayedColumnsCapsules = this.globalChangesFT ? ['drink', 'siteName', 'currency', 'vendDateTime', 'salesReceivedDate', 'vendStatus', 'actions'] : ['vendId', 'drink', 'siteName', 'manufacturePrice', 'gridPrice', 'currency', 'vendDateTime', 'salesManufactureDate', 'salesReceivedDate', 'vendStatus', 'actions'];//[GD2-2876]
        this.displayedColumnsConcession = this.globalChangesFT ? ['drink', 'siteName', 'machinePrice', 'gridPrice', 'amountPaid', 'currency', 'vendDateTime', 'salesReceivedDate', 'vendStatus', 'actions'] : ['vendId', 'drink', 'siteName', 'manufacturePrice', 'gridPrice', 'currency', 'vendDateTime', 'salesReceivedDate', 'vendStatus', 'actions'];
        this.enableGridImprovementsFT = await this.azureService.isEnableGridImprovementsFT();
        this.showEntityOrRecordName = this.enableGridImprovementsFT ? 'Record' : 'Entity';
        this.lable = this.enableGridImprovementsFT ? 'Record' : 'Entity';
    }

    clickSuspendMachine() {
        if (this.getSuspendMachineLabelNameState() === SuspendOrResumeLabelTypes.SUSPEND) {
            this.getSuspendMachineConcessionsState();
            this.setSuspendReasonOptions();
            this.dropdownDrawer.openDrawer();
        } else {
            this.suspendOrResumeMachineConfirmationPopup('resume');
        }
    }

    clickSaveSuspend(suspendReason: string) {
        this.selectedSuspendReason = suspendReason;
        this.suspendOrResumeMachineConfirmationPopup('suspend');
    }

    clickCancelSuspend(suspendReason: string) {
        if (suspendReason) {
            this.suspendReasonCancel()
        } else {
            this.dropdownDrawer.closeDrawer();
        }
    }

    setSuspendReasonOptions() {
        const machineSuspendReasonIndex = this.filedsJson.findIndex((el) => el.fieldUniqueName == 'machineSuspendReason');
        const optionsCode = this.filedsJson[machineSuspendReasonIndex].fieldOptions.filter((obj) => obj.text !== SuspendOrResumeMachineTypes.NOT_APPLICABLE);
        optionsCode.sort((a, b) => a.text.localeCompare(b.text));
        this.suspendReasonOptions = {
            label: 'Suspend Reason',
            options: optionsCode
        };
    }

    private suspendOrResumeMachineConfirmationPopup(action: SuspendOrResumeActionTypes) {
        var partialMessage;
        if (action === 'resume')
            partialMessage = 'resume trading on';
        else
            partialMessage = action;

        const confirmationMessageView = `Do you wish to ${partialMessage} this machine?`;
        const confirmationMessageEdit = `${confirmationMessageView} <br /><b>Note: As you are in edit view, all previous changes on machine details will be lost</b>`
        const message = this.screenType === 'edit' ? confirmationMessageEdit : confirmationMessageView

        const dialogModelConfig = {
            action: "Information",
            dialogId: `${action}-machine-confirmation`,
            okButtonName: "Yes",
            cancelButtonName: "No",
            isVisibleCancel: true,
            title: "Confirmation",
            message: message,
            requestBody: null,
            result: null
        }
        if (action === 'resume') {
            this.resumeMachineConfirmDataModel = new AlertConfirmDialogModel();
            this.resumeMachineConfirmDataModel = dialogModelConfig
        } else {
            this.suspendMachineConfirmDataModel = new AlertConfirmDialogModel();
            this.suspendMachineConfirmDataModel = dialogModelConfig;
        }
        this.dialogTemplateData = {
          htmlContent: dialogModelConfig.message
        };
        const { key, component } = this.dialogService.openDialog();
        component.instance.title = dialogModelConfig.title;
        component.instance.primary = 'No';
        component.instance.secondary = 'Yes';
        component.instance.contentTemplate = this.dialogTemplate;
        const dialogSubs = new Subscription();
        dialogSubs.add(
          component.instance.primaryClick.subscribe(() => {
            this.dialogService.closeDialog(key);
            dialogSubs.unsubscribe();
          }),
        );
        dialogSubs.add(
          component.instance.secondaryClick.subscribe(() => {
            if (action === 'resume') {
              this.onConfirmResumeMachine();
            } else {
             this.onConfirmSuspendMachine();
            }
            this.dialogService.closeDialog(key);
            dialogSubs.unsubscribe();
          }),
        );
    }

    getSuspendMachineConcessionsState(): {
        suspendReason: string;
        workingStatus: string;
        targetWorkingStatus: string;
    } {
        const machineSuspendReasonElements = this.filedsJson.filter((field) => field.fieldUniqueName === 'machineSuspendReason');
        const machineTargetWorkingStatusElements = this.filedsJson.filter((field) => field.fieldUniqueName === 'machineTargetWorkingStatus');
        const machineWorkingStatusElements = this.filedsJson.filter((field) => field.fieldUniqueName === 'machineWorkingStatus');

        return {
            suspendReason: machineSuspendReasonElements.length ? machineSuspendReasonElements[0].value : '',
            targetWorkingStatus: machineTargetWorkingStatusElements.length ? machineTargetWorkingStatusElements[0].value : '',
            workingStatus: machineWorkingStatusElements.length ? machineWorkingStatusElements[0].value : '',
        }
    }

    getSuspendMachineLabelHideState(): boolean {
        const screenTypeIsReadOrEdit = (this.screenType === 'read' || this.screenType === 'edit')
        const propositionIsConcessions = this.selectedPropositionType === this.propositionMasterEnum.Concession;

        return screenTypeIsReadOrEdit &&
            propositionIsConcessions
    }

    getSuspendMachineLabelNameState(): string {
        const state = this.getSuspendMachineConcessionsState();
        const isSuspendedMachine = state.suspendReason !== SuspendOrResumeMachineTypes.NOT_APPLICABLE
        const isResumeMachine = state.suspendReason === SuspendOrResumeMachineTypes.NOT_APPLICABLE
        const workingStatusIsSuspended = state.workingStatus === SuspendOrResumeMachineTypes.SUSPENDED
        const workingStatusIsResume = state.workingStatus === SuspendOrResumeMachineTypes.TRADING
        const targetWorkingStatusIsSuspended = state.targetWorkingStatus === SuspendOrResumeMachineTypes.SUSPENDED
        const targetWorkingStatusIsResume = state.targetWorkingStatus === SuspendOrResumeMachineTypes.TRADING
        const resumeLogic = this.selectedPropositionType === this.propositionMasterEnum.Concession &&
            isSuspendedMachine &&
            workingStatusIsSuspended &&
            targetWorkingStatusIsSuspended

        const suspendLogic = this.selectedPropositionType === this.propositionMasterEnum.Concession &&
            isResumeMachine &&
            workingStatusIsResume &&
            targetWorkingStatusIsResume

        return resumeLogic ? SuspendOrResumeLabelTypes.RESUME : suspendLogic ? SuspendOrResumeLabelTypes.SUSPEND : ''
    }

    onConfirmSuspendMachine() {
        this.machineService.suspendMachine(this.machineId, this.selectedSuspendReason).subscribe(response => {
            window.location.reload();
        }, err => {
            this.toaster.open({
                text: "Something wrong happened while trying to suspend the machine.",
                type: 'danger',
                position: 'top-right',
                duration: 10000
            });
        });
    }

    setDisabledFranchiseCountryID(element: string) {
        return (element === 'level2FranchiseCountryID' || element === 'level3FranchiseCountryID') &&
            (this.roleService.objRole.isGlobalMarketAdmin || this.roleService.objRole.isMarketAdmin);
    }

    toggleLowAdmin() {
      if (this.isLowAdminActive) {
        this.spinner.show();
        this.lowAdminService
          .hasLowAdminEnableForAnySite(this.marketId)
          .subscribe(
            (result) => {
              if (result && result.hasSitesWithLowAdminEnabled) {
                const message = 'A Low Admin site already exists for this proposition in this Market - are you sure you want to proceed?';
                this.confirmLowAdminSetupPopUp(message);
              }
              this.spinner.hide();
            },
            (err) => {
              this.spinner.hide();
              this.toaster.open({
                text: 'Something wrong happened while trying to retrieve Low Admin information.',
                type: 'danger',
                position: 'top-right',
                duration: 10000,
              });
            },
          );
      } else {
        const message = 'You have changed this site so that it is no longer a low admin site. Are you sure you wish to proceed?';
        this.confirmLowAdminSetupPopUp(message);
      }
    }

    private confirmLowAdminSetupPopUp(message: string) {
      const dialogModelConfig = {
        action: 'Information',
        dialogId: 'site-low-admin-setup-confirmation',
        okButtonName: 'Yes',
        cancelButtonName: 'No',
        isVisibleCancel: true,
        title: 'Confirmation',
        message: message,
        requestBody: null,
        result: null,
      };

      this.siteLowAdminConfirmDataModel = dialogModelConfig;
      this.dialogTemplateData = {
        htmlContent: this.infoConfirmDataModel.message
      };
      const { key, component } = this.dialogService.openDialog();
      component.instance.title = dialogModelConfig.title;
      component.instance.primary = 'No';
      component.instance.secondary = 'Yes';
      component.instance.contentTemplate = this.dialogTemplate;
      const dialogSubs = new Subscription();
      dialogSubs.add(
        component.instance.primaryClick.subscribe(() => {
          this.onCancelLowAdminSetup();
          this.dialogService.closeDialog(key);
          dialogSubs.unsubscribe();
        }),
      );
      dialogSubs.add(
        component.instance.secondaryClick.subscribe(() => {
          this.onConfirmLowAdminSetup();
          this.dialogService.closeDialog(key);
          dialogSubs.unsubscribe();
        }),
      );
    }

    private CommissioningStatusFieldToLabel(commissioningStatus: any) {
      if(this.screenType == 'edit' && commissioningStatus.value == CommissioningStatusEnum.InProgress) {
        commissioningStatus.fieldType = 'Label';
      }
    }

  onDialogConfirm() {
    if (this.leaveAnyway ) {
      this.leaveAnyway();
    }
  }
  onDialogCancel() {
    this.leaveAnyway = undefined;
  }

  onDialogConfirmLegacyComponent() {
    if (this.leaveAnyway ) {
      this.leaveAnyway();
    }
  }

  onDialogCancelLegacyComponent() {
    this.leaveAnyway = undefined;
  }

  machineDetailsHasUnsavedChanges() {
    const rawForm = this.entityForm.getRawValue()
    return Object.keys(this.initialFormValues).reduce((acc, key) => {
      if (this.initialFormValues[key] !== rawForm[key]) {
        return true
      }
      return acc
    }, false)
  }
  resetMachineDetailsChanges(save?: boolean) {
    if (save) {
      this.initialFormValues = this.entityForm.getRawValue()
      return
    }
    this.entityForm.patchValue(this.initialFormValues)
  }

  unsavedCheck(proceed?: Function) {
    if (this.navigationFromMachineMove) {
      if (this.levelNumber == 8) {
        return !this.canLeaveMoveScreenType;
      }
      return false;
    }

    const options = {
      // machineDetails
      0: {
        unsavedChanges: () => this.machineDetailsHasUnsavedChanges(),
        resetChanges: () =>  this.resetMachineDetailsChanges()
      },
      // machine model
      1: {
        unsavedChanges: () => this.machineModelComponent?.hasUnsavedChanges(),
        resetChanges: () =>  this.machineModelComponent?.resetChanges()
      },
      // machine configuration
      2: {
        unsavedChanges: () => this.entityConfigurationComponent?.hasUnsavedChanges(),
        resetChanges: () =>  this.entityConfigurationComponent?.resetChanges()
      }
    }
    const option = options[this.tabIndex]
    if (!option) return false
    const hasUnsavedChanges = option.unsavedChanges()
    if (hasUnsavedChanges && proceed) {
      const { key, component } = this.dialogService.openDialog();
      component.instance.title = 'Unsaved Changes';
      component.instance.description = 'All unsaved changes will be lost. Are you sure you want to cancel?';
      component.instance.primary = 'No';
      component.instance.secondary = 'Yes';
      const dialogSubs = new Subscription();
      dialogSubs.add(
        component.instance.primaryClick.subscribe(() => {
          this.onDialogCancel();
          this.dialogService.closeDialog(key);
          dialogSubs.unsubscribe();
        }),
      );
      dialogSubs.add(
        component.instance.secondaryClick.subscribe(() => {
          this.onDialogConfirm();
          this.dialogService.closeDialog(key);
          dialogSubs.unsubscribe();
        }),
      );
      this.leaveAnyway = () => {
          option.resetChanges()
          proceed(this);
      }
    }
    return hasUnsavedChanges
  }

  selectedIndexChange(index: number) {
    if (this.tabIndex == index) {return}
    if (this.unsavedCheck(() => this.tabs.selectedIndex = index)) {
      this.tabs.selectedIndex = this.tabIndex;
    } else {
      this.tabIndex = index;
    }
  }

  private checkSiteLatLong(formData: any[]): void {
    const siteLonField = formData.find(obj => obj.fieldUniqueName === 'siteGeoPositionLong');
    const siteLatField = formData.find(obj => obj.fieldUniqueName === 'siteGeoPositionLat');
    const editRoles = [
      'isMarketAdmin',
      'isSystemAdmin',
      'isGlobalMarketAdmin',
      'isLevel2Admin',
      'isLevel3Admin',
      'isSiteSupportL1',
      'isSiteSupportL2',
      'isSiteSupportL3'
    ];
    if (this.roleService.hasUserAccess(editRoles) && !siteLonField?.value && !siteLatField?.value) {
      const { key, component } = this.dialogService.openDialog();
      component.instance.title = 'Alert';
      component.instance.description = 'The address for the site is invalid. Please review and update the site address to avoid incorrect data being reported.';
      component.instance.secondary = 'Close';
      const dialogSubs = new Subscription();
      dialogSubs.add(
        component.instance.secondaryClick.subscribe(() => {
          this.dialogService.closeDialog(key);
          dialogSubs.unsubscribe();
        }),
      );
    }
  }

  canDisplayButtonEdit(): boolean {
    return this.screenType === 'read' && (this.roleService.objRole.isMarketAdmin || this.roleService.objRole.isSystemAdmin || this.roleService.objRole.isGlobalMarketAdmin || this.roleService.objRole.isLevel2Admin || this.roleService.objRole.isLevel3Admin || (this.roleService.objRole.isSiteSupportL1 || this.roleService.objRole.isSiteSupportL2 || this.roleService.objRole.isSiteSupportL3));
  }

  canDisplayMachineModelComponentActionButtons(): boolean {
    return this.tabIndex == 1 &&
      (this.MachineModelComponent?.machineModelDetails['machineModelType']?.trim() === 'Proprietary' ||
        this.MachineModelComponent?.selectedPropositionType?.toLowerCase() === PropositionMasterEnum.Capsules.toLowerCase()) &&
      this.MachineModelComponent?.machineModelDetails['machineModelName'] !== '' ||
      (this.MachineModelComponent?.getPageType() === PageTypeEnum.Add && this.MachineModelComponent?.isPropositionAutonomous());
  }

  canDisplayMachineModelComponentSaveButton(): boolean {
    return this.MachineModelComponent?.hasButtonSave();
  }

  canDisplayMachineModelComponentEditButton(): boolean {
    return this.MachineModelComponent?.hasButtonEdit();
  }

  editMachineModelComponent(): void {
    this.MachineModelComponent?.edit();
  }

  canDisplayMachineModelComponentCancelButton(): boolean {
    return this.MachineModelComponent?.hasButtonCancel();
  }

  submitFormMachineModelComponent(type): void {
    this.MachineModelComponent?.submitForm(type);
  }

  canDisplayMachineConfigurationComponentEditButton(): boolean {
    return this.screenType == 'read' && ((this.roleService.objRole.isMarketAdmin && this.levelNumber != 1) || this.roleService.objRole.isSystemAdmin || this.roleService.objRole.isGlobalMarketAdmin || this.roleService.objRole.isLevel2Admin || this.roleService.objRole.isLevel3Admin)
  }

  async saveConfigurationComponent(propositionType): Promise<void> {
    await this.entityConfigurationComponent.saveConfiguration(propositionType);
  }

  editConfigurationComponent(): void {
    this.entityConfigurationComponent.edit();
  }

  cancelConfigurationComponent(): void {
    this.entityConfigurationComponent.cancel();
  }
}
