export class SummaryInformationModel {
    title: string;
    itens: string[];
    isChip: boolean;
    className: string;

    constructor(title: string, itens: string[], isChip: boolean = false, className: string = '') {
      this.title = title;
      this.itens = itens;
      this.isChip = isChip;
      this.className = className;
    }
}