import { Component, Output, OnInit, EventEmitter, Input, ViewChild } from '@angular/core';
import { CreateRemoteUpdateTaskRequest } from 'src/app/components/remote-update-tasks/dtos/create-remote-update-task.model';
import { RemoteUpdateTaskType } from 'src/app/components/remote-update-tasks/enums/remote-update-task-type.enum';
import { TaskEnableDrinksComponent } from './task-enable-drinks/task-enable-drinks.component';
import { TaskPackageComponent } from './task-package/task-package.component';
import { TaskEnableContactlessComponent } from './task-enable-contactless/task-enable-contactless.component';

@Component({
  selector: 'app-step-task-properties',
  templateUrl: './step-task-properties.component.html',
})
export class StepTaskPropertiesComponent implements OnInit {
  @Output() nextStepEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() backStepEvent: EventEmitter<any> = new EventEmitter<any>();

  @Input() newTaskRequest: CreateRemoteUpdateTaskRequest = new CreateRemoteUpdateTaskRequest();

  @ViewChild(TaskEnableDrinksComponent) private taskEnableDrinkComponent: TaskEnableDrinksComponent;
  @ViewChild(TaskEnableContactlessComponent) private taskEnableContactlessComponent: TaskEnableContactlessComponent;
  @ViewChild(TaskPackageComponent) private taskPackageComponent: TaskPackageComponent;

  isEnableDisableDrinks = false;
  isEnableDisableContactless = false;
  isPackage = false;
  isCertificateManagement = false;

  loadProperties() {
    this.isEnableDisableDrinks = false;
    this.isPackage = false;
    this.isCertificateManagement = false;
    this.isEnableDisableContactless = false;
    switch (this.newTaskRequest.TaskType) {
      case RemoteUpdateTaskType.EnableDisableDrinks:
        this.isEnableDisableDrinks = true
        break;
      case RemoteUpdateTaskType.CertificateManagement:
        this.isCertificateManagement = true
        break;
      case RemoteUpdateTaskType.Contactless:
        this.isEnableDisableContactless = true
        break;
      default:
        this.isPackage = true
        break;
    }
    if (this.isEnableDisableDrinks) {
      return this.taskEnableDrinkComponent?.loadProperties();
    }
    if (this.isPackage) {
      return this.taskPackageComponent?.loadPackage();
    }
  }

  ngOnInit(): void {}
}
