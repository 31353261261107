import { Component, OnInit, Input } from '@angular/core';
import { SummaryInformationModel } from 'src/app/components/remote-update-tasks/dtos/summary-info-model';

@Component({
  selector: 'app-summary-info-card',
  templateUrl: './information-card.component.html',
  styleUrls: ['./../../../new-task-step-common.css','./information-card.component.css']
})
export class SummaryInformationCardComponent implements OnInit {
  @Input() title : string = '';
  @Input() models: SummaryInformationModel[];
  @Input() numberChips: number;
  @Input() textLenght: number;
  @Input() className: string;

  constructor(
  ) {}

  ngOnInit(): void {}
  
  ngOnChanges(): void {}
}
