import { Component, Output, EventEmitter, Input } from '@angular/core';
import { CreateRemoteUpdateTaskRequest } from 'src/app/components/remote-update-tasks/dtos/create-remote-update-task.model';

@Component({
  selector: 'app-task-enable-contactless',
  templateUrl: './task-enable-contactless.component.html',
  styleUrls: ['./../../new-task-step-common.css', './task-enable-contactless.component.css'],
})
export class TaskEnableContactlessComponent {

  @Output() nextStepEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() backStepEvent: EventEmitter<any> = new EventEmitter<any>();

  @Input() newTaskRequest: CreateRemoteUpdateTaskRequest = new CreateRemoteUpdateTaskRequest();

  contactlessEnabled = true;

  backStep(): void {
    this.backStepEvent.emit();
  }

  nextStep(): void {
    this.newTaskRequest.Metadata = { "enabled" : this.contactlessEnabled };
    this.nextStepEvent.emit();
  }
}
